import { useForm } from 'react-hook-form'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { InterStateActions } from '../../../store/InterStateSlice'
import { CostData } from '../../../store/InterStateAction'
import axios from 'axios'
import { generateTransactionID } from '../../../Library/Utilities'
import { toast } from 'react-toastify'
import NestedBtn from '../../../components/NestedBtn'




const ReceiverForm = ({setTab,SenderStatus}) => {

  const dispatch=useDispatch()
  
  const {User} = useSelector((state)=>state.Auth)


  const {
    Sender,
    SenderCity,
    Receiver,
    ReceiverCity,
    PriceStatus,
    isLoading} = useSelector((state)=>state.Interstate)


  const [PickCity,setPickCity]=useState("")
  const [ApiCity,setApiCity]=useState("")
  const [SelectedCity,setSelectedCity]=useState("")
  const [showCitySuggestions,setShowCitySuggestions]=useState(false)
  const [CityError,setCityError]=useState(false)


    const {
        register,
        reset,
        handleSubmit,
        formState: { errors },
      } = useForm()


      useEffect(() => {
        AOS.init({
          duration: 2000,
        })
      }, [])

      const Submit=(data)=>{
        if(PickCity===''){
          CityError(false)
        }
        if(PickCity!=='' && data){
        dispatch(InterStateActions.GetReceiver(data))
        dispatch(InterStateActions.GetReceiverCity(PickCity))
        dispatch(InterStateActions.GetReceiverStatus(true))
        dispatch(InterStateActions.GetPriceStatus(false))
        dispatch(InterStateActions.GetPriceDetails(''))
        localStorage.setItem('Receiver',JSON.stringify(data))
        dispatch(CostData(User,Sender,SenderCity,{...Receiver,data},ReceiverCity))
        }
      }

    const Next=()=>{
      setTab(2)
    }

    const Previous=()=>{
      setTab(0)
    }

    useEffect(()=>{
      if(Receiver!==''){
        reset(Receiver)
      }
      if(ReceiverCity!==''){
        setPickCity(ReceiverCity)
      }
    },[reset,Receiver,ReceiverCity])


    const selectCity = (location) => {
      setPickCity(location)
      setCityError(false)
      setShowCitySuggestions(false);
  }
  
    const searchCity = (query) => {
      // dispatch(InterStateActions.GetPriceStatus(false))
      setPickCity(query);
  
      if (query === ""){
          setShowCitySuggestions(false);
          return;
      }
      const filteredCity = [];
      ApiCity.map((city) => {
          if (city["city_name"].toLowerCase().includes(query.toLowerCase())){
              filteredCity.push(city["city_name"]);
          }
      });
  
      setSelectedCity(filteredCity);
      setCityError(false)
      if (filteredCity.length > 0){
        setShowCitySuggestions(true);
      } else {
        setShowCitySuggestions(false);
      }
  }
  
  
      useEffect(()=>{
        const fetchCity=async()=>{
          const raw={
            "user_id":User.userID,
            "user_ses_id":User.sessionID,
            "company_id":User.companyID,
            "transaction_id":generateTransactionID(15),
        }
          await axios.post('https://users.mylogisticsapp.com/engine/v1/actions/get-dhl-cities',raw)
          .then(res=>{
            if(res.data.success===true){
              setApiCity(res.data.data)
            }
            if(res.data.success===false){
              toast.error(res.data.message)
            }
          })
        }
        fetchCity()
      },[ApiCity])

    return (
        <div className="Interstate_Form">
            <h3>Where will we be sending to?</h3>
            <form autoCorrect='false' autoComplete='false'>
              <div className="Top_Wrap">
              <div className="Form_Control">
              <label htmlFor="name">FIRST NAME</label>
              <input
              type="text"
              id='name'
              placeholder='First name'
              autoComplete='false'
              autoCorrect='false'
              className={`${errors.first_name ? 'error' : 'noError'}`}
                    {...register('first_name', { required: true, onChange:()=>dispatch(InterStateActions.GetPriceStatus(false)) })}
                />
                {errors.first_name && (
                    <span>
                    first name field is required
                    </span>
                )} 
              </div> 
              <div className="Form_Control">
              <label htmlFor="last">LAST NAME</label>
              <input
              type="text"
              id='last'
              placeholder='Last name'
              autoComplete='false'
              autoCorrect='false'
              className={`${errors.last_name ? 'error' : 'noError'}`}
                    {...register('last_name', { required: true, onChange:()=>dispatch(InterStateActions.GetPriceStatus(false)) })}
                />
                {errors.last_name && (
                    <span>
                    last name field is required
                    </span>
                )} 
              </div>    
             </div>

             <div className="Form_Control">
              <label htmlFor="street">Address</label>
              <input
              type="text"
              id='street'
              placeholder='Address'
              autoComplete='false'
              autoCorrect='false'
              className={`${errors.receiver_address ? 'error' : 'noError'}`}
                    {...register('receiver_address', { required: true, onChange:()=>dispatch(InterStateActions.GetPriceStatus(false)) })}
                />
                {errors.receiver_address && (
                    <span>
                    Address field is required
                    </span>
                )} 
              </div>
            
              <div className="Form_Control">
              <label htmlFor="house">ADDRESS 2 (optional)</label>
              <input
              type="text"
              id='house'
              placeholder='Address 2 '
              autoComplete='false'
              autoCorrect='false'

                />
              </div>

              <div className="City_Wrap">
              <div className="Form_Control">
              <label htmlFor="city">CITY</label>
              <input
              type="text"
              id='city'
              placeholder='City'
              autoComplete='false'
              autoCorrect='false'
              value={PickCity}
              onChange={(e) => searchCity(e.target.value)}
              className={`${CityError===true ? 'error' : ''}`}
                />
                {CityError===true && (
                    <span>
                    City field is required
                    </span>
                )}
                <div className={`suggestions-field ${showCitySuggestions ? "suggestions-field-visible" : null}`}>
                  {
                     showCitySuggestions===true &&  SelectedCity.map((suggestions) => 
                     (
                       <div onClick={(e) => selectCity(suggestions)} key={suggestions} className="single-suggestion">
                                 <span style={{fontSize:14, color:"#74788d"}}>{suggestions}</span>
                             </div>
                     )
                     )
                  }
              </div>
              </div> 
              <div className="Form_Control">
              <label htmlFor="last">STATE</label>
              <input
              type="text"
              id='last'
              placeholder='State'
              autoComplete='false'
              autoCorrect='false'
              className={`${errors.state ? 'error' : 'noError'}`}
                    {...register('state', { required: true,onChange:()=>dispatch(InterStateActions.GetPriceStatus(false)) })}
                />
                {errors.state && (
                    <span>
                    State field is required
                    </span>
                )} 
              </div>    
             </div>

             <div className="Form_Control">
              <label htmlFor="phone">PHONE NUMBER</label>
              <input
              type="tel"
              id='phone'
              placeholder='Phone Number'
              autoComplete='false'
              autoCorrect='false'
              className={`${errors.phone_number ? 'error' : 'noError'}`}
                    {...register('phone_number', { required: true, onChange:()=>dispatch(InterStateActions.GetPriceStatus(false)) })}
                />
                {errors.phone_number && (
                    <span>
                    Phone number field is required
                    </span>
                )} 
              </div>

              <div className="Save_Address">
                <input type="radio" name="" id="save" />
                <label htmlFor="save">SAVE TO ADDRESS BOOK</label>
              </div>

             {
              PriceStatus===true ?  <div onClick={Next} className="FormBtn">
              CONTINUE
              </div> : <div onClick={handleSubmit(Submit)} className="FormBtn">
              {
                isLoading===true ? <span className="spinner-border spinner-grow-sm"/>:"CALCULATE COST"
              }
             </div>
             }
            </form>
            <div className="Terms">
            By completing this transaction, you represent that you have read, understand and accept the Ella Logistics <a href="https://ellalogistics.africa/privacy-policy" target="_blank" rel="noreferrer">terms of service</a> and <a href="https://ellalogistics.africa/privacy-policy" target="_blank" rel="noreferrer">privacy policy</a>.
            </div>
            <div className="Nested_Bottom_Wrap">
                <NestedBtn 
                handle={Previous}
                status={SenderStatus}
                name="previous" />
            </div>
        </div>
    );
}
 
export default ReceiverForm;