import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useDispatch, useSelector } from 'react-redux'
import { InterStateActions } from '../../../store/InterStateSlice'
import NestedBtn from '../../../components/NestedBtn'


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  outline: 'none',
  outerWidth: '0px',
  borderRadius: '5px',
  p: 4,
}


const PackageDetail = ({setTab,PriceStatus}) => {

  const {Package,TotalQuantity,TotalValue} = useSelector((state)=>state.Interstate)

  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [isEmpty, setisEmpty] = useState(false)
  const [Overall, setOverall] = useState('')
  const [OveralModal, setOveralModal] = useState(false)
  const handleOverallOpen = () => setOveralModal(true)
  
  const OveralSubmit = () => {
    if (Overall === '') {
      setisEmpty(true)
    }
    if (Overall !== '' && Overall.length > 3) {
      dispatch(InterStateActions.GetOverall(Overall))
      console.log(Overall)
      setisEmpty(false)
      setOveralModal(false)
    }
  }

  const [EditPackage, setEditPackage] = useState(null)
  const [Editopen, setEditopen] = useState(false)
  const handleEditOpen = (item) => {
    setEditPackage(item)
    setEditopen(true)
  }
  const handleEditClose = () => setEditopen(false)

  const SubmitUpdate = () => {
    dispatch(InterStateActions.EditPackage(EditPackage))
    setEditopen(false)
  }

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [isClear, setIsClear] = useState(false)

 
const Previous=()=>{
    setTab(2)
}


  useEffect(() => {
    AOS.init({
      duration: 2000,
    })
  }, [])

  useEffect(() => {
    if (!isClear) {
      return
    } else {
      reset()
    }
  }, [isClear, reset])

  const onSubmit = (data) => {
    if (data) {
      dispatch(
        InterStateActions.GetPackage({
          id: Package.length + 1,
          ...data,
        }),
      )
      dispatch(InterStateActions.GetQtyVal())
      dispatch(InterStateActions.GetRequestPack())
      dispatch(InterStateActions.GetPackageStatus(true))
      setOpen(false)
      setIsClear(true)
      console.log(isClear)
    } else setIsClear(false)
  }

  const SubmitForm = () => {
    if (Package.length < 1) {
      return
    } else {
      setTab(4)
    }
  }


    return (
        <>
        <div data-aos="fade-zoom-in" className="Package_Detail">
            <h3>Enter Package Details</h3>
          <div className="Top">
            {
              Package.map((item,index)=>(
                <div className="Box">
                <div className="Left">
                  <div className="circle">{item.quantity}</div>
                  <div className="Detail">
                  <h4>{item.name}</h4>
                  <h5>{item.description}</h5>
                  </div>
                </div>
                <div onClick={() => handleEditOpen(item)} className="Right">
                  <svg
                    width="17"
                    height="5"
                    viewBox="0 0 17 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0488 2.94775C12.0488 2.41732 12.2595 1.90861 12.6346 1.53354C13.0097 1.15847 13.5184 0.947754 14.0488 0.947754C14.5793 0.947754 15.088 1.15847 15.463 1.53354C15.8381 1.90861 16.0488 2.41732 16.0488 2.94775C16.0488 3.47819 15.8381 3.98689 15.463 4.36197C15.088 4.73704 14.5793 4.94775 14.0488 4.94775C13.5184 4.94775 13.0097 4.73704 12.6346 4.36197C12.2595 3.98689 12.0488 3.47819 12.0488 2.94775ZM6.04883 2.94775C6.04883 2.41732 6.25954 1.90861 6.63461 1.53354C7.00969 1.15847 7.51839 0.947754 8.04883 0.947754C8.57926 0.947754 9.08797 1.15847 9.46304 1.53354C9.83811 1.90861 10.0488 2.41732 10.0488 2.94775C10.0488 3.47819 9.83811 3.98689 9.46304 4.36197C9.08797 4.73704 8.57926 4.94775 8.04883 4.94775C7.51839 4.94775 7.00969 4.73704 6.63461 4.36197C6.25954 3.98689 6.04883 3.47819 6.04883 2.94775ZM0.0488281 2.94775C0.0488281 2.41732 0.259542 1.90861 0.634614 1.53354C1.00969 1.15847 1.5184 0.947754 2.04883 0.947754C2.57926 0.947754 3.08797 1.15847 3.46304 1.53354C3.83811 1.90861 4.04883 2.41732 4.04883 2.94775C4.04883 3.47819 3.83811 3.98689 3.46304 4.36197C3.08797 4.73704 2.57926 4.94775 2.04883 4.94775C1.5184 4.94775 1.00969 4.73704 0.634614 4.36197C0.259542 3.98689 0.0488281 3.47819 0.0488281 2.94775Z"
                      fill="#151A42"
                    />
                  </svg>
                </div>
              </div>
              ))
            }
             {
              Package.length < 1 &&  <div className="Box">
              <div className="Left">
                <div className="Detail">
                  <h4>No package yet</h4>
                </div>
              </div>
            </div>
             }
            <div onClick={handleOpen} className="chip">
              Add Item
            </div>
          </div>
          <h4>SUMMARY</h4>
          <ul>
            <li>
              
              TOTAL QUANTITY
              <span>{TotalQuantity === 0 ? 0 : TotalQuantity}</span>
            </li>
            <li>
              TOTAL VALUE <span>{TotalValue === 0 ? 0 : TotalValue}</span>
            </li>
            <li>
             Overall Description
              <div onClick={handleOverallOpen} className="btn change">
                change
              </div>
            </li>
          </ul>
         
          <button onClick={SubmitForm}>CONTINUE</button>
          <small>
          By continuing, I represent that the declaration above is a proper and accurate description of the contents of my package.
          </small>
          <NestedBtn 
            handle={Previous}
            status={PriceStatus}
            name="previous" />
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <div className="Package_Modal_Wrapper">
                <div className="Box">
                  <label htmlFor="des">NAME</label>
                  <input
                    type="text"
                    placeholder="ex. Brown laptop bag made of cotton"
                    className={`${errors.name ? 'error' : ''}`}
                    {...register('name', { required: true })}
                  />
                  {errors.name && (
                    <span className={`${errors.name ? 'errorText' : ''}`}>
                      Name field is required
                    </span>
                  )}
                </div>
                <p>Give a name of what you are shipping</p>
                <div className="Box">
                  <label htmlFor="cat">DESCRIPTION</label>
                  <textarea
                  placeholder="Enter the description of the item"
                  className={`${errors.description ? 'error' : ''}`}
                  {...register('description', { required: true })}
                ></textarea>
                {errors.description && (
                  <span className={`${errors.description ? 'errorText' : ''}`}>
                    Description field is required
                  </span>
                )}
                </div>
                <p>Give a brief description that best describes your item. This is required for effective customs clearance when you ship internationally.</p>
                <div className="Top">
                <div className="Box">
                  <label htmlFor="cat">WEIGHT (KG)</label>
                  <input
                    type="tel"
                    placeholder="1"
                    className={`${errors.weight ? 'error' : ''}`}
                    {...register('weight', { required: true })}
                  />
                  {errors.weight && (
                    <span className={`${errors.weight ? 'errorText' : ''}`}>
                      Weight field is required
                    </span>
                  )}
                </div>
                <div className="Box">
                  <label htmlFor="cat">QUANTITY</label>
                  <input
                    type="tel"
                    placeholder="Enter quantity"
                    className={`${errors.quantity ? 'error' : ''}`}
                    {...register('quantity', { required: true })}
                  />
                  {errors.quantity && (
                    <span className={`${errors.quantity ? 'errorText' : ''}`}>
                      Quantity field is required
                    </span>
                  )}
                </div>
              </div>
                <p>Provide the value and quantity of the item you are trying to declare for shipping.</p>
                <div className="Top">
                <div className="Box">
                  <label htmlFor="cat">VALUE</label>
                  <input
                    type="tel"
                    placeholder="Enter value"
                    className={`${errors.value ? 'error' : ''}`}
                    {...register('value', { required: true })}
                  />
                  {errors.value && (
                    <span className={`${errors.value ? 'errorText' : ''}`}>
                      Value field is required
                    </span>
                  )}
                </div>
                <div className="Box">
                  <label htmlFor="cat">CURRENCY</label>
                  <select
                    name="currency"
                    className={`${errors.currency ? 'error' : ''}`}
                    {...register('currency', { required: true })}
                  >
                    <option value="">Choose Currency</option>
                    <option value="USD">USD</option>
                    <option value="NGN">NGN</option>
                  </select>
                  {errors.currency && (
                    <span className={`${errors.currency ? 'errorText' : ''}`}>
                      Currency is required
                    </span>
                  )}
                </div>
              </div>
                <button onClick={handleSubmit(onSubmit)}>ADD ITEM</button>
              </div>
            </Box>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={Editopen}
          onClose={handleEditClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={Editopen}>
            <Box sx={style}>
           {
            EditPackage!==null &&  <div className="Package_Modal_Wrapper">
            <div className="Box">
            <label htmlFor="des">NAME</label>
                  <input
                    type="text"
                    value={EditPackage.name}
                    onChange={(e) =>
                      setEditPackage((pre) => ({
                        ...pre,
                        name: e.target.value,
                      }))
                    }
                  />
            </div>
            <p>Give a name of what you are shipping</p>
            <div className="Box">
            <label htmlFor="cat">DESCRIPTION</label>
                  <textarea
                    value={EditPackage.description}
                    onChange={(e) =>
                      setEditPackage((pre) => ({
                        ...pre,
                        description: e.target.value,
                      }))
                    }
                  ></textarea>
            </div>
            <p>Give a brief description that best describes your item. This is required for effective customs clearance when you ship internationally.</p>
            <div className="Top">
                  <div className="Box">
                    <label htmlFor="cat">WEIGHT (KG)</label>
                    <input
                      type="tel"
                      value={EditPackage.weight}
                      onChange={(e) =>
                        setEditPackage((pre) => ({
                          ...pre,
                          weight: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="Box">
                    <label htmlFor="cat">QUANTITY</label>
                    <input
                      type="tel"
                      value={EditPackage.quantity}
                      onChange={(e) =>
                        setEditPackage((pre) => ({
                          ...pre,
                          quantity: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="Top">
                  <div className="Box">
                    <label htmlFor="cat">VALUE</label>
                    <input
                      type="tel"
                      value={EditPackage.value}
                      onChange={(e) =>
                        setEditPackage((pre) => ({
                          ...pre,
                          value: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="Box">
                    <label htmlFor="cat">CURRENCY</label>
                    <select
                      name="currency"
                      value={EditPackage.currency}
                      onChange={(e) =>
                        setEditPackage((pre) => ({
                          ...pre,
                          currency: e.target.value,
                        }))
                      }
                    >
                      <option value="">Choose Currency</option>
                      <option value="USD">  USD</option>
                      <option value="NGN">NGN</option>
                    </select>
                  </div>
                </div>
            <p>Provide the value and quantity of the item you are trying to declare for shipping.</p>
            <button onClick={SubmitUpdate}>UPDATE ITEM</button>
          </div>
           }
            </Box>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={OveralModal}
          onClose={() => setOveralModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={OveralModal}>
            <Box sx={style}>
              <div className="Package_Modal_Wrapper">
                <div className="Box">
                  <label htmlFor="cat">DESCRIPTION</label>
                  <textarea
                    value={Overall}
                    onChange={(e) => setOverall(e.target.value)}
                    placeholder="Enter overall description of the item"
                    className={`${isEmpty ? 'error' : ''}`}
                  ></textarea>
                  {isEmpty && (
                    <span className={`${isEmpty ? 'errorText' : ''}`}>
                      Overall Description is required
                    </span>
                  )}
                </div>
                <button onClick={OveralSubmit}>ADD OVERALL</button>
              </div>
            </Box>
          </Fade>
        </Modal>
      </>
    );
}
 
export default PackageDetail;