import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InternationalActions } from "../../../store/InternationalSlice";
import { NumericFormat } from "react-number-format";
import NestedBtn from "../../../components/NestedBtn";

const PaymentMethod = ({ setTab, CostStatus }) => {
  const { WalletDetails } = useSelector((state) => state.Auth);
  const Paymethod = useSelector((state) => state.International.PaymentOption);
  const { SelectedCarrier } = useSelector((state) => state.International);
  const dispatch = useDispatch();

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  useEffect(() => {}, []);

  const SubmitForm = () => {
    if (Paymethod === "") {
      return;
    } else {
      dispatch(InternationalActions.GetPaymentStatus(true));
      setTab(5);
      window.scrollTo(0, 0);
    }
  };

  const Previous = () => {
    setTab(3);
  };

  return (
    <div data-aos="fade-zoom-in" className="Payment_Method">
      <h3>Choose Payment Method</h3>
      <div className="Box">
        <h6>AVAILABLE PAYMENT MENTHOD</h6>
      </div>
      {
        (SelectedCarrier?.currency !== "USD" &&
        SelectedCarrier?.currency !== "EUR" &&
        SelectedCarrier?.currency !== "YEN" &&
        SelectedCarrier?.currency !== "GBP") && (
          <div className="Box">
            <div className="Detail">
              <h4>Paystack</h4>
              <h5>Pay using Mastercard, Visa or Verve card via paystack</h5>
            </div>
            <input
              value="Paystack"
              onChange={(e) =>
                dispatch(InternationalActions.GetPayment(e.target.value))
              }
              disabled={
                SelectedCarrier?.currency === "USD" ||
                SelectedCarrier?.currency === "EUR" ||
                SelectedCarrier?.currency === "YEN" ||
                SelectedCarrier?.currency === "GBP"
              }
              name="radio"
              type="radio"
              checked={Paymethod === "Paystack" ? true : false}
            />
          </div>
        )
      }
      
      <div className="Box">
        <div className="Detail">
          <h4>Stripe</h4>
          <h5>Pay using Mastercard, Visa card via stripe</h5>
        </div>
        <input
          value="Stripe"
          onChange={(e) =>
            dispatch(InternationalActions.GetPayment(e.target.value))
          }
          name="radio"
          type="radio"
          checked={Paymethod === "Stripe" ? true : false}
        />
      </div>
      {/* <div className="Box">
          <div className="Detail">
            <h4>Virtual bank transfer</h4>
            <h5>Pay by sending funds to a virtual bank account</h5>
          </div>
          <input
            value="Direct Transfer"
            onChange={(e) =>
              dispatch(InternationalActions.GetPayment(e.target.value))
            }
            name="radio"
            type="radio"
            checked={Paymethod === 'Direct Transfer' ? true : false}
          />
        </div> */}
      {
        (SelectedCarrier?.currency !== "USD" &&
        SelectedCarrier?.currency !== "EUR" &&
        SelectedCarrier?.currency !== "YEN" &&
        SelectedCarrier?.currency !== "GBP") && (
          <div className="Box">
            <div className="Detail">
              <h4>Wallet</h4>
              <h5>Pay using your Ella logistics Wallet</h5>
            </div>
            <input
              value="Wallet"
              onChange={(e) =>
                dispatch(InternationalActions.GetPayment(e.target.value))
              }
              name="radio"
              disabled={
                SelectedCarrier?.currency === "USD" ||
                SelectedCarrier?.currency === "EUR" ||
                SelectedCarrier?.currency === "YEN" ||
                SelectedCarrier?.currency === "GBP"
              }
              type="radio"
              checked={Paymethod === "Wallet" ? true : false}
            />
          </div>
        )
      }
      
      {
        Paymethod === "Wallet" && (
          <div className="Balance">
            <div className="Wrap">
              <h2>
                <NumericFormat
                  value={WalletDetails.account_balance}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                />
              </h2>
              <h5>NGN</h5>
            </div>
            <h6
              className={`${
                WalletDetails.account_balance < 100 ? "Danger_Balance" : ""
              }`}
            >
              {WalletDetails.account_balance < 100
                ? "Insufficient balance"
                : "Available balance"}
            </h6>
          </div>
        )
      }

      <button onClick={SubmitForm}>CONTINUE</button>
      <small>
        By continuing, I represent that the declaration above is a proper and
        accurate description of the contents of my package.
      </small>
      <div className="Nested_Bottom_Wrap">
        <NestedBtn handle={Previous} status={CostStatus} name="previous" />
      </div>
    </div>
  );
};

export default PaymentMethod;
