import React from "react";
import Countries from "./components/Countries";
import "../../Assets/style/ShipAddress.scss";

const ShippingAddresses = () => {
  return (
    <>
      <div className="Breadcrum">
        <h5>Shipping Address</h5>
      </div>
      <div className="shipCountries">
        <Countries />
      </div>
    </>
  );
};

export default ShippingAddresses;
