import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import "../../Assets/style/Shipments.scss"
import Card from "../Dashboard/components/Card";
import {
Bank,
CardData, 
CardTab,} from "./components";
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';



const EllaShipment = () => {

    const [Target,setTarget]=useState('')
    const Select=(index)=>setTarget(index)

    const {
        WalletDetails,
        BankDetails} = useSelector((state)=>state.Auth)


    return (
        <Fragment>
        <div className="Breadcrum">
        <h5 >shipments</h5>
        </div>
        <div className="Shipment_Logistic">
            <h2>What do you want to do today?</h2>
            <div className="Layout">
            <div className="Left">
            {
              CardData.map((item,index)=>
              <CardTab  key={index}
              handleClick={()=>Select(index)} active={Target===index ? 'active':''} item={item}/>
              )
            }
            </div>
            <div className="Right">
            <div className="Shipment_Top_Card">
                <Card
                title="Balance"
                value={WalletDetails.account_balance}
                subText="12%"
                spanText={WalletDetails.account_balance < 100 ? "Decrease From Target" : 'Increase From Target'}
                icon={WalletDetails.account_balance < 100 ? <SouthIcon/> : <NorthIcon/>}
                />
                <Card
                title="Total Fund"
                value={WalletDetails.total_funded}
                subText="12%"
                spanText={WalletDetails.total_funded < 100 ? "Decrease From Target" : 'Increase From Target'}
                icon={WalletDetails.total_funded < 100 ? <SouthIcon/> : <NorthIcon/>}
                />
                <Card
                title="Total Spent"
                value={WalletDetails.total_spent}
                subText="12%"
                spanText={WalletDetails.total_spent < 100 ? "Decrease From Target" : 'Increase From Target'}
                icon={WalletDetails.total_spent < 100 ? <SouthIcon/> : <NorthIcon/>}
                />
                </div>
                <Bank
                name={BankDetails.account_name}
                acct_name={BankDetails.bank}
                acct_number={BankDetails.account_number}
                />
            </div>
            </div>
        </div>
        </Fragment>
    );
}
 
export default EllaShipment;