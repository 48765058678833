const BankLogistic = ({acct_number,acct_name,name}) => {
    return (
        <div className="Bank">
            <h3>{name}</h3>
            <div className="Bank_Card">
                <div className="Bank_Top">
                    <div className="Left">
                    <h4>Account Number</h4>
                    <h3>{acct_number}</h3>
                    </div>
                    <div className="Ella">
                        <div className="Image"></div>
                    </div>
                </div>
                <div className="Bank_Bottom">
                    {/* <div className="Left">
                    <h4>Account Number</h4>
                    <h3>{acct_number}</h3>
                    </div> */}
                    <div className="Bottom_Right">
                    <h4>Bank</h4>
                    <h5>{acct_name}</h5>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default BankLogistic;