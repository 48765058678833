import { Fragment, useEffect, useState } from "react";
import "../../Assets/style/GeneralShipmetStyle.scss"
import "../../Assets/style/SenderForm.scss"
import "../../Assets/style/InterstateShipment.scss"
import {
    NavTab,
    Sender,
    Receiver,
    Price,
    Package,
    Payment,
    Confirm } from "./components";
import { useDispatch, useSelector } from "react-redux";
import { useLocation} from "react-router-dom";
import { ClearRecord } from "../../store/InterStateAction";

// import { NumericFormat } from 'react-number-format';



const InterstateShipment = () => {

    const dispatch=useDispatch()

    const Locate = useLocation()
    const path=Locate.pathname


    useEffect(()=>{
       if(path==='/interstate-shipment'){
        dispatch(ClearRecord())
       }
    },[])
   
    const {
        SenderStatus,
        ReceiverStatus,
        PackageStatus,
        PaymentStatus,
        PriceStatus} = useSelector((state)=>state.Interstate)

    const [Tab,setTab]=useState(0)

    return (
        <Fragment>
        <div className="Breadcrum">
        <h5 >Interstate Shipment</h5>
        {/* {
            PriceDetails==="" ? null : <div className="Cost_Wrap">
            <h3>Cost: </h3> <h3 className="Price"><NumericFormat value={PriceDetails.amount} displayType={'text'} thousandSeparator={true} prefix={'₦'} /></h3>
        </div>
          } */}
        </div>
        <div className="Interstate_Shipment">
        <NavTab
        Tab={Tab}
        sender={SenderStatus}
        receiver={ReceiverStatus}
        price={PriceStatus}
        package_detail={PackageStatus}
        payment={PaymentStatus}
        confirm=''
        setTab={setTab}
        />
        {Tab===0 && <Sender setTab={setTab} />}
        {Tab===1 && <Receiver
        SenderStatus={SenderStatus} 
        setTab={setTab} />}
        {Tab===2 && <Price
        ReceiverStatus={ReceiverStatus} 
        setTab={setTab} />}
        {Tab===3 && <Package
        PriceStatus={PriceStatus} 
        setTab={setTab}/>}
        {Tab===4 && <Payment 
        PackageStatus={PackageStatus}
        setTab={setTab} />}
        {Tab===5 && <Confirm
        PaymentStatus={PaymentStatus} 
        setTab={setTab}
        />}
        </div>
        </Fragment>
    );
}
 
export default InterstateShipment;