import { Box, Drawer } from "@mui/material";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Data } from ".";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useDispatch } from "react-redux";
import { ClearRecord } from "../../../store/AuthAction";

const EllaMobileDrawer = ({theme,handle,container,matchUpMd,drawerWidth,Toggle,display}) => {
   
    const dispatch=useDispatch()
    const navigate = useNavigate()

    const location = useLocation()
    const Locate = location.pathname

    const [activeLink,setActive]=useState('')
    const selectLink=(index)=>{
        setActive(index)
    }


    const Logout=()=>{
        dispatch(ClearRecord())
        navigate("/login",{replace: true})
    }


    return (
        <Drawer
        container={container}
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor={matchUpMd ? 'left' : 'right'}
        open={Toggle}
        onClose={handle}
        sx={{
            display: { xs:display, md:'none' },
            '& .MuiDrawer-paper': {
                width: drawerWidth,
                background: theme.palette.background.default,
                color: theme.palette.text.primary,
                borderRight: 'none',
                paddingTop:'0px',
                [theme.breakpoints.up('md')]: {
                    top: '88px'
                }
            }
            
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
        >
        <Box sx={{ overflow: 'auto' }}>
        <div className="SideBar_Logo"></div>
        {
            Data.map((item,index)=>(
                item.hasChildren===false ? <div onClick={()=>selectLink(index)} key={index} className={`Desktop_Anchor_Wrapper ${(activeLink===index || Locate===item.path) ? 'active' : ''}`}>
                {item.icon}<Link onClick={handle} to={item.path}> {item.title}</Link>
                </div>:
                item.hasChildren===true && <div onClick={()=>selectLink(index)} className='Dropdown_Anchor_Link'>
                     <div onClick={()=>selectLink(index)} key={index} className={`Desktop_Anchor_Wrapper ${(activeLink===index || Locate===item.path) ? 'active' : ''}`}>
                    {item.icon}<Link> {item.title}</Link>
                    {activeLink===index ? <ArrowDropDownIcon className="ChevronDown"/>: <ArrowRightIcon className="Chevron"/>}
                    </div>
                     
                     <div className={`DropDown_Wrap ${activeLink===index ? 'active' : ''}`}>
                        {
                            item.Children.map((sub,subindex)=>
                            <Link 
                            onClick={handle}
                            className={`${Locate===sub.link ? 'active' : ''}`}
                            key={subindex} 
                            to={sub.link}> {sub.name}</Link>
                            )
                        }
                     </div>
                </div>
            ))
         }
            <div className='Log_Out_Btn'  onClick={Logout} >
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.87012 16.1452H3.87012C3.47229 16.1452 3.09076 15.9871 2.80946 15.7058C2.52815 15.4245 2.37012 15.043 2.37012 14.6452V4.14517C2.37012 3.74735 2.52815 3.36582 2.80946 3.08451C3.09076 2.80321 3.47229 2.64517 3.87012 2.64517H6.87012" stroke="" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.1201 13.1452L15.8701 9.39517L12.1201 5.64517" stroke="" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.8701 9.39517H6.87012" stroke="" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <p>Log out</p>
            </div>
        </Box>
        </Drawer>
    );
}
 
export default EllaMobileDrawer;