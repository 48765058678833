import { useForm } from "react-hook-form";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { InternationalActions } from "../../../store/InternationalSlice";
import states from "../../../Data/states2.json";
// import countries from "../../../Assets/Files/countries.json";
import axios from "axios";
import { generateTransactionID } from "../../../Library/Utilities";
import { toast } from "react-toastify";
import { GetAllCities, GetCountries, GetProcessingUnitsUrl } from "../../../Library/URLs";
import rivers from "../../../Data/rivers.json";

const SenderForm = ({ setTab }) => {
  const dispatch = useDispatch();

  const { User } = useSelector((state) => state.Auth);
  const [countries, setCountries] = useState([]);
  const [processingUnits, setProcessingUnits] = useState([]);

  const [SelectedCountry, setSelectedCountry] = useState("Nigeria");

  const [PickState, setPickState] = useState("");
  const [ApiState, setApiState] = useState("");
  const [SelectedState, setSelectedState] = useState("");
  const [showStateSuggestions, setShowStateSuggestions] = useState(false);
  const [StateError, setStateError] = useState(false);

  const [cities, setCities] = useState([]);
  const [SelectedCity, setSelectedCity] = useState("");
  const [showCitySuggestions, setShowCitySuggestions] = useState(false);
  const [CityError, setCityError] = useState(false);
  const [PickCity, setPickCity] = useState("");


  const [country, setCountry] = useState("");
  const [countryError, setcountryError] = useState();
  const { Sender, SenderCountry, SenderState, SenderCity } = useSelector(
    (state) => state.International
  );

  const [showCountriesSuggestions, setShowCountriesSuggestions] =
    useState(false);
  const [countriesSuggestions, setCountriesSuggestions] = useState([]);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (country === "") {
      setcountryError(true);
      return;
    };
    
    if (PickState === "") {
      setStateError(true);
      return;
    };

    if(PickCity === "") {
      setCityError(true);
      return;
    };

    if (country !== "" && PickState !== "" && data) {
      dispatch(InternationalActions.GetSender(data));
      dispatch(InternationalActions.GetSenderCity(PickCity));
      dispatch(InternationalActions.GetSenderRegion(country));
      dispatch(InternationalActions.GetSenderState(PickState));
      dispatch(InternationalActions.GetSenderStatus(true));
      dispatch(InternationalActions.GetPriceDetails(""));
      // dispatch(InternationalActions.GetCost(false))
      setTab((pre) => pre + 1);
      window.scrollTo(0,0);
    }
  };

  const getCountries = async () => {
    const data = {
      user_id: User.userID,
      user_ses_id: User.sessionID,
      company_id: User.companyID,
      transaction_id: generateTransactionID(15),
    };

    await axios.post(GetCountries, data).then((response) => {
      console.log("countries", response.data);
      if (response.data.success === false) {
        toast.error(response.data.message);
      }
      if (response.data.success === true) {
        const res = response.data.data?.map((country) => ({
          ...country,
          name: country?.country,
          code: country?.iso_code,
        }));
        setCountries(res);
      }
    });
  };

  const getProcessingUnits = async () => {
    const data = {
      user_id: User.userID,
      user_ses_id: User.sessionID,
      company_id: User.companyID,
      transaction_id: generateTransactionID(15),
    };

    await axios.post(GetProcessingUnitsUrl, data).then((response) => {
      console.log("processing Units", response.data);
      if (response.data.success === false) {
        toast.error(response.data.message);
      }
      if (response.data.success === true) {
        setProcessingUnits(response?.data?.data);
      }
    });
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    getCountries();
    getProcessingUnits();
    // eslint-disable-next-line
  }, []);

  const selectCountry = (location) => {
    setCountry(location);
    setSelectedCountry(location);
    setcountryError(false);
    setShowCountriesSuggestions(false);
  };
  const selectState = (location) => {
    setPickState(location);
    setStateError(false);
    setShowStateSuggestions(false);
  };

  const selectCity = (location) => {
    setPickCity(location);
    setCityError(false);
    setShowCitySuggestions(false);
  }

  const searchCountry = (query) => {
    // dispatch(InternationalActions.GetCost(false))
    setCountry(query);

    if (query === "") {
      setShowCountriesSuggestions(false);
      return;
    }

    const filteredCountries = [];
    countries.map((country) => {
      if (country["name"].toLowerCase().includes(query.toLowerCase())) {
        filteredCountries.push(country["name"]);
      }
    });

    setCountriesSuggestions(filteredCountries);
    if (filteredCountries.length > 0) {
      setShowCountriesSuggestions(true);
    } else {
      setShowCountriesSuggestions(false);
    }
  };

  const searchState = (query) => {
    // dispatch(InternationalActions.GetCost(false))
    setPickState(query);

    if (query === "") {
      setShowStateSuggestions(false);
      return;
    }
    const filteredStates = [];
    if(SelectedCountry === "Nigeria") {
      states.forEach(city => {
        if (city["name"].toLowerCase().includes(query.toLowerCase())) {
          filteredStates.push(city["name"])
        }
      })
    } else {
      ApiState.forEach((city) => {
        if (city["name"].toLowerCase().includes(query.toLowerCase())) {
          filteredStates.push(city["name"]);
        }
      });
    }

    setSelectedState(filteredStates);
    if (filteredStates.length > 0) {
      setShowStateSuggestions(true);
    } else {
      setShowStateSuggestions(false);
    }
  };

  const searchCities = (query) => {
    // dispatch(InternationalActions.GetCost(false))
    setPickCity(query);

    if (query === "") {
      setShowCitySuggestions(false);
      return;
    };
    
    const filteredCities = [];

    let searchCities = PickState === "Rivers State" ? rivers : cities;

    searchCities.forEach((city) => {
      if (city.toLowerCase().includes(query.toLowerCase())) {
        filteredCities.push(city);
      }
    });

    setSelectedCity(filteredCities);
    if (filteredCities.length > 0) {
      setShowCitySuggestions(true);
    } else {
      setShowCitySuggestions(false);
    };
  };

  useEffect(() => {
    if (Sender !== "") {
      reset(Sender);
    }
    if (SenderCountry !== "") {
      setCountry(SenderCountry);
    }
    if (SenderState !== "") {
      setPickState(SenderState);
    }
    if (SenderCity !== "") {
      setPickCity(SenderCity)
    }
  }, [reset, Sender, SenderCountry, SenderState, SenderCity]);

  const FetchCity = async() => {
    console.log("hjdj", SelectedCountry, PickState);
    const raw = {
      user_id: User.userID,
      user_ses_id: User.sessionID,
      company_id: User.companyID,
      transaction_id: generateTransactionID(15),
      country: countries.find(
        (country) => country.name === SelectedCountry
      )?.name,
      state: PickState,
    };
    // console.log("post data",raw)
    await axios
      .post(GetAllCities, raw)
      .then((res) => {
        if (res.data.success === true) {
          setCities(res.data.data);
        }
        if (res.data.success === false) {
          // toast.error(res.data.message);
        }
      })
      .catch((e) => {
        // toast.error("fetching of city error" + e.message);
      });
  }

  useEffect(() => {
    if(PickState !== "" && country !== "") {
      FetchCity();
    }
  }, [PickState, country]);

  useEffect(() => {
    const FetchState = async () => {
      const raw = {
        user_id: User.userID,
        user_ses_id: User.sessionID,
        company_id: User.companyID,
        transaction_id: generateTransactionID(15),
        country: SelectedCountry,
      };
      // console.log("post data",raw)
      await axios
        .post("https://user.parcelflow.io/engine/v1/actions/get-states", raw)
        .then((res) => {
          if (res.data.success === true) {
            setApiState(res.data.data);
          }
          if (res.data.success === false) {
            toast.error(res.data.message);
          }
        })
        .catch((e) => {
          toast.error("fetching of city error" + e.message);
        });
    };
    if (SelectedCountry !== "") {
      setApiState("");
      FetchState();
      // console.log("International sender state data",ApiState)
    }
  }, [SelectedCountry]);

  return (
    <div className="Interstate_Form">
      <h3>Where will we be picking up from?</h3>
      <form autoCorrect="false" autoComplete="false">
        <div className="Top_Wrap">
          <div className="Form_Control">
            <label htmlFor="name">FIRST NAME</label>
            <input
              type="text"
              id="name"
              placeholder="First name"
              autoComplete="false"
              autoCorrect="false"
              className={`${errors.first_name ? "error" : "noError"}`}
              {...register("first_name", {
                required: true,
                onChange: dispatch(InternationalActions.GetCost(false)),
              })}
            />
            {errors.first_name && <span>first name field is required</span>}
          </div>
          <div className="Form_Control">
            <label htmlFor="last">LAST NAME</label>
            <input
              type="text"
              id="last"
              placeholder="Last name"
              autoComplete="false"
              autoCorrect="false"
              className={`${errors.last_name ? "error" : "noError"}`}
              {...register("last_name", {
                required: true,
                onChange: dispatch(InternationalActions.GetCost(false)),
              })}
            />
            {errors.last_name && <span>last name field is required</span>}
          </div>
        </div>

        <div className="Form_Control">
          <label htmlFor="street">ADDRESS LINE 1</label>
          <input
            type="text"
            id="street"
            placeholder="Address"
            autoComplete="false"
            autoCorrect="false"
            maxLength={35}
            className={`${errors.street ? "error" : "noError"}`}
            {...register("street", {
              required: true,
              onChange: dispatch(InternationalActions.GetCost(false)),
            })}
          />
          <p className="text-muted helperText" >Maximum length of 35 characters</p>
          {errors.street && <span>Address field is required</span>}
        </div>
        <div className="Form_Control">
          <label htmlFor="house">ADDRESS LINE 2 (optional)</label>
          <input
            type="text"
            id="house"
            placeholder="Address 2"
            autoComplete="false"
            autoCorrect="false"
          />
        </div>

        <div className="Form_Control">
          <label htmlFor="processing_unit">PROCESSING UNIT</label>
          <select
            name="processing_unit"
            id="processing_unit"
            className={`${errors.processing_unit ? "error" : "noError"}`}
            {...register("processing_unit", {
              required: true,
              onChange: dispatch(InternationalActions.GetCost(false)),
            })}
          >
            <option value="" hidden>
              Select Processing Unit
            </option>
            {processingUnits?.map((item, id) => (
              <option value={item.id} key={id}>
                {item?.city_name}
              </option>
            ))}
          </select>
        </div>

        <div className="PostCode_Wrap">
          <div className="Form_Control">
            <label htmlFor="country">COUNTRY</label>
            <input
              type="text"
              id="country"
              placeholder="Country"
              autoComplete="false"
              autoCorrect="false"
              value={country}
              className={`Country ${
                countryError === true ? "error" : "noError"
              }`}
              onChange={(e) => searchCountry(e.target.value)}
            />
            {countryError && (
              <span className={`${countryError ? "errorText" : ""}`}>
                Country is required
              </span>
            )}
            <div
              style={{ width: "86%" }}
              className={`suggestions-field ${
                showCountriesSuggestions ? "suggestions-field-visible" : null
              }`}
            >
              {countriesSuggestions.map((suggestions) => {
                return (
                  <div
                    onClick={(e) => selectCountry(suggestions, "Pickup")}
                    key={suggestions}
                    className="single-suggestion"
                  >
                    <span style={{ fontSize: 14, color: "#74788d" }}>
                      {suggestions}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="Form_Control">
            <label htmlFor="post">POST CODE</label>
            <input
              type="text"
              id="post"
              placeholder="Post Code"
              autoComplete="false"
              autoCorrect="false"
              className={`${errors.post_code ? "error" : "noError"}`}
              {...register("post_code", {
                required: true,
                onChange: dispatch(InternationalActions.GetCost(false)),
              })}
            />
            {errors.post_code && <span>Post code is required</span>}
          </div>
        </div>

        <div className="City_Wrap">
        <div className="Form_Control">
            <label htmlFor="last">STATE</label>
            <input
              type="text"
              id="last"
              value={PickState}
              placeholder="State"
              onChange={(e) => searchState(e.target.value)}
              className={`${StateError === true ? "error" : ""}`}
            />
            {StateError === true && <span>State field is required</span>}
            <div
              className={`suggestions-field ${
                showStateSuggestions === true
                  ? "suggestions-field-visible"
                  : null
              }`}
            >
              {showStateSuggestions === true &&
                SelectedState.map((suggestions) => (
                  <div
                    onClick={(e) => selectState(suggestions)}
                    key={suggestions}
                    className="single-suggestion"
                  >
                    <span style={{ fontSize: 14, color: "#74788d" }}>
                      {suggestions}
                    </span>
                  </div>
                ))}
            </div>
          </div>

          <div className="Form_Control">
            <label htmlFor="city">CITY</label>
            {/* <input
              type="text"
              id="city"
              placeholder="City"
              autoComplete="false"
              autoCorrect="false"
              className={`${errors.city ? "error" : "noError"}`}
              {...register("city", {
                required: true,
                onChange: dispatch(InternationalActions.GetCost(false)),
              })}
            /> */}
            <input
              type="text"
              id="last"
              value={PickCity}
              placeholder="City"
              onChange={(e) => searchCities(e.target.value)}
              className={`${CityError === true ? "error" : ""}`}
            />
            {CityError === true && <span>City field is required</span>}
            <div
              className={`suggestions-field ${
                showCitySuggestions === true
                  ? "suggestions-field-visible"
                  : null
              }`}
            >
              {showCitySuggestions === true &&
                SelectedCity.map((suggestions) => (
                  <div
                    onClick={(e) => selectCity(suggestions)}
                    key={suggestions}
                    className="single-suggestion"
                  >
                    <span style={{ fontSize: 14, color: "#74788d" }}>
                      {suggestions}
                    </span>
                  </div>
                ))}
            </div>
            {/* {errors.city && <span>City field is required</span>} */}
          </div>
        </div>

        <div className="Top_Wrap">
          <div className="Form_Control">
            <label htmlFor="phone">PHONE NUMBER</label>
            <input
              type="tel"
              id="phone"
              placeholder="Phone Number"
              autoComplete="false"
              autoCorrect="false"
              className={`${errors.phone_number ? "error" : "noError"}`}
              {...register("phone_number", {
                required: true,
                onChange: dispatch(InternationalActions.GetCost(false)),
              })}
            />
            {errors.phone_number && <span>Phone number field is required</span>}
          </div>

          {/* <div className="Form_Control">
            <label htmlFor="phone">WEIGHT</label>
            <input
              type="tel"
              id="weight"
              placeholder="Weight"
              autoComplete="false"
              autoCorrect="false"
              className={`${errors.weight ? "error" : "noError"}`}
              {...register("weight", {
                required: true,
                onChange: dispatch(InternationalActions.GetCost(false)),
              })}
            />
            {errors.weight && <span>weight field is required</span>}
          </div> */}
        </div>

        <div className="Save_Address">
          <input type="radio" name="" id="save" />
          <label htmlFor="save">SAVE TO ADDRESS BOOK</label>
        </div>

        <div onClick={handleSubmit(onSubmit)} className="FormBtn">
          CONTINUE
        </div>
      </form>
      <div className="Terms">
        By completing this transaction, you represent that you have read,
        understand and accept the Ella Logistics <a href="https://ellalogistics.africa/privacy-policy" target="_blank" rel="noreferrer">terms of service</a>{" "}
        and <a href="https://ellalogistics.africa/privacy-policy" target="_blank" rel="noreferrer">privacy policy</a>.
      </div>
    </div>
  );
};

export default SenderForm;
