import { useForm } from "react-hook-form";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import countries from "../../../Assets/Files/countries.json"
import { InternationalActions } from "../../../store/InternationalSlice";
import { CostData } from "../../../store/InternationalAction";
import { generateTransactionID } from "../../../Library/Utilities";
import axios from "axios";
import { toast } from "react-toastify";
import NestedBtn from "../../../components/NestedBtn";
import {
  calculateInterStateCostURL,
  GetCountries,
  GetAllCities
} from "../../../Library/URLs";
import rivers from "../../../Data/rivers.json";
import states from "../../../Data/states2.json";

const SenderForm = ({ setTab, SenderStatus }) => {
  const transaction_id = generateTransactionID(15);

  const [countries, setCountries] = useState([]);

  const { User } = useSelector((state) => state.Auth);

  const dispatch = useDispatch();

  const [SelectedCountry, setSelectedCountry] = useState("Nigeria");

  const [PickState, setPickState] = useState("");
  const [ApiState, setApiState] = useState("");
  const [SelectedState, setSelectedState] = useState("");
  const [showStateSuggestions, setShowStateSuggestions] = useState(false);
  const [StateError, setStateError] = useState(false);

  const [cities, setCities] = useState([]);
  const [SelectedCity, setSelectedCity] = useState("");
  const [showCitySuggestions, setShowCitySuggestions] = useState(false);
  const [CityError, setCityError] = useState(false);
  const [PickCity, setPickCity] = useState("");

  const [country, setCountry] = useState("");
  const [countryError, setcountryError] = useState(false);
  const {
    isLoading,
    Sender,
    SenderCountry,
    SenderState,
    Receiver,
    ReceiverCountry,
    ReceiverCity,
    ReceiverState,
    CostStatus,
  } = useSelector((state) => state.International);

  const [showCountriesSuggestions, setShowCountriesSuggestions] =
    useState(false);
  const [countriesSuggestions, setCountriesSuggestions] = useState([]);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const CalculateCost = async () => {
    const raw = {
      user_id: User.userID,
      user_type: "Regular",
      user_ses_id: User.sessionID,
      company_id: User.companyID,
      transaction_id: transaction_id,
      weight: Sender.weight,
      processor: "DHL",
      sender_address: Sender.street,
      sender_country: SenderCountry,
      sender_city: Sender.city,
      sender_state: SenderState,
      sender_zip_code: Sender.post_code,
      sender_phone: Sender.phone_number,
      receiver_address: Receiver.street,
      receiver_country: ReceiverCountry,
      receiver_city: Receiver.city,
      receiver_state: ReceiverState,
      receiver_zip_code: Receiver.post_code,
      receiver_phone_number: Receiver.phone_number,
      type: "International",
      request_pickup: "No",
    };
    await axios.post(calculateInterStateCostURL, raw).then((res) => {
      console.log("inter res", res.data);
    });
  };

  const getCountries = async () => {
    const data = {
      user_id: User.userID,
      user_ses_id: User.sessionID,
      company_id: User.companyID,
      transaction_id: generateTransactionID(15),
    };

    await axios.post(GetCountries, data).then((response) => {
      console.log("countries", response.data);
      if (response.data.success === false) {
        toast.error(response.data.message);
      }
      if (response.data.success === true) {
        const res = response.data.data?.map((country) => ({
          ...country,
          name: country?.country,
          code: country?.iso_code,
        }));
        setCountries(res);
      }
    });
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    getCountries();
    // eslint-disable-next-line
  }, []);

  const selectCountry = (location) => {
    setCountry(location);
    setSelectedCountry(location);
    setcountryError(false);
    setShowCountriesSuggestions(false);
  };

  const searchCountry = (query) => {
    // dispatch(InternationalActions.GetCost(false))
    setCountry(query);

    if (query === "") {
      setShowCountriesSuggestions(false);
      return;
    }

    const filteredCountries = [];
    countries.forEach((country) => {
      if (country["name"].toLowerCase().includes(query.toLowerCase())) {
        filteredCountries.push(country["name"]);
      }
    });

    setCountriesSuggestions(filteredCountries);
    if (filteredCountries.length > 0) {
      setShowCountriesSuggestions(true);
    } else {
      setShowCountriesSuggestions(false);
    }
  };

  useEffect(() => {
    if (Receiver !== "") {
      reset(Receiver);
    }
    if (ReceiverCountry !== "") {
      setCountry(ReceiverCountry);
    }
    if (ReceiverState !== "") {
      setPickState(ReceiverState);
    }
    if(ReceiverCity !== "") {
      setPickCity(ReceiverCity)
    }
  }, [reset, Receiver, ReceiverCountry, ReceiverState, ReceiverCity]);

  const onSubmit = (data) => {
    if (country === "") {
      setcountryError(true);
      return;
    }
    if (PickState === "") {
      setStateError(true);
      return;
    }
    if (PickCity === "") {
      setCityError(true);
      return;
    }
    if (country !== "" && PickState !== "" && data) {
      dispatch(InternationalActions.GetReceiver(data));
      dispatch(InternationalActions.GetReceiverRegion(country));
      dispatch(InternationalActions.GetReceiverCity(PickCity));
      dispatch(InternationalActions.GetReceiverState(PickState));
      dispatch(InternationalActions.GetReceiverStatus(true));
      // dispatch(InternationalActions.GetCost(false))
      dispatch(InternationalActions.GetPriceDetails(""));
      Next();
      // CalculateCost(User,Sender,SenderCountry,SenderState,Receiver,ReceiverCountry,ReceiverState)
      // dispatch(CostData(User,Sender,SenderCountry,SenderState,{...Receiver, data},ReceiverCountry,ReceiverState))
    }
  };

  const Next = () => {
    setTab(2);
    window.scrollTo(0,0);
  };

  const Previous = () => {
    setTab(0);
  };

  const selectState = (location) => {
    setPickState(location);
    setStateError(false);
    setShowStateSuggestions(false);
  };

  const selectCity = (location) => {
    setPickCity(location);
    setCityError(false);
    setShowCitySuggestions(false);
  }

  const searchState = (query) => {
    // dispatch(InternationalActions.GetCost(false))
    setPickState(query);

    if (query === "") {
      setShowStateSuggestions(false);
      return;
    }
    const filteredState = [];
    // ApiState.forEach((state) => {
    //   if (state["name"].toLowerCase().includes(query.toLowerCase())) {
    //     filteredState.push(state["name"]);
    //   }
    // });
    if(SelectedCountry === "Nigeria") {
      states.forEach(city => {
        if (city["name"].toLowerCase().includes(query.toLowerCase())) {
          filteredState.push(city["name"])
        }
      })
    } else {
      ApiState.forEach((city) => {
        if (city["name"].toLowerCase().includes(query.toLowerCase())) {
          filteredState.push(city["name"]);
        }
      });
    }

    setSelectedState(filteredState);
    if (filteredState.length > 0) {
      setShowStateSuggestions(true);
      // setStateError(false)
    } else {
      setShowStateSuggestions(false);
    }
  };

  const searchCities = (query) => {
    // dispatch(InternationalActions.GetCost(false))
    setPickCity(query);

    if (query === "") {
      setShowCitySuggestions(false);
      return;
    };
    
    const filteredCities = [];

    let searchCities = PickState === "Rivers State" ? rivers : cities;

    searchCities.forEach((city) => {
      if (city.toLowerCase().includes(query.toLowerCase())) {
        filteredCities.push(city);
      }
    });

    setSelectedCity(filteredCities);
    if (filteredCities.length > 0) {
      setShowCitySuggestions(true);
    } else {
      setShowCitySuggestions(false);
    };
  };

  const FetchCity = async() => {
    console.log("hjdj", SelectedCountry, PickState);
    const raw = {
      user_id: User.userID,
      user_ses_id: User.sessionID,
      company_id: User.companyID,
      transaction_id: generateTransactionID(15),
      country: countries.find(
        (country) => country.name === SelectedCountry
      )?.name,
      state: PickState,
    };
    // console.log("post data",raw)
    await axios
      .post(GetAllCities, raw)
      .then((res) => {
        if (res.data.success === true) {
          setCities(res.data.data);
        }
        if (res.data.success === false) {
          // toast.error(res.data.message);
        }
      })
      .catch((e) => {
        // toast.error("fetching of city error" + e.message);
      });
  }

  useEffect(() => {
    if(PickState !== "" && country !== "") {
      FetchCity();
    }
  }, [PickState, country]);

  useEffect(() => {
    const FetchCity = async () => {
      const raw = {
        user_id: User.userID,
        user_ses_id: User.sessionID,
        company_id: User.companyID,
        transaction_id: generateTransactionID(15),
        country: SelectedCountry,
      };
      // console.log("post data",raw)
      await axios
        .post("https://user.parcelflow.io/engine/v1/actions/get-states", raw)
        .then((res) => {
          if (res.data.success === true) {
            setApiState(res.data.data);
            // console.log("api city",ApiState)
          }
          if (res.data.success === false) {
            toast.error(res.data.message);
          }
        })
        .catch((e) => {
          toast.error("fetching of city error" + e.message);
        });
    };
    if (SelectedCountry !== "") {
      setApiState("");
      FetchCity();
      // console.log("International Receiver state data",ApiState)
    }
  }, [SelectedCountry]);

  // useEffect(()=>{
  //   dispatch(InternationalActions.GetPriceStatus(true))
  // })

  return (
    <div className="Interstate_Form">
      <h3>Where will we be sending to?</h3>
      <form autoCorrect="false" autoComplete="false">
        <div className="Top_Wrap">
          <div className="Form_Control">
            <label htmlFor="name">FIRST NAME</label>
            <input
              type="text"
              id="name"
              placeholder="First name"
              autoComplete="false"
              autoCorrect="false"
              className={`${errors.first_name ? "error" : "noError"}`}
              {...register("first_name", { required: true })}
            />
            {errors.first_name && <span>first name field is required</span>}
          </div>
          <div className="Form_Control">
            <label htmlFor="last">LAST NAME</label>
            <input
              type="text"
              id="last"
              placeholder="Last name"
              autoComplete="false"
              autoCorrect="false"
              className={`${errors.last_name ? "error" : "noError"}`}
              {...register("last_name", { required: true })}
            />
            {errors.last_name && <span>last name field is required</span>}
          </div>
        </div>

        <div className="Form_Control">
          <label htmlFor="street">ADDRESS LINE 1</label>
          <input
            type="text"
            id="street"
            placeholder="Address"
            autoComplete="false"
            autoCorrect="false"
            className={`${errors.street ? "error" : "noError"}`}
            {...register("street", { required: true })}
          />
          <p className="text-muted helperText">
            Maximum length of 35 characters
          </p>
          {errors.street && <span>Address field is required</span>}
        </div>

        <div className="Form_Control">
          <label htmlFor="house">ADDRESS LINE 2 (optional)</label>
          <input
            type="text"
            id="house"
            autoComplete="false"
            autoCorrect="false"
            placeholder="Address 2"
          />
        </div>

        <div className="PostCode_Wrap">
          <div className="Form_Control">
            <label htmlFor="country">COUNTRY</label>
            <input
              type="text"
              id="country"
              placeholder="Country"
              autoComplete="false"
              autoCorrect="false"
              value={country}
              className={`Country${countryError ? "error" : "noError"}`}
              onChange={(e) => searchCountry(e.target.value)}
            />
            {countryError && (
              <span className={`${countryError ? "errorText" : ""}`}>
                Country is required
              </span>
            )}
            <div
              style={{ width: "86%" }}
              className={`suggestions-field ${
                showCountriesSuggestions ? "suggestions-field-visible" : null
              }`}
            >
              {countriesSuggestions.map((suggestions) => {
                return (
                  <div
                    onClick={(e) => selectCountry(suggestions, "Pickup")}
                    key={suggestions}
                    className="single-suggestion"
                  >
                    <span style={{ fontSize: 14, color: "#74788d" }}>
                      {suggestions}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="Form_Control">
            <label htmlFor="post">POST CODE</label>
            <input
              type="text"
              id="post"
              placeholder="Post Code"
              autoComplete="false"
              autoCorrect="false"
              className={`${errors.post_code ? "error" : "noError"}`}
              {...register("post_code", { required: true })}
            />
            {errors.post_code && <span>Post code field is required</span>}
          </div>
        </div>

        <div className="City_Wrap">
          <div className="Form_Control">
            <label htmlFor="last">STATE</label>
            <input
              type="text"
              id="last"
              value={PickState}
              placeholder="State"
              autoComplete="false"
              autoCorrect="false"
              onChange={(e) => searchState(e.target.value)}
              className={`${StateError === true ? "error" : ""}`}
            />
            {StateError === true && <span>State field is required</span>}
            <div
              className={`suggestions-field ${
                showStateSuggestions ? "suggestions-field-visible" : null
              }`}
            >
              {showStateSuggestions === true &&
                SelectedState.map((suggestions) => (
                  <div
                    onClick={(e) => selectState(suggestions)}
                    key={suggestions}
                    className="single-suggestion"
                  >
                    <span style={{ fontSize: 14, color: "#74788d" }}>
                      {suggestions}
                    </span>
                  </div>
                ))}
            </div>
          </div>
          <div className="Form_Control">
            <label htmlFor="city">CITY</label>
            {/* <input
              type="text"
              id="city"
              placeholder="City"
              autoComplete="false"
              autoCorrect="false"
              className={`${errors.city ? "error" : "noError"}`}
              {...register("city", { required: true })}
            /> */}
            <input
              type="text"
              id="last"
              value={PickCity}
              placeholder="City"
              onChange={(e) => searchCities(e.target.value)}
              className={`${CityError === true ? "error" : ""}`}
            />
            {CityError === true && <span>City field is required</span>}
            <div
              className={`suggestions-field ${
                showCitySuggestions === true
                  ? "suggestions-field-visible"
                  : null
              }`}
            >
              {showCitySuggestions === true &&
                SelectedCity.map((suggestions) => (
                  <div
                    onClick={(e) => selectCity(suggestions)}
                    key={suggestions}
                    className="single-suggestion"
                  >
                    <span style={{ fontSize: 14, color: "#74788d" }}>
                      {suggestions}
                    </span>
                  </div>
                ))}
            </div>
            {/* {errors.city && <span>city field is required</span>} */}
          </div>
        </div>

        <div className="Form_Control">
          <label htmlFor="phone">PHONE NUMBER</label>
          <input
            type="tel"
            id="phone"
            placeholder="Phone Number"
            autoComplete="false"
            autoCorrect="false"
            className={`${errors.phone_number ? "error" : "noError"}`}
            {...register("phone_number", { required: true })}
          />
          {errors.phone_number && <span>Phone number field is required</span>}
        </div>

        <div className="Save_Address">
          <input type="radio" name="" id="save" />
          <label htmlFor="save">SAVE TO ADDRESS BOOK</label>
        </div>

        {/* <div onClick={PriceStatus===true ? Next : handleSubmit(onSubmit)} className="FormBtn">
             {
                isLoading===true ? <span className="spinner-border spinner-grow-sm"/>:(
                  PriceStatus===true ? "CONTINUE" : "CALCULATE COST"
                )
              }
             </div> */}
        {/* {
              CostStatus===true ?  <div onClick={Next} className="FormBtn">
              CONTINUE
              </div> : <div onClick={handleSubmit(onSubmit)} className="FormBtn">
              {
                isLoading===true ? <span className="spinner-border spinner-grow-sm"/>:"CALCULATE COST"
              }
             </div>
             } */}
        <div onClick={handleSubmit(onSubmit)} className="FormBtn">
          CONTINUE
        </div>
      </form>
      <div className="Terms">
        By completing this transaction, you represent that you have read,
        understand and accept the Ella Logistics <a href="https://ellalogistics.africa/privacy-policy" target="_blank" rel="noreferrer">terms of service</a>{" "}
        and <a href="https://ellalogistics.africa/privacy-policy" target="_blank" rel="noreferrer">privacy policy</a>.
      </div>
      <div className="Nested_Bottom_Wrap">
        <NestedBtn handle={Previous} status={SenderStatus} name="previous" />
      </div>
    </div>
  );
};

export default SenderForm;
