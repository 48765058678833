import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { loginURL } from '../../Library/URLs';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { AuthActions } from '../../store/AuthSlice';
import {generateTransactionID} from "../../Library/Utilities";

const LogInForm = () => {

    const dispatch=useDispatch()
    const navigate=useNavigate()

    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm();

    const [isLoading,setLoading]=useState(false)

    function getDevice() {
        let operatingSystem = 'Not known';
        if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Windows OS'; }
        if (window.navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'MacOS'; }
        if (window.navigator.appVersion.indexOf('X11') !== -1) { operatingSystem = 'UNIX OS'; }
        if (window.navigator.appVersion.indexOf('Linux') !== -1) { operatingSystem = 'Linux OS'; }

        let currentBrowser = 'Not known';
        if (window.navigator.userAgent.indexOf('Chrome') !== -1) { currentBrowser = 'Chrome'; }
        else if (window.navigator.userAgent.indexOf('Firefox') !== -1) { currentBrowser = 'Mozilla Firefox'; }
        else if (window.navigator.userAgent.indexOf('MSIE') !== -1) { currentBrowser = 'Internet Exployer'; }
        else if (window.navigator.userAgent.indexOf('Edge') !== -1) { currentBrowser = 'Edge'; }
        else if (window.navigator.userAgent.indexOf('Safari') !== -1) { currentBrowser = 'Safari'; }
        else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'Opera'; }
        else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'YaBrowser'; }

        return currentBrowser + " on " + operatingSystem;
    }

    const loginUser = (data) => {
        console.log("hello..")
        setLoading(true)
        const raw={
            "phone":data.EmailPhone,
            "password":data.Password,
            "device_id":"value",
            "device_name":getDevice(),
            "transaction_id":generateTransactionID(15),
            "token":"token",
            "company_id":65
        }
        console.log(raw);

        axios
            .post(loginURL, raw)
            .then(function (response) {
                console.log("res",response);
                setLoading(true)

                if (response["data"]["success"] === false) {
                    setLoading(false)
                    toast.error(response["data"]["message"])
                } else if (response["data"]["success"] === true) {
                    const result=response.data.data
                    dispatch(AuthActions.GetUser({
                        fullname:result.fullname,
                        email:result.email,
                        phone:result.phone,
                        profileImage:result.profile_image_url,
                        userID:result.user_id,
                        sessionID:result.user_ses_id,
                        companyID:result.company_id,
                        paystack_key:result.paystack_public_key,
                    }))
                    dispatch(AuthActions.GetAuth(true))
                    toast.success(response["data"]["message"])
                    setLoading(false)
                    navigate('/dashboard', {replace: true});
                }
            })
            .catch(function (error) {
                return toast.error("login error"+error.message);
            });
    };
    
    const Submit=(data)=>{
        loginUser(data)
    }


    return (
        <div className="LogIn_Form">
           <div className="Wrapper">
          <Link to="/"><div className="Logo"></div></Link>
            <h4>Hey, Hello</h4>
            <p>To continue, please provide a valid phone number.</p>
            <form onSubmit={handleSubmit(Submit)}>
                <div className="Box">
                <input 
                type="text" 
                placeholder="Email/Phone number"
                className={`Email ${errors.EmailPhone ? 'error':''}`}
                {...register('EmailPhone', { required: true })}
                />
                {errors.EmailPhone && <span>Email/Phone is required.</span>}
                </div>

                <div className="Box">
                <input 
                type="password" 
                placeholder="Password"
                className={`${errors.Password ? 'error':''}`}
                {...register('Password', { required: true })}
                />
                {errors.Password && <span>Password is required.</span>}
                </div>

                <div className="Checkbox_Wrap">
                    <div className="Check_Box">
                     <input
                     type="checkbox"
                     id="check"
                     />
                     <label htmlFor="check">Remember me</label>
                    </div>
                    
                    <span>
                    <Link to="/reset-password">Forgot Pasword?</Link>
                    </span>
                </div>

                <div className="Descrip">
                By completing this transaction, you represent that you have read, understand and accept the Ella Logistics <a href="https://ellalogistics.africa/privacy-policy" target="_blank" rel="noreferrer">terms of service</a> and <a href="https://ellalogistics.africa/privacy-policy" target="_blank" rel="noreferrer">privacy policy</a>.
                </div>
                <button>{
                    isLoading===true ?  <span className="spinner-border spinner-grow-sm"/>:
                    'LOGIN'} </button>
                <div className="Sign_up">
                <Link to="/register">Don't have an account? Sign up</Link>
                </div>
            </form>
           </div>
        </div>
    );
}
 
export default LogInForm;