import "../Assets/style/Button.scss"

const NestedBtn = ({name,width,handle,status}) => {
    return (
        <button
        disabled={status===true ? false : true}
        onClick={handle}
        style={{width:`${width ? width : '125px'}`}}
        className="Nested_Btn">
           { name}
        </button>
    );
}
 
export default NestedBtn;