import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Route from "./Routes"
import NavigationScroll from './components/NavigationScroll';
import { useEffect } from 'react';

function App() {
  
  // const clearCacheData = () => {
  //   caches.keys().then((names) => {
  //     names.forEach((name) => {
  //       caches.delete(name);
  //     });
  //   });
  // };


  useEffect(()=>{
      // clearCacheData()
      localStorage.setItem('EllaStateChange',true)
      if(localStorage.getItem('EllaStateChange')!==false){
        localStorage.clear()
        localStorage.setItem('EllaStateChange',false)
      }
  });

  useEffect(() => {
    window.gtag('config', 'AW-11270102553');
  }, [])

  return (
    <NavigationScroll>
      <Route/>
      <ToastContainer />
    </NavigationScroll>
  );
}

export default App;
