import { useDispatch, useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";
import "../../../Assets/style/Price.scss";
import NestedBtn from "../../../components/NestedBtn";
import { useState } from "react";
import { InternationalActions } from "../../../store/InternationalSlice";

const PricePage = ({ PackageStatus, setTab }) => {
  const dispatch = useDispatch();

  const { Package, TotalQuantity, TotalValue, SelectedCarrier, Carrier_Info } =
    useSelector((state) => state.International);

  const [Selected, setPick] = useState(SelectedCarrier?.carrier_id ?? "");

  const Previous = () => {
    setTab(2);
  };

  const Next = () => {
    console.log("alive?");
    dispatch(InternationalActions.GetCost(true));
    setTab(4);
    window.scrollTo(0, 0);
  };

  return (
    <div className="Price_Page">
      {/* <h3>Prices</h3> */}

      {Carrier_Info?.map((carrier, id) => (
        <div
          onClick={
            carrier?.selling_price === "Coming Soon"
              ? () => {}
              : () => {
                  setPick(carrier?.carrier_id);
                  dispatch(InternationalActions.GetSelectedCarrier(carrier));
                }
          }
          className={`Price_Card ${
            Selected === carrier?.carrier_id ? "active" : ""
          }`}
          key={id}
        >
          <div className="Wrap">
            <div className="Logo">
              <img src={carrier?.carrier_logo} alt="" />
            </div>
            <div className="Right">
              <h2>
                {carrier?.selling_price === "Coming Soon"
                  ? carrier?.selling_price
                  : `${
                      carrier?.currency === "USD"
                        ? "$"
                        : carrier?.currency === "NGN"
                        ? "₦"
                        : carrier?.currency === "GBP" ?
                        "£"
                        : carrier?.currency === "EUR"
                        ? "€"
                        : "₦"
                    }${carrier?.selling_price}`}
              </h2>
            </div>
          </div>
          <div className="Wrap">
            <div className="Left Name">{carrier?.carrier_name}</div>
            <div className="Right">{carrier?.delivery_time}</div>
          </div>
          {/* <div className="Wrap">
                <div className="Left">
                  PickUp
                </div>
                <div className="Right">
                
                </div>
                </div>
                <div className="Wrap">
                <div className="Left">
                  Delivery
                </div>
                <div className="Right">
                
                </div>
                </div> */}
        </div>
      ))}

      <button onClick={Next} disabled={!(PackageStatus && Selected !== "")}>
        {/* <button onClick={Next} disabled={!(Selected !== "")} > */}
        CONTINUE
      </button>
      <div className="Nested_Bottom_Wrap">
        <NestedBtn handle={Previous} status={PackageStatus} name="previous" />
        {/* <NestedBtn
          handle={Next}
          status={}
          name="CONTINUE"
        /> */}
      </div>
    </div>
  );
};

export default PricePage;
