import "../../Assets/style/Setting.scss";
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    outline: 'none',
    outerWidth: '0px',
    borderRadius: '5px',
    p:0,
};

const EllaProfile = () => {

    const {User} = useSelector((state)=>state.Auth)

    const [ File, setFile]=useState(null)
    const handleUpload=(e)=>{
        setFile(URL.createObjectURL(e.target.files[0]))
    }


    const [open, setOpen] = useState(false);
    const[PWD,setPWD]=useState('')
    const[OldPWD,setOldPWD]=useState('')
    const handleOpen = () =>setOpen(true)
    const handleClose = () => setOpen(false);

    const [Mobileopen, setMobileopen] = useState(false);
    const MobilehandleOpen = () =>setMobileopen(true)
    const MobilehandleClose = () => setMobileopen(false);

    const [Mailopen, setMailopen] = useState(false);
    const MailhandleOpen = () =>setMailopen(true)
    const MailhandleClose = () => setMailopen(false);



    return (
        <Fragment>
        <div className="Breadcrum">
        <h5 >settings</h5>
        </div>
         <div className="Setting_Wrapper">
         <div className="Right">
            <div className="Detail_Wrapper">
                <div className="Box">
                    <div className="Left">
                        <h4>Basic Details</h4>
                    </div>
                    <div className="Right">
                        <div className="Btn">Edit</div>
                    </div>
                </div>
                <div className="Box">
                    <div className="Left">
                        <h4>First Name</h4>
                    </div>
                    <div className="Right">
                        <p>{User.fullname}</p>
                    </div>
                </div>
                <div className="Box">
                    <div className="Left">
                        <h4>Email</h4>
                    </div>
                    <div className="Right">
                        <p>{User.email}</p>
                    </div>
                </div>
                <div className="Box">
                    <div className="Left">
                        <h4>Change Password</h4>
                    </div>
                    <div className="Right">
                        <div onClick={handleOpen} className="Btn">Change</div>
                    </div>
                </div>
                <div className="Box">
                    <div className="Left">
                        <h4>Phone Number</h4>
                        {User.phone==="" && <div className="Btn">Not Verified</div>}
                    </div>
                    <div className="Right">
                        <p>{User.phone!=='' ? User.phone : '+2340...'}</p>
                        <div onClick={MobilehandleOpen} className="Btn">Change</div>
                    </div>
                </div>
                <div className="Box">
                    <div className="Left">
                        <h4>Email</h4>
                        {User.email==="" && <div className="Btn">Not Verified</div>}
                    </div>
                    <div className="Right">
                        <p>{User.email!=='' ? User.email : '@...'}</p>
                        <div onClick={MailhandleOpen} className="Btn">Change</div>
                    </div>
                </div>
                <div className="Box">
                    <div className="Left">
                        <h4>Referral Code</h4>
                    </div>
                    <div className="Right">
                        <p>4AB%35C</p>
                    </div>
                </div>
            </div>
            </div>
            <div className="Left">
                {(User.profileImage==="" && File===null) && <div className="Box">
                    <h4>No profile Picture</h4>
                    <input onChange={handleUpload} style={{display:'none'}} type="file" id="file" />
                    <label htmlFor="file">
                    <div className="Label_Box">
                        <AddIcon/>
                        <span>Click to add image</span>
                    </div>
                    </label>
                </div>}
               {(User.profileImage!=="" || File!==null)  &&  <div className="Box">
               <input onChange={handleUpload} style={{display:'none'}} type="file" id="file" />
                   <img src={File===null ? User.profileImage : File} alt="avatar" />
                   <label htmlFor="file">
                    <span className="Btn">Change Upload</span>
                   </label>
                </div>}
            </div>
        </div>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="Settin_Modal_Wrapper">
                        <div className="Details_Wrapper Amount">
                        <label>CHANGE PASSWORD</label>
                        <input type="password"  onChange={(e)=>setOldPWD(e.target.value)} placeholder="Enter Current Password" />
                        <input type="password" onChange={(e)=>setPWD(e.target.value)} placeholder="Enter New Password" />
                        <input type="password" placeholder="Confirm New Password" />
                        <div  className="Btn">UPDATE</div>
                        </div>
                      
                    </div>
                </Box>
            </Fade>
        </Modal>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={Mobileopen}
            onClose={MobilehandleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={Mobileopen}>
                <Box sx={style}>
                    <div className="Settin_Modal_Wrapper">
                        <div className="Details_Wrapper Amount">
                        <label>CHANGE PHONE Number</label>
                        <input type="tel" value={User.phone} placeholder="Enter Current Phone Number" />
                        <input type="tel" placeholder="Enter New Phone Number" />
                        <input type="tel" placeholder="Confirm New Phone Number" />
                        <div className="Btn">UPDATE</div>
                        </div>
                      
                    </div>
                </Box>
            </Fade>
        </Modal>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={Mailopen}
            onClose={MailhandleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={Mailopen}>
                <Box sx={style}>
                    <div className="Settin_Modal_Wrapper">
                        <div className="Details_Wrapper Amount">
                        <label>CHANGE Email</label>
                        <input type="email" value={User.email} placeholder="Enter Current Email" />
                        <input type="email" placeholder="Enter New Email" />
                        <input type="email" placeholder="Confirm New Email" />
                        <div className="Btn">UPDATE</div>
                        </div>
                      
                    </div>
                </Box>
            </Fade>
        </Modal>
       </Fragment>
    );
}
 
export default EllaProfile;