import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  outline: "none",
  outerWidth: "0px",
  borderRadius: "5px",
  p: 4,
};

export const AddressModal = ({ open, handleClose, country }) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <div className="Country_Modal_Wrapper">
            <span onClick={handleClose} className="close_btn">
              <CloseIcon />
            </span>
            <div className="modalContent">
              <div>
                <img src={country?.flag_url} alt="" />
              </div>
              <div>
                <p className="countryRecipient" >{country?.recipient_name} </p>
                <p className="countryName">{country?.country} </p>
                <p className="countryAddress">{country?.address} </p>
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};
