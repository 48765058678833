import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { RequestPWDURL } from '../../Library/URLs';
import axios from 'axios';
import { toast } from 'react-toastify';
import {generateTransactionID} from "../../Library/Utilities";

const ChangePWDForm = () => {

    const navigate=useNavigate()

    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm();

    const [isLoading,setLoading]=useState(false)
     
    const PWDRequest = (data) => {
        setLoading(true)
        const raw={
            "email":data.Email,
            "transaction_id":generateTransactionID(15),
            "company_id":65
        }
        console.log(raw);

        axios
            .post(RequestPWDURL, raw)
            .then(function (response) {
                console.log("res",response);
                setLoading(true)

                if (response["data"]["success"] === false) {
                    setLoading(false)
                    toast.error(response["data"]["message"])
                } else if (response["data"]["success"] === true) {
                    toast.success(response["data"]["message"]+'  check your email for reset code')
                    setLoading(false)
                    navigate('/change-password', {replace: true});
                }
            })
            .catch(function (error) {
                return toast.error("Password request error"+error.message);
            });
    };
    
    const Submit=(data)=>{
        console.log(data)
        PWDRequest(data)
    }



    return (
        <div className="LogIn_Form">
        <div className="Wrapper">
         <Link to="/"><div className="Logo"></div></Link>
         <h4>Hey, Hello</h4>
         <p>To continue, please provide a valid detail.</p>
         <form onSubmit={handleSubmit(Submit)}>
             <div className="Box">
             <input 
             type="email" 
             placeholder="Enter email"
             className={`${errors.Email ? 'error':''}`}
             {...register('Email', { required: true })}
             />
             {errors.Email && <span>Email is required.</span>}
             </div>
             <Link className="Forgot_Text" to="/login">Already have an account</Link>
             <button> {
                 isLoading===true ?  <span className="spinner-border spinner-grow-sm"/>:'SEND'
                 } </button>
         </form>
        </div>
     </div>
    );
}
 
export default ChangePWDForm;