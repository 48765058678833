import { useNavigate } from "react-router-dom";
import "../../Assets/style/ErrorPage.scss"
import Button from "../../components/Button"


const Ella404Page = () => {
    const navigate=useNavigate()

    const Back=()=>{
        navigate(-1,{replace: true})
    }
    return (
        <div className="Error_Page">
            <div className="Error_Image">
            <div onClick={Back} className="Home_Btn">
                Back
            </div>
            </div>
            
        </div>
    );
}
 
export default Ella404Page;