import { Link } from "react-router-dom";
import "../../Assets/style/LogIn.scss"
import LogInForm from "./LoginForm";

const LogInPage = () => {
    return (
        <div className="Login_Wrapper">
         <div className="Left">
           <Link to="/"> <div className="Logo"></div></Link>
            <p>We take care about<br/>transportation for your business</p>
            <div className="Transport"></div>
         </div>
         <div className="Right">
         <LogInForm/>
         </div>
        </div>
    );
}
 
export default LogInPage;