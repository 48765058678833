import { Link } from "react-router-dom";
import { FaInstagram, FaFacebook } from "react-icons/fa";

const HomeFooter = () => {
    return (
        <div className="Footer">
           <div className="Container">
           <ul>
                <li>
                    <Link to="/" ><div className="Logo"></div></Link>
                </li>
                <li>Ella Logistics is a privately owned company that provides top notch courier and logistics solutions Headquartered in Lagos Nigeria. We have a substantial operating capacity powered by a growing team of professional in the logistics industry. </li>
            </ul>
            <ul>
                <li className="Title">Ship</li>
                <li>
                    <address>
                    Plot 7 Niyi Okunubi street opposite Mecure Lekki Phase 1 Lekki Lagos Nigeria.
                    </address>
                </li>
            </ul>
            <ul>
                <li className="Title">About Us</li>
                <li>What we do </li>
                <li>Services</li>
            </ul>
            <ul>
                <li className="Title">Connect</li>
                <li>Phone: +234 704 921 4981</li>
                <li>Mobile: +234 704 921 4980</li>
                <li>Email: info@ellalogistics.africa</li>
                <li>
                    <div className="d-flex gap-2 align-items-center" >
                        <a target="_blank" rel="noreferrer" href="https://www.instagram.com/ellalogistics/"><FaInstagram style={{fontSize:18}} /></a>
                        <a target="_blank" rel="noreferrer" href="http://facebook.com/ellalogisticservices/"><FaFacebook style={{fontSize:18}} /></a>
                    </div>
                </li>
                <li></li>
            </ul>
           </div>
           <div className="Bottom">
            <p>© 2023 Ella Logistics. All right reserved.</p>
            <ul>
                <li><Link to="/privacy-policy" className="text-light" >Privacy Policy</Link></li>
            </ul>
           </div>
        </div>
    );
}
 
export default HomeFooter;