// const domain = "https://user.parcelflow.io/engine/v1/actions/";
const domain = "https://parcelflow-user-b4e5130303b2.herokuapp.com/engine/v1/actions/";

export const loginURL = domain + "login";
export const registerURL = domain + "registration";
export const logoutURL = domain + "logout";
export const getAddressSuggestionsURL = domain + "auto-complete-search-new";
export const calculateLocalCostURL = domain + "calculate-shipment-cost-new";
export const calculateInterStateCostURL = domain + "calculate-shipment-cost-1";
export const createInternationalShipment = domain + "create-dhl-export-shipment.php";
export const createInterstateShipment = domain + "create-inter-shipment-terminal.php";
export const createLocalShipment = domain + "create-shipment";
export const bookInterstateShipment = domain + "create-inter-shipment-terminal-new";
export const ShipInternationalShipment = domain + "create-inter-shipment-terminal-new"
export const getDashboardStatisticsURL = domain + "get-dashboard-stats";
export const createLocalShipmentURL = domain + "get-transactions";
export const getShipmentsURL =domain + "get-wallet-history";
export const getShipmentHistory=domain + "get-shipments"
export const searchDHLCityURL = domain + "search-dhl-cities";
export const getWalletBalanceURL = domain + "get-wallet";
export const getBankDetailURL = domain + "get-customer-bank-account";
export const FundWalletBalanceURL = domain + "fund-wallet";
export const RequestPWDURL = domain + "request-reset-password-code";
export const ResetPWDURL = domain + "reset-password";
export const calculateInternationalShipment = domain + "calculate-shipment-cost-1"
export const GetShopShipUrl = domain + "get-shop-and-ship-countries";
export const GetRates = domain + "get-rates";
export const calculateShipment = domain + "calculate-shipment";
export const GetCities = domain + "get-dhl-cities";
export const GetCountries = domain + "get-countries";
export const CreateShipment = domain + "create-international-shipment-new";
export const GetProcessingUnitsUrl = domain + "get-pickup-locations";
export const GetAllCities = domain + "get-cities";
export const GetAllStatesUrl = domain + "get-states";
export const trackShipmentUrl = domain + "track-shipment-by-number";
export const getDHLCitiesUrl = domain + "get-dhl-cities";
export const createIntentUrl = domain + "create-stripe-intent";
export const uploadItemImgUrl = "https://user.parcelflow.io/engine/v1/actions/upload-document";