import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {persistReducer} from "redux-persist"
import { combineReducers } from "@reduxjs/toolkit";
import LocalShipmentReducer from "./LocalSlice"
import InterStateReducer from "./InterStateSlice"
import InternationalReducer from "./InternationalSlice"
import AuthReducer from "./AuthSlice"
import thunk from "redux-thunk";


const persistConfig={
    key:"root",
    version:1,
    storage
}

const reducer = combineReducers({
    Auth:AuthReducer,
    Local:LocalShipmentReducer,
    Interstate:InterStateReducer,
    International:InternationalReducer
});

const persistedReducer=persistReducer(persistConfig,reducer);

export const store = configureStore({
    reducer:persistedReducer,
    middleware: [thunk],
})