import { Link } from "react-router-dom";
import "../Assets/style/Button.scss"


const EllaBtn = ({name,to, onClick}) => {
    return (
        <div className="Home_Btn">
            <Link onClick={onClick} to={to}>{name}</Link>
        </div>
    );
}
 
export default EllaBtn;