export {default as Desktop} from "./DesktopDrawer"
export {default as Mobile} from "./MobileDrawer"
export {default as Header} from "../../GeneralHeader/GeneralHeader"


const dash=<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.95345 4.29447V0.961141C8.95345 0.72503 9.03345 0.526974 9.19345 0.366974C9.35289 0.20753 9.55067 0.127808 9.78678 0.127808H14.7868C15.0229 0.127808 15.2207 0.20753 15.3801 0.366974C15.5401 0.526974 15.6201 0.72503 15.6201 0.961141V4.29447C15.6201 4.53059 15.5401 4.72836 15.3801 4.88781C15.2207 5.04781 15.0229 5.12781 14.7868 5.12781H9.78678C9.55067 5.12781 9.35289 5.04781 9.19345 4.88781C9.03345 4.72836 8.95345 4.53059 8.95345 4.29447ZM0.620117 7.62781V0.961141C0.620117 0.72503 0.700117 0.526974 0.860117 0.366974C1.01956 0.20753 1.21734 0.127808 1.45345 0.127808H6.45345C6.68956 0.127808 6.88762 0.20753 7.04762 0.366974C7.20706 0.526974 7.28678 0.72503 7.28678 0.961141V7.62781C7.28678 7.86392 7.20706 8.0617 7.04762 8.22114C6.88762 8.38114 6.68956 8.46114 6.45345 8.46114H1.45345C1.21734 8.46114 1.01956 8.38114 0.860117 8.22114C0.700117 8.0617 0.620117 7.86392 0.620117 7.62781ZM8.95345 14.2945V7.62781C8.95345 7.3917 9.03345 7.19364 9.19345 7.03364C9.35289 6.8742 9.55067 6.79447 9.78678 6.79447H14.7868C15.0229 6.79447 15.2207 6.8742 15.3801 7.03364C15.5401 7.19364 15.6201 7.3917 15.6201 7.62781V14.2945C15.6201 14.5306 15.5401 14.7284 15.3801 14.8878C15.2207 15.0478 15.0229 15.1278 14.7868 15.1278H9.78678C9.55067 15.1278 9.35289 15.0478 9.19345 14.8878C9.03345 14.7284 8.95345 14.5306 8.95345 14.2945ZM0.620117 14.2945V10.9611C0.620117 10.725 0.700117 10.527 0.860117 10.367C1.01956 10.2075 1.21734 10.1278 1.45345 10.1278H6.45345C6.68956 10.1278 6.88762 10.2075 7.04762 10.367C7.20706 10.527 7.28678 10.725 7.28678 10.9611V14.2945C7.28678 14.5306 7.20706 14.7284 7.04762 14.8878C6.88762 15.0478 6.68956 15.1278 6.45345 15.1278H1.45345C1.21734 15.1278 1.01956 15.0478 0.860117 14.8878C0.700117 14.7284 0.620117 14.5306 0.620117 14.2945Z" fill=""/>
</svg>;

const ship=<svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.902832 0.723022V2.38969H2.5695V11.5564C2.5695 11.9984 2.74509 12.4223 3.05765 12.7349C3.37021 13.0474 3.79414 13.223 4.23617 13.223H14.2362C14.6782 13.223 15.1021 13.0474 15.4147 12.7349C15.7272 12.4223 15.9028 11.9984 15.9028 11.5564V2.38969H17.5695V0.723022H0.902832ZM8.40283 10.723H4.23617V9.05636H8.40283V10.723Z" fill=""/>
</svg>;

const pay=<svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.18262 0.0385132C2.4367 0.0385132 1.72133 0.334829 1.19388 0.862275C0.666433 1.38972 0.370117 2.10509 0.370117 2.85101V3.78851H17.8701V2.85101C17.8701 2.10509 17.5738 1.38972 17.0464 0.862275C16.5189 0.334829 15.8035 0.0385132 15.0576 0.0385132H3.18262ZM0.370117 10.976V5.03851H17.8701V10.976C17.8701 11.7219 17.5738 12.4373 17.0464 12.9648C16.5189 13.4922 15.8035 13.7885 15.0576 13.7885H3.18262C2.4367 13.7885 1.72133 13.4922 1.19388 12.9648C0.666433 12.4373 0.370117 11.7219 0.370117 10.976ZM12.2451 8.78851C12.0794 8.78851 11.9204 8.85436 11.8032 8.97157C11.686 9.08878 11.6201 9.24775 11.6201 9.41351C11.6201 9.57927 11.686 9.73825 11.8032 9.85546C11.9204 9.97267 12.0794 10.0385 12.2451 10.0385H14.1201C14.2859 10.0385 14.4448 9.97267 14.5621 9.85546C14.6793 9.73825 14.7451 9.57927 14.7451 9.41351C14.7451 9.24775 14.6793 9.08878 14.5621 8.97157C14.4448 8.85436 14.2859 8.78851 14.1201 8.78851H12.2451Z" fill=""/>
</svg>;

// const track=<svg width="19" height="19" viewBox="0 0 19 19" fill="" xmlns="http://www.w3.org/2000/svg">
// <g opacity="1" clip-path="url(#clip0_117_2846)">
// <path d="M9.12012 4.50873C8.52338 4.50873 7.95108 4.74578 7.52913 5.16774C7.10717 5.58969 6.87012 6.16199 6.87012 6.75873V15.7587C6.87012 16.3555 7.10717 16.9278 7.52913 17.3497C7.95108 17.7717 8.52338 18.0087 9.12012 18.0087C9.71685 18.0087 10.2892 17.7717 10.7111 17.3497C11.1331 16.9278 11.3701 16.3555 11.3701 15.7587V6.75873C11.3701 6.16199 11.1331 5.58969 10.7111 5.16774C10.2892 4.74578 9.71685 4.50873 9.12012 4.50873Z" fill=""/>
// <path d="M15.8701 0.00872803C15.2734 0.00872803 14.7011 0.245781 14.2791 0.667738C13.8572 1.08969 13.6201 1.66199 13.6201 2.25873V15.7587C13.6201 16.3555 13.8572 16.9278 14.2791 17.3497C14.7011 17.7717 15.2734 18.0087 15.8701 18.0087C16.4669 18.0087 17.0392 17.7717 17.4611 17.3497C17.8831 16.9278 18.1201 16.3555 18.1201 15.7587V2.25873C18.1201 1.66199 17.8831 1.08969 17.4611 0.667738C17.0392 0.245781 16.4669 0.00872803 15.8701 0.00872803V0.00872803Z" fill=""/>
// <path d="M2.37012 9.00873C1.77338 9.00873 1.20108 9.24578 0.779127 9.66774C0.35717 10.0897 0.120117 10.662 0.120117 11.2587L0.120117 15.7587C0.120117 16.3555 0.35717 16.9278 0.779127 17.3497C1.20108 17.7717 1.77338 18.0087 2.37012 18.0087C2.96685 18.0087 3.53915 17.7717 3.96111 17.3497C4.38306 16.9278 4.62012 16.3555 4.62012 15.7587V11.2587C4.62012 10.662 4.38306 10.0897 3.96111 9.66774C3.53915 9.24578 2.96685 9.00873 2.37012 9.00873Z" fill=""/>
// </g>
// <defs>
// <clipPath id="clip0_117_2846">
// <rect width="18" height="18" fill="" transform="translate(0.120117 0.00872803)"/>
// </clipPath>
// </defs>
// </svg>;

const set=<svg width="17" height="19" viewBox="0 0 17 19" fill="" xmlns="http://www.w3.org/2000/svg">
<path d="M0.325599 13.8C0.945966 14.8767 2.32174 15.2466 3.39843 14.6262C3.39917 14.6258 3.39987 14.6254 3.40061 14.6249L3.73435 14.4322C4.36435 14.9712 5.0885 15.3892 5.87034 15.6652V16.05C5.87034 17.2926 6.8777 18.3 8.12034 18.3C9.36297 18.3 10.3703 17.2926 10.3703 16.05V15.6652C11.1523 15.3888 11.8765 14.9703 12.5063 14.4307L12.8416 14.6242C13.9185 15.2455 15.2953 14.8762 15.9166 13.7992C16.5379 12.7222 16.1686 11.3455 15.0916 10.7242L14.7586 10.5322C14.9085 9.71652 14.9085 8.88037 14.7586 8.06467L15.0916 7.87268C16.1685 7.25137 16.5379 5.87465 15.9166 4.79767C15.2953 3.72073 13.9186 3.35134 12.8416 3.97266L12.5078 4.16542C11.8772 3.62707 11.1526 3.20984 10.3703 2.93471V2.54996C10.3703 1.30732 9.36297 0.299957 8.12034 0.299957C6.8777 0.299957 5.87034 1.30732 5.87034 2.54996V2.93471C5.08839 3.21114 4.36421 3.62964 3.73435 4.16922L3.3991 3.97498C2.32212 3.35363 0.945404 3.72302 0.324087 4.79996C-0.297229 5.8769 0.0721222 7.25365 1.1491 7.87497L1.4821 8.06696C1.33219 8.88262 1.33219 9.71877 1.4821 10.5345L1.1491 10.7265C0.0751106 11.3494 -0.293046 12.7235 0.325599 13.8ZM8.12034 6.29997C9.77718 6.29997 11.1203 7.64311 11.1203 9.29996C11.1203 10.9568 9.77718 12.2999 8.12034 12.2999C6.46349 12.2999 5.12035 10.9568 5.12035 9.29996C5.12035 7.64311 6.46349 6.29997 8.12034 6.29997Z" fill=""/>
</svg>;

export const Data=[
    {
        icon:dash,
        title:'Dashboard',
        path:'/dashboard',
        hasChildren:false,
    },
    {
        icon:ship,
        title:'Shipments',
        path:'/shipments',
        hasChildren:true,
        Children:[
            {
                name:'View All Shipments',
                link:'/all-shipments'
            },
            {
                name:'Get Rates',
                link:'/get-rates'
            },
            // {
            //     name:'Create Local',
            //     link:'/local-shipment'
            // },
            // {
            //     name:'Create Interstate',
            //     link:'/interstate-shipment'
            // },
            {
                name:'Create Shipment',
                link:'/international-shipment'
            },
            {
                name:'Shipping Addresses',
                link:'/shipping-address'
            },
        ]
    },
    {
        icon:pay,
        title:'Payments',
        path:'/payments',
        hasChildren:true,
        Children:[
            {
                name:'Wallet',
                link:'/wallet'
            }
        ]
    },
    // {
    //     icon:track,
    //     title:'Track',
    //     path:'/track',
    //     hasChildren:false,
    // },
    {
        icon:set,
        title:'Settings',
        path:'/settings',
        hasChildren:false,
    },
]
