import { Routes, Route } from "react-router-dom";
import {
  HomePage,
  LogInPage,
  ProtectedRoute,
  ShipmentsPage,
  Dashboard,
  AllShipmentsPage,
  PaymentsPage,
  WalletPage,
  SettingPage,
  LocalShipmentPage,
  InterstateShipmentPage,
  InternationalShipmentPage,
  PasswordPage,
  RegisterPage,
  ResetPWDPage,
  ErrorPage,
  ShippingAddressPage,
  GetRatesPage,
  PrivacyPolicy,
} from "./Components";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const EllaRoute = () => {
  return (
    <Routes>
      <Route exact path="/" element={<LogInPage />} />
      <Route exact path="/login" element={<LogInPage />} />
      <Route exact path="/register" element={<RegisterPage />} />
      <Route exact path="/reset-password" element={<PasswordPage />} />
      <Route exact path="/change-password" element={<ResetPWDPage />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/" element={<ProtectedRoute />}>
        <Route exact path="local-shipment" element={<LocalShipmentPage />} />
        <Route
          exact
          path="interstate-shipment"
          element={<InterstateShipmentPage />}
        />
        <Route
          exact
          path="international-shipment"
          element={<InternationalShipmentPage />}
        />
        <Route exact path="shipments" element={<ShipmentsPage />} />
        <Route exact path="all-shipments" element={<AllShipmentsPage />} />
        <Route exact path="payments" element={<PaymentsPage />} />
        <Route exact path="wallet" element={<WalletPage />} />
        <Route exact path="settings" element={<SettingPage />} />
        <Route exact path="dashboard" element={<Dashboard />} />
        <Route exact path="shipping-address" element={<ShippingAddressPage />} />
        <Route exact path="get-rates" element={<GetRatesPage />} />
      </Route>
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

export default EllaRoute;
