import "../../../Assets/style/Card.scss"
import { NumericFormat } from 'react-number-format';

const DashboardTopCard = ({
    title,
    value,
    spanText,
    icon}) => {

    return (
       <>
        <div className="Card">
            <h6>{title}</h6>
            <h3><NumericFormat value={value} displayType={'text'} thousandSeparator={true} prefix={'₦'} /></h3>
            {/* <div className="Wrap">
                <h5><span>{spanText}</span></h5>
               {icon}
            </div> */}
        </div>
       </>
    );
}
 
export default DashboardTopCard;