import { Fragment, useEffect } from "react";
import {
    Top,
    Filter,
} from "./components"
import axios from "axios";
import { generateTransactionID } from "../../Library/Utilities";
import { getWalletBalanceURL,getShipmentsURL } from "../../Library/URLs";
import { AuthActions } from "../../store/AuthSlice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";




const EllaWallet = () => {

    const dispatch=useDispatch()
    const {User,ShipmentHistory} = useSelector((state)=>state.Auth)


    useEffect(()=>{
    const GetWallet=async()=>{
        const Data={
            "user_id":User.userID,
            "user_ses_id":User.sessionID,
            "company_id":User.companyID,
            "transaction_id":generateTransactionID(15),
        }
            await axios
            .post(getWalletBalanceURL,Data)
            .then(response=>{
                console.log("res",response.data)
                if(response.data.success===false){
                    toast.error("fail to fetch wallet")
                }
                if(response.data.success===true){
                    const res=response.data.data
                    dispatch(AuthActions.GetWalletDetail(res))
                }
            })
        }
    const GetShipHistory=async()=>{
        const Data={
            "user_id":User.userID,
            "user_ses_id":User.sessionID,
            "company_id":User.companyID,
            "transaction_id":generateTransactionID(15),
            "limit":"10",
            "offset":"0"
        }
            await axios
            .post(getShipmentsURL,Data)
            .then(response=>{
                console.log("shipment history res",response.data)
                if(response.data.success===false){
                    toast.error(response.data.message)
                }
                if(response.data.success===true){
                    const res=response.data.data
                    dispatch(AuthActions.GetShipmentHistory(res))
                }
            })
        }
    GetWallet()
    GetShipHistory();
    // eslint-disable-next-line
    },[])

    return (
        <Fragment>
             <div className="Breadcrum">
            <h5 >wallet</h5>
            </div>
            <Top/>
            <Filter data={ShipmentHistory}/>
        </Fragment>
    );
}
 
export default EllaWallet;