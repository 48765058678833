import "../../Assets/style/SideBar.scss"
import { useTheme } from '@mui/material/styles';
import { Box} from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from "react";
import { Desktop, Mobile,Header } from "./Components";
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {ClearRecord} from "../../store/AuthAction"

const EllaSideBar = () => {
    const dispatch=useDispatch()
    // const location = useLocation()
    // const Locate = location.pathname
    // const slide = Locate.slice(1)
    const [display,setDisplay]=useState('none')

    const {Authentication}=useSelector((state)=>state.Auth)

    const navigate=useNavigate()

    const [Toggle,setToggle]=useState(false)
    const handleToggle=()=>{
        setDisplay('none')
        setToggle(false)
    }
    const ShowNav=()=>{
        setDisplay('block')
        setToggle(true)
    }

    const Logout=()=>{
        dispatch(ClearRecord())
        navigate("/login",{replace: true})
    }

    const drawerWidth = 260;
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const container = window !== undefined ? () => window.document.body : undefined;


    useEffect(()=>{
        if(matchUpMd){
            setToggle(true)
        }
    },[matchUpMd])

    return (
        <Box sx={{ display: 'flex' }}>
        <Box sx={{  flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }}>
        <Mobile
        Toggle={Toggle}
        handle={handleToggle}
        theme={theme}
        matchUpMd={matchUpMd}
        drawerWidth={drawerWidth}
        container={container}
        display={display}
        />
        </Box>
        <Desktop
        drawerWidth={drawerWidth}
        Logout={Logout}
        />
        <div className='Main'>
        <Header handleLogout={Logout} NavClick={ShowNav}/>
        {Authentication===true ? <Outlet /> : navigate("/login")} 
        </div>
        </Box>
    );
}
 
export default EllaSideBar;