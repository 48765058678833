import React, { useEffect, useState } from "react";
import { AuthActions } from "../../../store/AuthSlice";
import axios from "axios";
import { generateTransactionID } from "../../../Library/Utilities";
import { GetShopShipUrl } from "../../../Library/URLs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { AddressModal } from "./Modals";

const Countries = () => {
  const dispatch = useDispatch();
  const [selectedCountry, setSelectedCountry] = useState(null);

  const { User, ShipAddresses } = useSelector((state) => state.Auth);

  useEffect(() => {
    GetShippingAddress();
    // eslint-disable-next-line
  }, []);

  const GetShippingAddress = async () => {
    const Data = {
      user_id: User.userID,
      user_ses_id: User.sessionID,
      company_id: User.companyID,
      transaction_id: generateTransactionID(15),
    };
    await axios.post(GetShopShipUrl, Data).then((response) => {
      console.log("res", response.data);
      if (response.data.success === false) {
        toast.error("fail to fetch wallet");
      }
      if (response.data.success === true) {
        const res = response.data.data;
        dispatch(AuthActions.GetShipAddresses(res));
      }
    });
  };
  return (
    <div>
      <AddressModal
        open={selectedCountry !== null}
        country={selectedCountry}
        handleClose={() => setSelectedCountry(null)}
      />
      <p className="countryTopLabel">Get your Shipments accross the globe</p>
      <div className="addressContain">
        {ShipAddresses &&
          ShipAddresses?.map((address, id) => (
            <div
              className="countryWrap"
              onClick={() => setSelectedCountry(address)}
              key={id}
            >
              <img src={address?.flag_url} alt={address?.recipient_name} />
              <p>{address?.country} </p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Countries;
