import { Fragment, useState } from "react";
import "../../Assets/style/LocalShipment.scss"
import "../../Assets/style/GeneralShipmetStyle.scss"
import "../../Assets/style/SenderForm.scss"
import {
    Location,
    NavBar,
    Payment,
    Confirm,
    Item,
} from "./components";
import { useSelector } from "react-redux";
import { NumericFormat } from 'react-number-format';



const LocalShipment = () => {

    const {PriceDetails} = useSelector((state)=>state.Local)

    const [Tab,setTab]=useState(0)


    return (
        <Fragment>
            <div className="Breadcrum">
        <h5 >Local Shipment</h5>
        {
            PriceDetails==="" ? null : <div className="Cost_Wrap">
            <h3>Cost: </h3> <h3 className="Price"><NumericFormat value={PriceDetails.amount} displayType={'text'} thousandSeparator={true} prefix={'₦'} /></h3>
        </div>
          }
        </div>
        <div className="Local_Shipment">
            <NavBar
            Tab={Tab}
            sender=''
            receiver=''
            item=''
            payment=''
            confirm=''
            />
            {Tab===0 && <Location  setTab={setTab}/>}
            {Tab===1 && <Item setTab={setTab}/>}
            {Tab===2 && <Payment setTab={setTab}/>}
            {Tab===3 && <Confirm setTab={setTab}/>}
        </div>
        </Fragment>
    );
}
 
export default LocalShipment;
