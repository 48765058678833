import { Fragment, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../Assets/style/Dashboard.scss"
import {
    Card,
    RecentShip,
    Bank} from "./components"
import axios from "axios";
import { generateTransactionID } from "../../Library/Utilities";
import {getShipmentsURL, getWalletBalanceURL,getBankDetailURL } from "../../Library/URLs";
import { AuthActions } from "../../store/AuthSlice";
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import { toast } from "react-toastify";




const Dashboard = () => {
 
  const dispatch = useDispatch()

  const {
    User,
    WalletDetails,
    BankDetails} = useSelector((state)=>state.Auth)

   useEffect(()=>{
    const GetWallet=async()=>{
        const Data={
            "user_id":User.userID,
            "user_ses_id":User.sessionID,
            "company_id":User.companyID,
            "transaction_id":generateTransactionID(15),
        }
            await axios
            .post(getWalletBalanceURL,Data)
            .then(response=>{
                console.log("res",response.data)
                if(response.data.success===false){
                    toast.error("fail to fetch wallet")
                }
                if(response.data.success===true){
                    const res=response.data.data
                    dispatch(AuthActions.GetWalletDetail(res))
                }
            })
        }

    const GetBank=async()=>{
        const Data={
            "user_id":User.userID,
            "user_ses_id":User.sessionID,
            "company_id":User.companyID,
            "transaction_id":generateTransactionID(15),
        }
            await axios
            .post(getBankDetailURL,Data)
            .then(response=>{
                console.log("Bank res",response.data)
                if(response.data.success===false){
                    toast.error("fail to fetch wallet")
                }
                if(response.data.success===true){
                    const res=response.data.data
                    dispatch(AuthActions.GetBankDetail(res))
                }
            })
        }
    const GetShipHistory=async()=>{
        const Data={
            "user_id":User.userID,
            "user_ses_id":User.sessionID,
            "company_id":User.companyID,
            "transaction_id":generateTransactionID(15),
            "limit":"10",
            "offset":"0"
        }
            await axios
            .post(getShipmentsURL,Data)
            .then(response=>{
                console.log("shipment history res",response.data)
                if(response.data.success===false){
                    toast.error(response.data.message)
                }
                if(response.data.success===true){
                    const res=response.data.data
                    dispatch(AuthActions.GetShipmentHistory(res))
                }
            })
        }
    GetShipHistory()

    GetWallet()
    GetBank()
   },[])

    return (
        <Fragment>
             <div className="Breadcrum">
        <h5 >Dashboard</h5>
        </div>
        <div className="Dashboard_Layout">
            <div className="Left">
                <div className="Top_Card">
                <Card
                title="Balance"
                value={parseFloat(WalletDetails.account_balance).toLocaleString("en-US",{maximumFractionDigits:2})}
                subText="12%"
                spanText={WalletDetails.account_balance < 100 ? "Decrease From Target" : 'Increase From Target'}
                icon={WalletDetails.account_balance < 100 ? <SouthIcon/> : <NorthIcon/>}
                />
                <Card
                title="Total Fund"
                value={parseFloat(WalletDetails.total_funded)?.toLocaleString("en-US", {maximumFractionDigits:2})}
                subText="12%"
                spanText={WalletDetails.total_funded < 100 ? "Decrease From Target" : 'Increase From Target'}
                icon={WalletDetails.total_funded < 100 ? <SouthIcon/> : <NorthIcon/>}
                />
                <Card
                title="Total Spent"
                value={parseFloat(WalletDetails.total_spent)?.toLocaleString("en-US",{maximumFractionDigits:2})}
                subText="12%"
                spanText={WalletDetails.total_spent < 100 ? "Decrease From Target" : 'Increase From Target'}
                icon={WalletDetails.total_spent < 100 ? <SouthIcon/> : <NorthIcon/>}
                />
                </div>
                <RecentShip/>
            </div>
            <div className="Right">
                <Bank
                name={BankDetails.account_name}
                acct_name={BankDetails.bank}
                acct_number={BankDetails.account_number}
                />
            </div>
        </div>
        </Fragment>
    );
}
 
export default Dashboard;