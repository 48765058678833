import "../../../Assets/style/Wallet.scss";
import Card from "../../Dashboard/components/Card";
import { Fragment } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import { useDispatch ,useSelector } from "react-redux";
import { usePaystackPayment } from "react-paystack";
import { toast } from "react-toastify";
import {
  // FundWalletBalanceURL,
  getWalletBalanceURL,
  getShipmentsURL,
} from "../../../Library/URLs";
import axios from "axios";
import { AuthActions } from "../../../store/AuthSlice";
import { generateTransactionID } from "../../../Library/Utilities";

// import AOS from "aos";
// import "aos/dist/aos.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  outline: "none",
  outerWidth: "0px",
  borderRadius: "5px",
  p: 4,
};

const WalletTop = () => {
  const { User, WalletDetails, BankDetails } = useSelector(
    (state) => state.Auth
  );
  const transaction_id = generateTransactionID(15);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const GetWallet = async () => {
    const Data = {
      user_id: User.userID,
      user_ses_id: User.sessionID,
      company_id: User.companyID,
      transaction_id: generateTransactionID(15),
    };
    await axios.post(getWalletBalanceURL, Data).then((response) => {
      console.log("res", response.data);
      if (response.data.success === false) {
        toast.error("fail to fetch wallet");
      }
      if (response.data.success === true) {
        const res = response.data.data;
        dispatch(AuthActions.GetWalletDetail(res));
      }
    });
  };
  const GetShipHistory = async () => {
    const Data = {
      user_id: User.userID,
      user_ses_id: User.sessionID,
      company_id: User.companyID,
      transaction_id: generateTransactionID(15),
      limit: "10",
      offset: "0",
    };
    await axios.post(getShipmentsURL, Data).then((response) => {
      console.log("shipment history res", response.data);
      if (response.data.success === false) {
        toast.error(response.data.message);
      }
      if (response.data.success === true) {
        const res = response.data.data;
        dispatch(AuthActions.GetShipmentHistory(res));
      }
    });
  };

  // const calculateShipment = async (reference) => {
  //   setIsLoading(true);
  //   const raw = {
  //     user_id: User.userID,
  //     user_ses_id: User.sessionID,
  //     company_id: User.companyID,
  //     transaction_id: transaction_id,
  //     amount_paid: Amount,
  //     transaction_reference_no: reference?.reference,
  //     payment_method: "Card Payment",
  //   };
  //   await axios.post(FundWalletBalanceURL, raw).then((res) => {
  //     console.log("inter res", res.data);
  //     setIsLoading(false);
  //     if (res.data?.success) {
        
  //     } else if (res.data?.success === false) {
  //       toast.error(res.data?.message);
  //     }
  //   });
  // };

  const [Tab, setTab] = useState(1);
  const [Amount, setAmount] = useState();
  const Price = Amount + 0 + 0;

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const config = {
    reference: new Date().getTime().toString(),
    email: User.email,
    amount: Price, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: process.env.REACT_APP_PAYSTACK_KEY,
    metadata: {
      custom_fields: [
        {
            display_name: 'WALLET TOP-UP',
            variable_name: 'WALLET TOP-UP',
            value: 'WALLET TOP-UP'
        }
      ]
    }
  };

  const onSuccess = (reference) => {
    handleClose();
    toast.success("Payment successful!");
    console.log("paystack success", reference);
    setAmount();
    GetWallet();
    GetShipHistory();
  };

  const onClose = () => {
    handleClose();
    toast.error("Payment fail");
    console.log("paystack closed");
  };

  const PaystackHookExample = () => {
    const initializePayment = usePaystackPayment(config);
    return (
      <div
        className="Btn"
        onClick={() => {
          initializePayment(onSuccess, onClose);
        }}
      >
        PAY
      </div>
    );
  };

  return (
    <Fragment>
      <div className="Wallet_Top">
        <div className="Left">
          <Card
            title="Balance"
            value={WalletDetails.account_balance}
            subText="12%"
            spanText={
              WalletDetails.account_balance < 100
                ? "Decrease From Target"
                : "Increase From Target"
            }
            icon={
              WalletDetails.account_balance < 100 ? (
                <SouthIcon />
              ) : (
                <NorthIcon />
              )
            }
          />
          <Card
            title="Total Fund"
            value={WalletDetails.total_funded}
            subText="12%"
            spanText={
              WalletDetails.total_funded < 100
                ? "Decrease From Target"
                : "Increase From Target"
            }
            icon={
              WalletDetails.total_funded < 100 ? <SouthIcon /> : <NorthIcon />
            }
          />
          <Card
            title="Total Spent"
            value={WalletDetails.total_spent}
            subText="12%"
            spanText={
              WalletDetails.total_spent < 100
                ? "Decrease From Target"
                : "Increase From Target"
            }
            icon={
              WalletDetails.total_spent < 100 ? <SouthIcon /> : <NorthIcon />
            }
          />
        </div>
        <div className="Right">
          <div className="Wallet_Btn Gray">WITHDRAW</div>
          <div onClick={handleOpen} className="Wallet_Btn Danger">
            FUND WALLET
          </div>
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="Wallet_Modal_Wrapper">
              <span onClick={handleClose} className="close_btn">
                <CloseIcon />
              </span>
              <div className="Tab_Wrapper">
                <div
                  onClick={() => setTab(1)}
                  className={`Tab ${Tab === 2 ? "active_tab" : ""}`}
                >
                  DIRECT TRANSFER
                </div>
                <div
                  onClick={() => setTab(2)}
                  className={`Tab ${Tab === 1 ? "active_tab" : ""}`}
                >
                  CARD PAYMENT
                </div>
              </div>
              {Tab === 1 && (
                <div className="Details_Wrapper">
                  <div className="Top">
                    <div className="Box">
                      <p>Account Name</p>
                      <h5>{BankDetails.account_name}</h5>
                    </div>
                  </div>
                  <div className="Bottom">
                    <div className="Box">
                      <p>Account Number</p>
                      <h5>{BankDetails.account_number}</h5>
                    </div>
                    <div className="Box">
                      <p>Bank Name</p>
                      <h5>{BankDetails.bank}</h5>
                    </div>
                  </div>
                </div>
              )}
              {Tab === 2 && (
                <div className="Details_Wrapper Amount">
                  <label htmlFor="amount">Amount</label>
                  <input
                    type="tel"
                    value={Amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="0.00"
                  />

                  <PaystackHookExample />
                </div>
              )}
            </div>
          </Box>
        </Fade>
      </Modal>
    </Fragment>
  );
};

export default WalletTop;
