import axios from 'axios'
import { InterStateActions } from './InterStateSlice'
import { toast } from 'react-toastify';
import {
  calculateInterStateCostURL,
  bookInterstateShipment,
} from '../Library/URLs'
import { generateTransactionID } from '../Library/Utilities'
const transaction_id = generateTransactionID(15)

export const CostData = (User,Sender,SenderCity,Receiver,ReceiverCity) => {
  return async (dispatch) => {
    dispatch(InterStateActions.Loading(true))
    const raw = {
      user_id: User.userID,
      user_type: 'Regular',
      user_ses_id:User.sessionID,
      company_id:User.companyID,
      transaction_id: transaction_id,
      weight: Sender.weight,
      processor: 'DHL',
      sender_address: Sender.street,
      sender_country: 'Nigeria',
      sender_city: SenderCity,
      sender_state: Sender.state,
      sender_zip_code: '101001',
      sender_phone: Sender.phone_number,
      receiver_address: Receiver.receiver_address,
      receiver_country: 'Nigeria',
      receiver_city: ReceiverCity,
      receiver_state: Receiver.state,
      receiver_zip_code: '202002',
      receiver_phone_number: Receiver.phone_number,
      type: 'Interstate',
      request_pickup: 'No',
    }
    console.log("interstate data",raw)
    console.log("interstate data",Receiver)

    if(raw["receiver_address"]!==''){
      await axios.post(calculateInterStateCostURL, raw)
    .then(res=>{
      if(res.data.success === false){
        toast.error(res.data.message)
      }
      if(res.data.success === true){
        dispatch(InterStateActions.GetPriceStatus(true))
        dispatch(InterStateActions.GetReceiverStatus(true))
        dispatch(InterStateActions.GetPriceDetails(res.data.data))
      }
      dispatch(InterStateActions.Loading(false))
    })
    .catch (error=>{
        console.log('calculate cost error' + error.message)
        toast.error('calculate cost error' + error.message)
        dispatch(InterStateActions.Loading(false))
    })
    }
    if(raw["receiver_address"]===''){
      return dispatch(InterStateActions.Loading(false))
    }
  }
}

export const BookInterStateShipment = (Data) => {
  return async (dispatch) => {
    dispatch(InterStateActions.Loading(true))
    console.log('book', Data)
    const BookShipment = async () => {
      let data

      const res = await axios.post(bookInterstateShipment, Data) // returns promise
      data = res.data
      return data
    }
    try {
      const BookInterState = await BookShipment()
      console.log('book', JSON.stringify(BookInterState))
      if (BookInterState.success === false) {
        dispatch(InterStateActions.ShipmentStatus(false))
        toast.error(BookInterState.message)
      }
      if (BookInterState.success === true) {
        dispatch(InterStateActions.ShipmentStatus(true))
        toast.success('Shipment as been successfully book')
      }
      dispatch(InterStateActions.Loading(false))
    } catch (error) {
      console.log('Shipment Book error' + error.message)
      toast.error('Shipment Book error' + error.message)
      dispatch(InterStateActions.Loading(false))
    }
  }
}

export const ClearRecord = () => {
  return async (dispatch) => {
    dispatch(InterStateActions.Loading(false))
    dispatch(InterStateActions.ShipmentStatus(false))
    dispatch(InterStateActions.GetReceiverStatus(false))
    dispatch(InterStateActions.GetSenderStatus(false))
    dispatch(InterStateActions.GetPackageStatus(false))
    dispatch(InterStateActions.GetPaymentStatus(false))
    dispatch(InterStateActions.GetPriceStatus(false))
    dispatch(InterStateActions.GetOverall(''))
    dispatch(InterStateActions.GetPriceDetails(''))
    dispatch(InterStateActions.GetPayment(''))
    dispatch(InterStateActions.GetSender(''))
    dispatch(InterStateActions.GetSenderCity(''))
    dispatch(InterStateActions.GetReceiver(''))
    dispatch(InterStateActions.GetReceiverCity(''))
    dispatch(InterStateActions.ResetPackage([]))
    dispatch(InterStateActions.GetRequestPack(''))
    dispatch(InterStateActions.ResetTotalQuantity(0))
    dispatch(InterStateActions.ResetTotalValue(0))
    dispatch(InterStateActions.ResetTotalWeight(0))
  }
}
