import { useDispatch, useSelector } from "react-redux";
import { NumericFormat } from 'react-number-format';
import '../../../Assets/style/Price.scss'
import NestedBtn from '../../../components/NestedBtn'
import { InterStateActions } from "../../../store/InterStateSlice";
import { useState } from "react";

const PricePage = ({ReceiverStatus,setTab}) => {

    const dispatch=useDispatch()

    const {PriceDetails}=useSelector((state)=>state.Interstate)
    
    const [Selected,setPick]=useState()

    const Previous=()=>{
        setTab(1)
    }

    const Next=()=>{
        dispatch(InterStateActions.GetPriceStatus(true))
        setTab(3)
      }



    return (
        <div className="Price_Page">
          {/* <h3>Prices</h3> */}
          
          <div onClick={()=>setPick(1)} className={`Price_Card ${Selected===1 ? 'active' : ''}`}>
            <div className="Wrap">
            <div className="Logo"></div>
            <div className="Right">
            <h2><NumericFormat value={PriceDetails.amount} displayType={'text'} thousandSeparator={true} prefix={'₦'} /></h2>
            </div>
            </div>
            <div className="Wrap">
            <div className="Left Name">
               DHL
            </div>
            <div className="Right">
                Standard
            </div>
            </div>
            {/* <div className="Wrap">
            <div className="Left">
               PickUp
            </div>
            <div className="Right">
             
            </div>
            </div>
            <div className="Wrap">
            <div className="Left">
               Delivery
            </div>
            <div className="Right">
             
            </div>
            </div> */}
          </div>

          <div onClick={()=>setPick(2)} className={`Price_Card ${Selected===2 ? 'active' : ''}`}>
          <div className="Wrap">
            <div className="Logo"></div>
            <div className="Right">
            <h5>Coming Soon</h5>
            </div>
            </div>
            <div className="Wrap">
            <div className="Left Name">
                UPS
            </div>
            <div className="Right">
                Standard
            </div>
            </div>
            {/* <div className="Wrap">
            <div className="Left">
               PickUp
            </div>
            <div className="Right">
             Coming Soon
            </div>
            </div>
            <div className="Wrap">
            <div className="Left">
               Delivery
            </div>
            <div className="Right">
             Coming Soon
            </div>
            </div> */}
          </div>

          <div onClick={()=>setPick(3)} className={`Price_Card ${Selected===3 ? 'active' : ''}`}>
          <div className="Wrap">
            <div className="Logo"></div>
            <div className="Right">
            <h5>Coming Soon</h5>
            </div>
            </div>
            <div className="Wrap">
            <div className="Left Name">
               FedEx
            </div>
            <div className="Right">
                International Priority
            </div>
            </div>
            {/* <div className="Wrap">
            <div className="Left">
               PickUp
            </div>
            <div className="Right">
            Coming Soon
            </div>
            </div>
            <div className="Wrap">
            <div className="Left">
               Delivery
            </div>
            <div className="Right">
            Coming Soon
            </div>
            </div> */}
          </div>

          <div className="Nested_Bottom_Wrap">
                <NestedBtn 
                handle={Previous}
                status={ReceiverStatus}
                name="previous" />
                 <NestedBtn 
                handle={Next}
                status={ReceiverStatus}
                name="CONTINUE" />
            </div>
        </div>
    );
}
 
export default PricePage;