import React, { useState } from "react";
import { International, Interstate } from "./components";
import "../../Assets/style/GetRates.scss";

const GetRates = () => {
  const [rateType, setRateType] = useState("Interstate");
  return (
    <>
      <div className="Breadcrum">
        <h5>Get Shipping Rates</h5>
      </div>
      <div className="PageWrapper" >
        <div className="toggleRateType">
          <button
            className={rateType === "Interstate" && "rateActive"}
            onClick={() => setRateType("Interstate")}
          >
            Nationwide
          </button>
          <button
            className={rateType === "Export" && "rateActive"}
            onClick={() => setRateType("Export")}
          >
            Export
          </button>
          <button
            className={rateType === "Import" && "rateActive"}
            onClick={() => setRateType("Import")}
          >
            Import
          </button>
        </div>
        <div>
          {
            (rateType==="Import" || rateType==="Export") ? (
              <International rateType={rateType}/>
            ) : (
              <Interstate />
            )
          }
        </div>
      </div>
    </>
  );
};

export default GetRates;
