import { useForm } from 'react-hook-form'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'


const ItemDetails = ({setTab}) => {


    const {
        register,
        reset,
        handleSubmit,
        formState: { errors },
      } = useForm()

      const handle=(data)=>{
        setTab(1)
      }


      useEffect(() => {
        AOS.init({
          duration: 2000,
        })
      }, [])



    return (
        <div className="Interstate_Form">
            <h3>Where will we be picking up from?</h3>
            <form >
              <div className="Top_Wrap">
              <div className="Form_Control">
              <label htmlFor="name">SENDER NAME</label>
              <input
              type="text"
              id='name'
              placeholder='Sender name'
              className={`${errors.sender_name ? 'error' : 'noError'}`}
                    {...register('sender_name', { required: true })}
                />
                {errors.sender_name && (
                    <span>
                    Sender name is required
                    </span>
                )} 
              </div> 
              <div className="Form_Control">
              <label htmlFor="last">RECEIVER NAME</label>
              <input
              type="text"
              id='last'
              placeholder='Receiver name'
              className={`${errors.receiver_name ? 'error' : 'noError'}`}
                    {...register('receiver_name', { required: true })}
                />
                {errors.receiver_name && (
                    <span>
                    Receiver name is required
                    </span>
                )} 
              </div>    
             </div>

             <div className="Form_Control">
              <label htmlFor="street">ITEM</label>
              <input
              type="text"
              id='street'
              placeholder='Name of item'
              className={`${errors.item ? 'error' : 'noError'}`}
                    {...register('item', { required: true })}
                />
                {errors.item && (
                    <span>
                    Item field is required
                    </span>
                )} 
              </div>
            
              <div className="Form_Control">
              <label htmlFor="house">ITEM DESCRIPTION</label>
              <input
              type="text"
              id='house'
              placeholder='Item description'
              className={`${errors.description ? 'error' : 'noError'}`}
                    {...register('description', { required: true })}
                />
                {errors.description && (
                    <span>
                    Item description field is required
                    </span>
                )} 
              </div>

              <div className="City_Wrap">
              <div className="Form_Control">
              <label htmlFor="city">NUMBER OF ITEM</label>
              <input
              type="text"
              id='city'
              placeholder='Number of item'
              className={`${errors.number_of_item ? 'error' : 'noError'}`}
                    {...register('number_of_item', { required: true })}
                />
                {errors.number_of_item && (
                    <span>
                    Number of item is required
                    </span>
                )} 
              </div> 
              <div className="Form_Control">
              <label htmlFor="value">VALUE</label>
              <input
              type="text"
              id='value'
              placeholder='0.0'
              className={`${errors.value ? 'error' : 'noError'}`}
                    {...register('value', { required: true })}
                />
                {errors.value && (
                    <span>
                    Value field is required
                    </span>
                )} 
              </div>    
             </div>

             <div className="Form_Control">
              <label htmlFor="phone">PHONE NUMBER</label>
              <input
              type="tel"
              id='phone'
              placeholder='PHONE NUMBER'
              className={`${errors.phone_number ? 'error' : 'noError'}`}
                    {...register('phone_number', { required: true })}
                />
                {errors.phone_number && (
                    <span>
                    Phone number field is required
                    </span>
                )} 
              </div>

              <div className="Save_Address">
                <input type="radio" name="" id="save" />
                <label htmlFor="save">SAVE TO ADDRESS BOOK</label>
              </div>

             <div onClick={handleSubmit(handle)} className="FormBtn">
             CONTINUE
             </div>
            </form>
            <div className="Terms">
            By completing this transaction, you represent that you have read, understand and accept the Ella Logistics terms of <a href="https://ellalogistics.africa/privacy-policy" target="_blank" rel="noreferrer">service</a> and <a href="https://ellalogistics.africa/privacy-policy" target="_blank" rel="noreferrer">privacy policy</a>.
            </div>
        </div>
    );
}
 
export default ItemDetails;