import "../../Assets/style/InternationalShipment.scss"
import "../../Assets/style/SenderForm.scss"
import "../../Assets/style/InterstateShipment.scss"
import "../../Assets/style/GeneralShipmetStyle.scss"
import {
    NavTab,
    Sender,
    Receiver,
    Price,
    Package,
    Payment,
    Confirm } from "./components";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ClearRecord } from "../../store/InternationalAction";
// import { NumericFormat } from 'react-number-format';

const InternationalShipment = () => {

    const dispatch=useDispatch()

    const Locate = useLocation()
    const path=Locate.pathname


    useEffect(()=>{
        if(path==='/international-shipment'){
         dispatch(ClearRecord())
        }
     },[])



    const {
        SenderStatus,
        ReceiverStatus,
        CostStatus,
        PackageStatus,
        PaymentStatus} = useSelector((state)=>state.International)

    const [Tab,setTab]=useState(0);

    useEffect(() => {
        let targetedElement = document.getElementById("topSection");
        targetedElement.scrollIntoView({behavior:"smooth"})
        // eslint-disable-next-line
    }, [Tab]);



    return (
        <Fragment>
            <div className="Breadcrum">
        <h5 id="topSection" >Create Shipment</h5>
        {/* {
            PriceDetails==="" ? null : <div className="Cost_Wrap">
                <h3>Cost: </h3> <h3 className="Price"><NumericFormat value={PriceDetails.amount} displayType={'text'} thousandSeparator={true} prefix={'₦'} /></h3>
            </div>
          } */}
        </div>
        <div className="Interstate_Shipment">
        <NavTab
         Tab={Tab}
         sender={SenderStatus}
         receiver={ReceiverStatus}
         price={CostStatus}
         package_detail={PackageStatus}
         payment={PaymentStatus}
         confirm=''
         setTab={setTab}
        />
        {Tab===0 && <Sender setTab={setTab} />}
        {Tab===1 && <Receiver
        SenderStatus={SenderStatus} 
        setTab={setTab} />}
        {Tab===2 && <Package
        ReceiverStatus={ReceiverStatus} 
        setTab={setTab} />}
        {Tab===3 && <Price
        PackageStatus={PackageStatus} 
        setTab={setTab}/>}
        {Tab===4 && <Payment 
        CostStatus={CostStatus}
        setTab={setTab} />}
        {Tab===5 && <Confirm
        PaymentStatus={PaymentStatus} 
        setTab={setTab}
        />}
        </div>
        </Fragment>
    );
}
 
export default InternationalShipment;