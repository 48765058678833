import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
export {default as Card} from "./Card"
export {default as Analysis} from "./AnalysisReport"
export {default as RecentShip} from "./RecentShipment"
export {default as Track} from "./TrackerCards"
export {default as Bank} from "./BankSection"


const arrowUp=<NorthIcon/>
const arrowDown=<SouthIcon/>
export const TopCardDetails=[
    {
     balance:'Balance',
     title:'N 120,000',
     subText:'12%',
     spanText:'Increase From Target',
     icon:arrowUp,
    },
    {
    balance:'Available Balance',
    title:'N 16,500',
    subText:'2%',
    spanText:'Decrease From Target',
    icon:arrowDown,
    },
    {
    balance:'Total Spending',
    title:'N 48,670',
    subText:'6%',
    spanText:'Increase From Target',
    icon:arrowUp,
    },
]

export const Graphdata = [
    {
      name: '12',
      uv: 0,
      date:'18 March 2023',
      amount:'N 78,560'
    },
    {
      name: '13',
      uv: 30,
      date:'1 March 2023',
      amount:'N 18,560'
    },
    {
      name: '14',
      uv: 60,
      date:'8 March 2023',
      amount:'N 8,560'
    },
    {
      name: '15',
      uv: 40,
      date:'2 March 2023',
      amount:'N 18,560'
    },
    {
      name: '16',
      uv: 20,
      date:'5 March 2023',
      amount:'N 17,560'
    },
    {
      name: '17',
      uv: 40,
      date:'6 March 2023',
      amount:'N 6,560'
    },
    {
      name: '18',
      uv: 70,
      date:'3 March 2023',
      amount:'N 38,560'
    },
    {
        name: '19',
        uv: 80,
        date:'11 March 2023',
        amount:'N 11,560'
      },
      {
        name: '20',
        uv: 20,
        date:'12 March 2023',
        amount:'N 12,560'
      },
      {
        name: '21',
        uv: 40,
        date:'21 March 2023',
        amount:'N 21,560'
      },
      {
        name: '22',
        uv: 120,
        date:'22 March 2023',
        amount:'N 23,560'
      },
  ];


export const ProgressDetails=[
    {
        title:'EL-127568N',
        text:'Sade Akingbade',
        firstPercent:"60%",
        secondPercent:"40%",
        valueOne:60,
        valueTwo:40,
        colorOne:"#037BCB",
        colorTwo:"#18A0FB",  
    },
    {
        title:'EL-127888A',
        text:'Sade Akingbade',
        firstPercent:"70%",
        secondPercent:"30%",
        valueOne:70,
        valueTwo:30,
        colorOne:"#CB0303",
        colorTwo:"#FB1818",  
    },
    {
        title:'EL-12457V',
        text:'Sade Akingbade',
        firstPercent:"43%",
        secondPercent:"57%",
        valueOne:43,
        valueTwo:57,
        colorOne:"#CB0303",
        colorTwo:"#FB1818",  
    },
    {
        title:'EL-11128Y',
        text:'Sade Akingbade',
        firstPercent:"10%",
        secondPercent:"90%",
        valueOne:10,
        valueTwo:90,
        colorOne:"#037BCB",
        colorTwo:"#18A0FB",  
    },
    {
        title:'EL-322568L',
        text:'Sade Akingbade',
        firstPercent:"60%",
        secondPercent:"40%",
        valueOne:60,
        valueTwo:40,
        colorOne:"#037BCB",
        colorTwo:"#18A0FB",  
    },
]