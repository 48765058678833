import { useForm } from 'react-hook-form'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'


const LocationDetails = ({setTab}) => {


    const {
        register,
        reset,
        handleSubmit,
        formState: { errors },
      } = useForm()

      const handle=(data)=>{
        setTab(1)
      }


      useEffect(() => {
        AOS.init({
          duration: 2000,
        })
      }, [])


    return (
        <div className="Interstate_Form">
            <h3>Where will we be picking up from?</h3>
            <form>
            <div className="Location_Dropdown">
            <div className="Form_Control">
              <label htmlFor="street">PiCK UP LOCATION</label>
              <input
              type="text"
              id='street'
              placeholder='Enter pick up location'
              className={`${errors.pick_up_location ? 'error' : 'noError'}`}
                    {...register('pick_up_location', { required: true })}
                />
                {errors.pick_up_location && (
                    <span>
                    Pick up location is required
                    </span>
                )} 
            </div>
            <div className="Location_Down"></div>
            </div>
            <div className="Location_Dropdown">
            <div className="Form_Control">
              <label htmlFor="drop">DROP OFF LOCATION</label>
              <input
              type="text"
              id='drop'
              placeholder='Enter dropoff location'
              className={`${errors.drop_off_location ? 'error' : 'noError'}`}
                    {...register('drop_off_location', { required: true })}
                />
                {errors.drop_off_location && (
                    <span>
                    Drop off loaction field is required
                    </span>
                )} 
            </div>
            <div className="Location_Down"></div>
            </div>
            <div onClick={handleSubmit(handle)} className="FormBtn">
             CONTINUE
             </div>
            </form>
            <div className="Terms">
            By completing this transaction, you represent that you have read, understand and accept the Ella Logistics terms of <a href="https://ellalogistics.africa/privacy-policy" target="_blank" rel="noreferrer">service</a> and <a href="https://ellalogistics.africa/privacy-policy" target="_blank" rel="noreferrer">privacy policy</a>.
            </div>
        </div>
    );
}
 
export default LocationDetails;