import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { ResetPWDURL } from '../../Library/URLs';
import axios from 'axios';
import { toast } from 'react-toastify';
import {generateTransactionID} from "../../Library/Utilities";


const ResetForm = () => {

    const navigate=useNavigate()

    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm();

      const [isLoading,setLoading]=useState(false)

      function getDevice() {
        let operatingSystem = 'Not known';
        if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Windows OS'; }
        if (window.navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'MacOS'; }
        if (window.navigator.appVersion.indexOf('X11') !== -1) { operatingSystem = 'UNIX OS'; }
        if (window.navigator.appVersion.indexOf('Linux') !== -1) { operatingSystem = 'Linux OS'; }

        let currentBrowser = 'Not known';
        if (window.navigator.userAgent.indexOf('Chrome') !== -1) { currentBrowser = 'Chrome'; }
        else if (window.navigator.userAgent.indexOf('Firefox') !== -1) { currentBrowser = 'Mozilla Firefox'; }
        else if (window.navigator.userAgent.indexOf('MSIE') !== -1) { currentBrowser = 'Internet Exployer'; }
        else if (window.navigator.userAgent.indexOf('Edge') !== -1) { currentBrowser = 'Edge'; }
        else if (window.navigator.userAgent.indexOf('Safari') !== -1) { currentBrowser = 'Safari'; }
        else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'Opera'; }
        else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'YaBrowser'; }

        return currentBrowser + " on " + operatingSystem;
    }
     
      const ResetPWD = (data) => {
          setLoading(true)
          const raw={
             "new_password":data.Password,
             "reset_code":data.reset_code,
             "email":data.email,
              "device_id":"value",
              "device_name":getDevice(),
              "transaction_id":generateTransactionID(15),
              "token":"token",
              "company_id":65
          }
          console.log(raw);
  
          axios
              .post(ResetPWDURL, raw)
              .then(function (response) {
                  console.log("res",response);
                  setLoading(true)
  
                  if (response["data"]["success"] === false) {
                      setLoading(false)
                      toast.error(response["data"]["message"])
                  } else if (response["data"]["success"] === true) {
                      toast.success(response["data"]["message"])
                      setLoading(false)
                      navigate('/login', {replace: true});
                  }
              })
              .catch(function (error) {
                  return toast.error("Reset password error"+error.message);
              });
      };

    
    const Submit=(data)=>{
        console.log(data)
        ResetPWD(data)
    }


    return (
        <div className="LogIn_Form">
        <div className="Wrapper">
        <Link to="/"><div className="Logo"></div></Link>
         <h4>Hey, Hello</h4>
         <p>To continue, please provide a valid detail.</p>
         <form onSubmit={handleSubmit(Submit)}>
             
             <div className="Box">
             <input 
             type="password" 
             placeholder="New password"
             className={`${errors.Password ? 'error':''}`}
             {...register('Password', { required: true })}
             />
             {errors.Password && <span>Password is required.</span>}
             </div>

             <div className="Box Reset_Email">
             <input 
             type="email" 
             placeholder="Enter email"
             className={`${errors.email ? 'error':''}`}
             {...register('email', { required: true })}
             />
             {errors.email && <span>Email is required.</span>}
             </div>

             <div className="Box">
             <input 
             type="tel" 
             placeholder="Enter sent code"
             className={`${errors.reset_code ? 'error':''}`}
             {...register('reset_code', { required: true })}
             />
             {errors.reset_code && <span>Reset code is required.</span>}
             </div>
             
             <button> {
                 isLoading===true ? <span className="spinner-border spinner-grow-sm"/> : "RESET"
                 } </button>
         </form>
        </div>
     </div>
    );
}
 
export default ResetForm;