import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { GetRates, GetCities, getDHLCitiesUrl } from "../../../Library/URLs";
import axios from "axios";
import { generateTransactionID } from "../../../Library/Utilities";
import RatesModal from "./RatesModal";
import { toast } from "react-toastify";
import states from "../../../Data/states2.json";
import { data } from "../../Home/Components";

const Interstate = () => {
  const { User } = useSelector((state) => state.Auth);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [carriers, setCarriers] = useState([]);
  const [cities, setCities] = useState([]);
  const [SelectedCity, setSelectedCity] = useState("");
  const [receiverSelectedCity, setReceiverSelectedCity] =
    useState("");

  const getCities = async () => {
    const res = states?.map((city) => ({
      ...city,
      code: city?.isoCode,
    }));
    setCities(res);
  };

  useEffect(() => {
    getCities();
    // eslint-disable-next-line
  }, []);

  const [senderCity, setSenderCity] = useState("");
  const [senderCityError, setSenderCityError] = useState();
  // const {Sender,SenderCity,SenderState} = useSelector((state)=>state.International)
  const [receiverCity, setReceiverCity] = useState("");
  const [receiverCityError, setReceiverCityError] = useState();
  const [dhlCities, setDhlCities] = useState([]);

  const [showSenderCitiesSuggestions, setShowSenderCitiesSuggestions] =
    useState(false);
  const [citiesSuggestions, setCitiesSuggestions] = useState([]);

  const [
    showReceiverCitiesSuggestions,
    setShowReceiverCitiesSuggestions,
  ] = useState(false);

  const [weight, setWeight] = useState("");
  const [weightError, setWeightError] = useState();

  useEffect(() => {
    fetchDhlCities();
    // eslint-disable-next-line
  }, [])

  const fetchDhlCities = async () => {
    const data = {
      user_id: User.userID,
      user_ses_id: User.sessionID,
      company_id: User.companyID,
      transaction_id: generateTransactionID(15),
    };
    await axios.post(getDHLCitiesUrl, data).then((response) => {
      console.log("shipment history res", response.data);
      setIsLoading(false);
      if (response.data.success === false) {
        toast.error(response.data.message);
      }
      if (response.data.success === true) {
        setDhlCities(response?.data?.data)
      }
    });
  }

  const getRates = async () => {
    const data = {
      user_id: User.userID,
      user_ses_id: User.sessionID,
      company_id: User.companyID,
      transaction_id: generateTransactionID(15),
      shipment_type: "Interstate",
      from: SelectedCity,
      to: receiverSelectedCity,
      weight: weight,
    };
    setIsLoading(true);

    await axios.post(GetRates, data).then((response) => {
      console.log("shipment history res", response.data);
      setIsLoading(false);
      if (response.data.success === false) {
        toast.error(response.data.message);
      }
      if (response.data.success === true) {
        const res = response.data.data;
        setCarriers(res?.carriers);
        setOpenModal(true);
      }
    });
  };

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (senderCity === "") {
      setSenderCityError(true);
    }
    if (receiverCity === "") {
      setReceiverCityError(true);
    }
    if (weight === "") {
      setWeightError(true);
    }
    if (senderCity !== "" && receiverCity !== "" && weight !== "") {
      getRates();
    }
  };

  const selectCity = (location) => {
    setSenderCity(location);
    setSelectedCity(location);
    setSenderCityError(false);
    setShowSenderCitiesSuggestions(false);
  };

  const selectReceiverCity = (location) => {
    setReceiverCity(location);
    setReceiverSelectedCity(location);
    setReceiverCityError(false);
    setShowReceiverCitiesSuggestions(false);
  };

  const searchCity = (query, name) => {
    // dispatch(InternationalActions.GetCost(false))
    if (name === "from") {
      setSenderCity(query);

      if (query === "") {
        setShowSenderCitiesSuggestions(false);
        return;
      }
    } else {
      setReceiverCity(query);

      if (query === "") {
        setShowReceiverCitiesSuggestions(false);
        return;
      }
    }

    const filteredCountries = [];
    console.log("Dfaf", dhlCities);
    dhlCities.forEach((city) => {
      if (city["city"].toLowerCase().includes(query.toLowerCase())) {
        filteredCountries.push(city["city"]);
      }
    });
    console.log("fil", filteredCountries);

    setCitiesSuggestions(filteredCountries);
    if (filteredCountries.length > 0) {
      if (name === "from") {
        setShowSenderCitiesSuggestions(true);
      } else {
        setShowReceiverCitiesSuggestions(true);
      }
    } else {
      if (name === "from") {
        setShowSenderCitiesSuggestions(false);
      } else {
        setShowReceiverCitiesSuggestions(false);
      }
    }
  };
  return (
    <div className="Rate_form">
      <RatesModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        carriers={carriers}
      />
      <h3>Check Shipping rates across Nigeria</h3>
      <form>
        <div className="Form_Control">
          <label htmlFor="country">FROM</label>
          <input
            type="text"
            id="country"
            placeholder="City"
            autoComplete="false"
            autoCorrect="false"
            value={senderCity}
            className={`Country ${
              senderCityError === true ? "error" : "noError"
            }`}
            onChange={(e) => searchCity(e.target.value, "from")}
          />
          {senderCityError && (
            <span className={`${senderCityError ? "errorText" : ""}`}>
              Sender city is required
            </span>
          )}
          <div
            style={{ width: "86%" }}
            className={`suggestions-field ${
              showSenderCitiesSuggestions
                ? "suggestions-field-visible"
                : null
            }`}
          >
            {citiesSuggestions.map((suggestions) => {
              return (
                <div
                  onClick={(e) => selectCity(suggestions, "Pickup")}
                  key={suggestions}
                  className="single-suggestion"
                >
                  <span style={{ fontSize: 14, color: "#74788d" }}>
                    {suggestions}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="Form_Control">
          <label htmlFor="country">TO</label>
          <input
            type="text"
            id="country"
            placeholder="City"
            autoComplete="false"
            autoCorrect="false"
            value={receiverCity}
            className={`Country ${
              receiverCityError === true ? "error" : "noError"
            }`}
            onChange={(e) => searchCity(e.target.value, "receive")}
          />
          {receiverCityError && (
            <span className={`${receiverCityError ? "errorText" : ""}`}>
              Receiver city is required
            </span>
          )}
          <div
            style={{ width: "86%" }}
            className={`suggestions-field ${
              showReceiverCitiesSuggestions
                ? "suggestions-field-visible"
                : null
            }`}
          >
            {citiesSuggestions.map((suggestions) => {
              return (
                <div
                  onClick={(e) => selectReceiverCity(suggestions, "Pickup")}
                  key={suggestions}
                  className="single-suggestion"
                >
                  <span style={{ fontSize: 14, color: "#74788d" }}>
                    {suggestions}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="Form_Control">
          <label htmlFor="country">WEIGHT (KG)</label>
          <input
            type="tel"
            id="weight"
            placeholder="Weight"
            autoComplete="false"
            autoCorrect="false"
            value={weight}
            className={`Country ${weightError === true ? "error" : "noError"}`}
            onChange={(e) => setWeight(e.target.value)}
          />
          {weightError && (
            <span className={`${weightError ? "errorText" : ""}`}>
              Weight is required
            </span>
          )}
        </div>
        <div onClick={handleSubmit(onSubmit)} className="FormBtn">
          {isLoading ? (
            <span className="spinner-border spinner-grow-sm" />
          ) : (
            "CONTINUE"
          )}
        </div>
      </form>
    </div>
  );
};

export default Interstate;
