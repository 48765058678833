import { NavLink } from "react-router-dom";
import Button from "../../../components/Button"
import MenuIcon from '@mui/icons-material/Menu';

const LandingPageHeader = ({setOpen,links}) => {

    const handle=()=>{
        setOpen(true)
    }

    return (
        <div className="Header">
           <div className="Inner-Container">
           <div className="Logo"></div>
            <div className="Right">
            <ul>
                {
                    links.map((item,index)=>
                    <li key={index}>
                    <NavLink 
                    className={({ isActive }) => (isActive ? "active-link" : "link")}
                    to={item.path}
                    >{item.name}</NavLink>
                    </li>
                    )
                }
            </ul>
            <Button to="/login" name="Sign in" />
            </div>
            {/* <MenuIcon onClick={handle} /> */}
           </div>
        </div>
    );
}
 
export default LandingPageHeader;