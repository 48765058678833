import { createSlice } from '@reduxjs/toolkit';

// ==============================|| REDUX - MAIN STORE ||============================== //

const InterStateSlice=createSlice({
    name:'InterStateSlice',
    initialState:{
       Overall:'',
       isLoading:false,
       isBook:false,
       PriceDetails:'',
       PaymentOption:'',
       Sender:'',
       SenderCity:'',
       Receiver:'',
       ReceiverCity:'',
       Package:[
        {
            package_items: [],
            package_weight: "0",
            package_length: "",
            package_width: "",
            package_height: "",
            package_value: "0",
            number_of_items: "0"
        }
    ],
       SenderStatus:false,
       ReceiverStatus:false,
       PriceStatus:false,
       PaymentStatus:false,
       PackageStatus:false,
       RequestPack:{},
       TotalQuantity:0,
       TotalValue:0,
       TotalWeight:0,
       PickUp_Location:'',
       EllaLocation:'',
    },
    reducers:{
        FetchLocation:(state,action)=>{
            state.EllaLocation=action.payload
        },
        GetPickUp:(state,action)=>{
            state.PickUp_Location=action
        },
        GetSenderStatus:(state,action)=>{
            state.SenderStatus=action.payload
        },
        GetSenderCity:(state,action)=>{
            state.SenderCity=action.payload
        },
        GetReceiverCity:(state,action)=>{
            state.ReceiverCity=action.payload
        },
        GetReceiverStatus:(state,action)=>{
            state.ReceiverStatus=action.payload
        },
        GetPriceStatus:(state,action)=>{
            state.PriceStatus=action.payload
        },
        GetPackageStatus:(state,action)=>{
            state.PackageStatus=action.payload
        },
        GetPaymentStatus:(state,action)=>{
            state.PaymentStatus=action.payload
        },
        GetPriceDetails:(state,action)=>{
        state.PriceDetails=action.payload
        },
        GetOverall:(state,action)=>{
        state.Overall=action.payload
        },
        Loading:(state,{payload})=>{
        state.isLoading=payload
        },
        ShipmentStatus:(state,{payload})=>{
        state.isBook=payload
        },
        GetPayment:(state,action)=>{
        state.PaymentOption=action.payload
        },
        GetQtyVal:(state)=>{
         let q = state.Package.map(item=>{return item.quantity})
         let sum_q=0
         for (let a of q){
            sum_q+=+a
         }
         state.TotalQuantity =sum_q

         let v = state.Package.map(item=>{return item.value})
         let sum_v=0
         for (let a of v){
            sum_v+=+a
         }
         state.TotalValue=sum_v

         let w = state.Package.map(item=>{return item.weight})
         let sum_w=0
         for (let a of w){
            sum_w+=+a
         }
         state.TotalWeight=sum_w
        },
        GetSender(state,action){
            state.Sender=action.payload
        },
        GetReceiver(state,action){
            state.Receiver=action.payload
        },
        GetPackage(state,{payload}){
            const Index = state.Package.findIndex((item)=>item.name===payload.name)
            if(Index >=0){
                state.Package[Index].name=payload.name
                state.Package[Index].value=payload.value
                state.Package[Index].description=payload.description
                state.Package[Index].weight=payload.weight
                state.Package[Index].quantity=payload.quantity
                state.Package[Index].currency=payload.currency
            }
            else{
                state.Package.push(payload)
            }
        },
        ResetPackage(state,action){
            state.Package=action.payload
        },
        ResetTotalQuantity(state,action){
            state.TotalQuantity=action.payload
        },
        ResetTotalValue(state,action){
            state.TotalValue=action.payload
        },
        ResetTotalWeight(state,action){
            state.TotalWeight=action.payload
        },
        GetRequestPack(state){
            const data=state.Package
            let object = data.reduce((acc,current,i)=>{
                acc[i] = current;
                return acc;
            }, {});
            state.RequestPack=object
        },
        EditPackage(state,action){
            state.Package=state.Package
            .map((item)=>{
                if(item.id===action.payload.id){
                    item.name=action.payload.name
                    item.value=action.payload.value
                    item.description=action.payload.description
                    item.weight=action.payload.weight
                    item.quantity=action.payload.quantity
                    item.currency=action.payload.currency
                }
                return item
            })
        },
        UpdatePackage(state, action) {
            state.Package = action.payload
        }
    }
});

export const InterStateActions = InterStateSlice.actions

export default InterStateSlice.reducer

