import "../../Assets/style/GeneralHeader.scss"
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from "react-router-dom";





const GeneralHeader = ({NavClick,handleLogout}) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };


    return (
        <div className="General_Header">
          <div className="Input_Wrapper">
            {/* <div className="Search_Box">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.89549 17.3708C13.7785 17.3708 16.9262 14.2231 16.9262 10.3401C16.9262 6.4571 13.7785 3.30933 9.89549 3.30933C6.01252 3.30933 2.86475 6.4571 2.86475 10.3401C2.86475 14.2231 6.01252 17.3708 9.89549 17.3708Z" stroke="#6D7D93" stroke-width="1.75769" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.6847 19.1293L14.8608 15.3054" stroke="#6D7D93" stroke-width="1.75769" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </div> */}
            {/* <input 
            type="search" 
            placeholder="Search for transaction, item, etc" /> */}
          </div>
            <NotificationsNoneOutlinedIcon/>
           {/* <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.039 10.6664C21.0428 12.1238 20.7023 13.5614 20.0452 14.8623C19.2661 16.4211 18.0684 17.7323 16.5863 18.6489C15.1041 19.5655 13.396 20.0513 11.6534 20.052C10.196 20.0558 8.75831 19.7153 7.45745 19.0582L1.16357 21.1562L3.26153 14.8623C2.60446 13.5614 2.26396 12.1238 2.26776 10.6664C2.26844 8.92371 2.75428 7.21564 3.67088 5.73349C4.58747 4.25133 5.89861 3.05364 7.45745 2.27456C8.75831 1.61749 10.196 1.27699 11.6534 1.28079H12.2055C14.507 1.40777 16.6808 2.3792 18.3107 4.00909C19.9406 5.63898 20.912 7.81279 21.039 10.1143V10.6664Z" stroke="#6D7D93" stroke-width="1.75769" stroke-linecap="round" stroke-linejoin="round"/>
            </svg> */}
            <div onClick={handleClick} className="Header_User">
              <div className="Avatar"></div>
              <KeyboardArrowDownIcon />
            </div>
            <MenuIcon onClick={NavClick} className="MenuBar"/>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
            elevation: 0,
            sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
                },
                '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
                },
            },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >

            <MenuItem component={Link} to="/settings" className="dropdown_link" >
            <ListItemIcon>
                <Settings fontSize="small" />
            </ListItemIcon>
            Settings
            </MenuItem>
            <MenuItem onClick={handleLogout}>
            <ListItemIcon>
                <Logout fontSize="small" />
            </ListItemIcon>
            Logout
            </MenuItem>
        </Menu>
        </div>
    );
}
 
export default GeneralHeader;