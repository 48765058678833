import { Link } from "react-router-dom";

const CardTab = ({item,active,handleClick}) => {
    const {title,Svg,text,link} = item
    return (
       <Link to={link}>
        <div onClick={handleClick} className={`Custom_Card ${active}`}>
            <div className="Inner_Card">
                <div className="Img_Box">
                   {Svg}
                </div>
                <div className="Detail_Box">
                    <h3>{title}</h3>
                    <p>{text}</p>
                </div>
            </div>
        </div>
       </Link>
    );
}
 
export default CardTab;