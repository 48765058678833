import { Fragment, useState } from "react";
import { Empty, Table } from ".";

const WalletFilter = ({ data }) => {
    const [CurrentPage, setCurrentPage] = useState(1);
    const [walletType, setWalletType] = useState("");
    const [PostPerPage, setPostPerPage] = useState(5);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const LastPost = CurrentPage * PostPerPage;
    const FirstPost = LastPost - PostPerPage;
    const Output = data
        ?.filter((item) => item?.type?.includes(walletType))
        ?.slice(FirstPost, LastPost);

    // Function to filter items based on start and end dates
    function filterItemsByDate(start, end, walletHistory) {
        // Check if start or end date is null or an empty string
        if (!start || start.trim() === "" || !end || end.trim() === "") {
            return walletHistory; // Return all items if either date is null or empty
        }

        // Parse input dates as JavaScript Date objects
        const startDate = new Date(start);
        const endDate = new Date(end);

        // Filter items based on the date range
        const filteredItems = walletHistory.filter((item) => {
            const itemDate = new Date(item.date_time);

            // Check if item's date range overlaps with the input date range
            return itemDate <= endDate && itemDate >= startDate;
        });

        return filteredItems;
    };

    let history = filterItemsByDate(startDate, endDate, Output)

    return (
        <Fragment>
            <div
                className={`Wallet_Header ${data.length === 0 ? "activate_fade" : ""}`}
            >
                <h3>Wallet History</h3>
                <div className="Right">
                    <select
                        name=""
                        id=""
                        value={walletType}
                        onChange={(e) => setWalletType(e.target.value)}
                    >
                        <option selected value="">
                            All Transactions
                        </option>
                        <option value="credit">Credit</option>
                        <option value="debit">Debit</option>
                    </select>
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        name=""
                        id=""
                    />
                    {/* <select name="" id="">
                <option selected>Choose date</option>
            </select> */}
                    {/* <div className="Search_Box">
                        <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M5.70566 1.95955C3.71744 1.95955 2.10566 3.57132 2.10566 5.55955C2.10566 7.54777 3.71744 9.15955 5.70566 9.15955C7.69389 9.15955 9.30566 7.54777 9.30566 5.55955C9.30566 3.57132 7.69389 1.95955 5.70566 1.95955ZM0.305664 5.55955C0.305664 2.57721 2.72333 0.159546 5.70566 0.159546C8.688 0.159546 11.1057 2.57721 11.1057 5.55955C11.1057 6.72579 10.736 7.80569 10.1073 8.68843L14.4421 13.0231C14.7935 13.3746 14.7935 13.9445 14.4421 14.2959C14.0906 14.6474 13.5207 14.6474 13.1693 14.2959L8.83455 9.96122C7.95181 10.5898 6.87191 10.9595 5.70566 10.9595C2.72333 10.9595 0.305664 8.54188 0.305664 5.55955Z"
                                fill="white"
                            />
                        </svg>
                    </div> */}
                </div>
            </div>
            {data.length > 0 && (
                <Table
                    Detail={history}
                    CurrentPage={CurrentPage}
                    setCurrentPage={setCurrentPage}
                />
            )}
            {data.length === 0 && <Empty />}
            {data.length === "" && (
                <div className="Spinner_Div">
                    <span className="spinner-border spinner-grow-sm" />
                </div>
            )}
        </Fragment>
    );
};

export default WalletFilter;
