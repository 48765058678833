import { Fragment } from "react";
import NavigationButton from "../../../components/NavigateBtn";
import Moment from "react-moment"
import { NumericFormat } from 'react-number-format';

const WalletTable = ({
    Detail,
    CurrentPage,
    setCurrentPage,}) => {


    const render=(res)=>{
        switch (res) {
        case "Pending Pickup":
        return "Pending"
        break;
        case "Cancelled":
        return "Cancel"
        break;
        case "Rejected":
        return "Rejected"
        break;
        case "Delivered":
        return "Delivered"
        break;
        case "Pending Approval":
        return "Pending Approval"
        break;
    
        default:
        return "Success"
        break;
        }
        }

    const renderColor=(res)=>{
    switch (res) {
    case "Pending Pickup":
    return "pending"
    break;
    case "Cancelled":
    return "cancel"
    break;
    case "Rejected":
    return "cancel"
    break;
    case "Delivered":
    return "success"
    break;
    case "Pending Approval":
    return "pending"
    break;

    default:
    return "success"
    break;
    }
    }


    return (
        <Fragment>
            <div className="Wallet_Transaction">
        <table>
             <thead>
                <th>Description</th>
                 <th>Amount</th>
                 <th>Type</th>
                 <th>Date</th>
             </thead>
             <tbody>
             {
         Detail.map(item=>(
             <tr>
             <td><div className="Box">
             <h5>{item.description}</h5>
                </div></td>
             <td><div className="Box">
             <h5><NumericFormat value={item.amount} displayType={'text'} thousandSeparator={true} prefix={'₦'} /></h5>
                </div></td>
             <td><div className="Box">
             <h5>{item.type}</h5>
                </div></td>
             <td>
                <div className="Box">
                <h5><Moment format="MMM DD, YY">{item.date_time}</Moment></h5>
                    <p><Moment format="h:mm a">{item.date_time}</Moment></p>
                </div>
             </td>
         </tr>
         ))
     }
             </tbody>
         </table>
        </div>
        <NavigationButton
        CurrentPage={CurrentPage}
        setCurrentPage={setCurrentPage}
        output={Detail}
        />
        </Fragment>
    );
}
 
export default WalletTable;