const AllShipmentsTopSection = ({
    filterType,
    setFilterType,
    startDate,
    setStartDate,
    endDate,
    setEndDate
}) => {
    
    return (
        <div className="AllShipment_Top">
                <h3>All Shipments</h3>
                <div className="Select_Box">
                        <select value={filterType} onChange={(e)=>setFilterType(e.target.value)} name="" id="">
                            <option selected value="">Filter By</option>
                            <option value="Pending Approval">Pending</option>
                            <option value="Approved">Approved</option>
                            <option value="In Transit">In Transit</option>
                            <option value="Delivered">Delivered</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Rejected">Rejected</option>
                        </select>
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            name=""
                            id=""
                        />
                    </div>
            </div>
    );
}
 
export default AllShipmentsTopSection;