import axios from "axios";
import { toast } from "react-toastify";
import { InternationalActions } from "./InternationalSlice";
import {
  calculateInterStateCostURL,
  ShipInternationalShipment,
} from "../Library/URLs";
import { generateTransactionID } from "../Library/Utilities";

const transaction_id = generateTransactionID(15);

export const CostData = (
  User,
  Sender,
  SenderCountry,
  SenderState,
  Receiver,
  ReceiverCountry,
  ReceiverState
) => {
  return async (dispatch) => {
    dispatch(InternationalActions.Loading(true));
    const CalCost = async () => {
      let data;
      const raw = {
        user_id: User.userID,
        user_type: "Regular",
        user_ses_id: User.sessionID,
        company_id: User.companyID,
        transaction_id: transaction_id,
        weight: Sender.weight,
        processor: "DHL",
        sender_address: Sender.street,
        sender_country: SenderCountry,
        sender_city: Sender.city,
        sender_state: SenderState,
        sender_zip_code: Sender.post_code,
        sender_phone: Sender.phone_number,
        receiver_address: Receiver.street,
        receiver_country: ReceiverCountry,
        receiver_city: Receiver.city,
        receiver_state: ReceiverState,
        receiver_zip_code: Receiver.post_code,
        receiver_phone_number: Receiver.phone_number,
        type:
          ReceiverCountry === "Nigeria" && SenderCountry !== "Nigeria"
            ? "Import"
            : ReceiverCountry === "Nigeria" && SenderCountry === "Nigeria"
            ? SenderState === "Lagos" && ReceiverState === "Lagos"
              ? "Local"
              : "Interstate"
            : "International",
        request_pickup: "No",
      };
      console.log("international post data", raw);
      const res = await axios.post(calculateInterStateCostURL, raw); // returns promise
      data = res.data;
      return data;
    };
    try {
      const interstateCost = await CalCost();
      if (interstateCost.success === false) {
        toast.error(interstateCost.message);
      }
      if (interstateCost.success === true) {
        console.log("international calculate cost res", interstateCost);
        dispatch(InternationalActions.GetCost(true));
        dispatch(InternationalActions.GetReceiverStatus(true));
        dispatch(InternationalActions.GetPriceDetails(interstateCost.data));
      }
      dispatch(InternationalActions.Loading(false));
    } catch (error) {
      console.log("calculate cost error" + error.message);
      toast.error("calculate cost error" + error.message);
      dispatch(InternationalActions.Loading(false));
    }
  };
};

export const BookInternationalShipment = (Data) => {
  return async (dispatch) => {
    dispatch(InternationalActions.Loading(true));
    console.log("book", Data);
    const BookShipment = async () => {
      let data;

      const res = await axios.post(ShipInternationalShipment, Data); // returns promise
      data = res.data;
      return data;
    };
    try {
      const BookInterState = await BookShipment();
      console.log("book", JSON.stringify(BookInterState));
      if (BookInterState.success === false) {
        dispatch(InternationalActions.ShipmentStatus(false));
        toast.error(BookInterState.message);
      }
      if (BookInterState.success === true) {
        toast.success("Shipment as been successfully book");
        dispatch(InternationalActions.ShipmentStatus(true));
      }
      dispatch(InternationalActions.Loading(false));
    } catch (error) {
      console.log("Shipment Book error" + error.message);
      toast.error("Shipment Book error" + error.message);
      dispatch(InternationalActions.Loading(false));
    }
  };
};

export const ClearRecord = () => {
  return async (dispatch) => {
    dispatch(InternationalActions.Loading(false));
    dispatch(InternationalActions.ShipmentStatus(false));
    dispatch(InternationalActions.GetReceiverStatus(false));
    dispatch(InternationalActions.GetSenderStatus(false));
    dispatch(InternationalActions.GetPackageStatus(false));
    dispatch(InternationalActions.GetPaymentStatus(false));
    dispatch(InternationalActions.SetPriceStatus(false));
    dispatch(InternationalActions.GetOverall(""));
    dispatch(InternationalActions.GetPriceDetails(""));
    dispatch(InternationalActions.GetPayment(""));
    dispatch(InternationalActions.GetSender(""));
    dispatch(InternationalActions.GetSenderState(""));
    dispatch(InternationalActions.GetSenderRegion(""));
    dispatch(InternationalActions.GetReceiverRegion(""));
    dispatch(InternationalActions.GetReceiver(""));
    dispatch(InternationalActions.GetReceiverState(""));
    dispatch(
      InternationalActions.ResetPackage([
        {
          package_items: [],
          package_weight: "0",
          package_length: "",
          package_width: "",
          package_height: "",
          package_value: "0",
          number_of_items: "0",
        },
      ])
    );
    dispatch(InternationalActions.GetRequestPack(""));
    dispatch(InternationalActions.ResetTotalQuantity(0));
    dispatch(InternationalActions.ResetTotalValue(0));
    dispatch(InternationalActions.ResetTotalWeight(0));
  };
};
