import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InterStateActions } from '../../../store/InterStateSlice'
import { NumericFormat } from 'react-number-format';
import NestedBtn from '../../../components/NestedBtn'


const PaymentMethod = ({setTab,PackageStatus}) => {
  const { WalletDetails } = useSelector((state)=>state.Auth)
  const Paymethod = useSelector((state) => state.Interstate.PaymentOption)
  const dispatch = useDispatch()


  useEffect(() => {
    AOS.init({
      duration: 2000,
    })
  }, [])


  const SubmitForm = () => {
    if (Paymethod === '') {
      return
    } else {
      dispatch(InterStateActions.GetPaymentStatus(true))
      setTab(5)
    }
  }


  const Previous=()=>{
    setTab(3)
    console.log('am click')
  }


    return (
        <div data-aos="fade-zoom-in" className="Payment_Method">
        <h3>Choose Payment Method</h3>
        <div className="Box">
         <h6>AVAILABLE PAYMENT MENTHOD</h6>
        </div>
        <div className="Box">
          <div className="Detail">
            <h4>Debit card</h4>
            <h5>Pay using Mastercard, Visa or Verve card</h5>
          </div>
          <input
            value="Card Payment"
            onChange={(e) =>
              dispatch(InterStateActions.GetPayment(e.target.value))
            }
            name="radio"
            type="radio"
            checked={Paymethod === 'Card Payment' ? true : false}
          />
        </div>
        <div className="Box">
          <div className="Detail">
            <h4>Virtual bank transfer</h4>
            <h5>Pay by sending funds to a virtual bank account</h5>
          </div>
          <input
            value="Direct Transfer"
            onChange={(e) =>
              dispatch(InterStateActions.GetPayment(e.target.value))
            }
            name="radio"
            type="radio"
            checked={Paymethod === 'Direct Transfer' ? true : false}
          />
        </div>
        <div className="Box">
          <div className="Detail">
            <h4>Wallet</h4>
            <h5>Pay by sending funds to a virtual bank account</h5>
          </div>
          <input
            value="Wallet"
            onChange={(e) =>
              dispatch(InterStateActions.GetPayment(e.target.value))
            }
            name="radio"
            type="radio"
            checked={Paymethod === 'Wallet' ? true : false}
          />
        </div>
        <div className="Balance">
          <div className="Wrap">
          <h2><NumericFormat value={WalletDetails.account_balance} displayType={'text'} thousandSeparator={true} prefix={''} /></h2><h5>NGN</h5>
          </div>
          <h6 className={`${WalletDetails.account_balance < 100 ? 'Danger_Balance':''}`}>{WalletDetails.account_balance < 100 ? 'Insufficient balance':'Available balance'}</h6>
          </div>
        
        <button onClick={SubmitForm}>CONTINUE</button>
        <small>
          By continuing, I represent that the declaration above is a proper and
          accurate description of the contents of my package.
        </small>
        <div className="Nested_Bottom_Wrap">
                <NestedBtn 
                handle={Previous}
                status={PackageStatus}
                name="previous" />
            </div>
      </div>
    );
}
 
export default PaymentMethod;