import { createSlice } from "@reduxjs/toolkit";

const Auth = createSlice({
  name: "Auth",
  initialState: {
    Authentication: false,
    User: "",
    WalletDetails: "",
    BankDetails: "",
    ShipmentHistory: [],
    isBook: false,
    PriceDetails: "",
    PaymentOption: "",
    Locations: "",
    SenderAndReceiverDetails: "",
    ResetStatus: false,
    ResetCode: "",
    ShipAddresses: null,
  },
  reducers: {
    GetShipmentHistory: (state, action) => {
      state.ShipmentHistory = action.payload;
    },
    GetAuth: (state, action) => {
      state.Authentication = action.payload;
    },
    GetUser: (state, action) => {
      state.User = action.payload;
    },
    GetResetStatus: (state, action) => {
      state.ResetStatus = action.payload;
    },
    GetPriceDetails: (state, action) => {
      state.PriceDetails = action.payload;
    },
    GetFullDetails: (state, action) => {
      state.SenderAndReceiverDetails = action.payload;
    },
    GetWalletDetail: (state, { payload }) => {
      state.WalletDetails = payload;
    },
    GetBankDetail: (state, { payload }) => {
      state.BankDetails = payload;
    },
    ShipmentStatus: (state, { payload }) => {
      state.isBook = payload;
    },
    GetPayment: (state, action) => {
      state.PaymentOption = action.payload;
    },
    GetLocation(state, action) {
      state.Locations = action.payload;
    },
    EditPackage(state, action) {
      state.Package = state.Package.map((item) => {
        if (item.id === action.payload.id) {
          item.name = action.payload.name;
          item.value = action.payload.value;
          item.description = action.payload.description;
          item.weight = action.payload.weight;
          item.quantity = action.payload.quantity;
          item.currency = action.payload.currency;
        }
        return item;
      });
    },
    GetShipAddresses(state, action) {
        state.ShipAddresses = action.payload;
    }
  },
});

export const AuthActions = Auth.actions;

export default Auth.reducer;
