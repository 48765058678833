import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect, useState } from 'react'
// import { NumericFormat } from 'react-number-format';


const PaymentMethod = ({setTab}) => {

  const [Balance,setBalance]=useState('')

  useEffect(() => {
    AOS.init({
      duration: 2000,
    })
  }, [])

  useEffect(()=>{
 
     },[])

  const SubmitForm = () => {
    setTab(3)
    // if (Paymethod === '') {
    //   return
    // } else {
    //   setTab(4)
    // }
  }


    return (
        <div data-aos="fade-zoom-in" className="Payment_Method">
        <h3>Choose Payment Method</h3>
        <div className="Box">
         <h6>AVAILABLE PAYMENT MENTHOD</h6>
        </div>
        <div className="Box">
          <div className="Detail">
            <h4>Debit card</h4>
            <h5>Pay using Mastercard, Visa or Verve card</h5>
          </div>
          <input
            value="Card Payment"
           
            name="radio"
            type="radio"
            
          />
        </div>
        <div className="Box">
          <div className="Detail">
            <h4>Virtual bank transfer</h4>
            <h5>Pay by sending funds to a virtual bank account</h5>
          </div>
          <input
            value="Direct Transfer"
          
            name="radio"
            type="radio"
           
          />
        </div>
        <div className="Box">
          <div className="Detail">
            <h4>Wallet</h4>
            <h5>Pay by sending funds to a virtual bank account</h5>
          </div>
          <input
            value="Wallet"
            name="radio"
            type="radio"
          />
        </div>
        <div className="Balance">
          <div className="Wrap">
          <h2>2000</h2><h5>NGN</h5>
          </div>
          <h6 className="Danger_Balance">Insufficient balance</h6>
          </div>
        
        <button onClick={SubmitForm}>CONTINUE</button>
        <small>
          By continuing, I represent that the declaration above is a proper and
          accurate description of the contents of my package.
        </small>
      </div>
    );
}
 
export default PaymentMethod;