import { useEffect, useState } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import NavigationButton from "../../../components/NavigateBtn";
import { generateTransactionID } from "../../../Library/Utilities";
import axios from "axios";
import { getShipmentHistory } from "../../../Library/URLs";
import { toast } from "react-toastify";
import { AuthActions } from "../../../store/AuthSlice";
import { useNavigate } from "react-router-dom";



const DashboardRecentShip = () => {

    const dispatch=useDispatch();
    const navigate = useNavigate();
    const {User,ShipmentHistory} = useSelector((state)=>state.Auth)


    useEffect(()=>{
    const GetShipHistory=async()=>{
        const Data={
            "user_id":User.userID,
            "user_ses_id":User.sessionID,
            "company_id":User.companyID,
            "transaction_id":generateTransactionID(15),
            "limit":"10",
            "offset":"0"
        }
            await axios
            .post(getShipmentHistory,Data)
            .then(response=>{
                console.log("shipment history res",response.data)
                if(response.data.success===false){
                    toast.error(response.data.message)
                }
                if(response.data.success===true){
                    const res=response.data.data
                    dispatch(AuthActions.GetShipmentHistory(res))
                }
            })
        }
    GetShipHistory()
    },[])

    const[CurrentPage,setCurrentPage]=useState(1)
    const [PostPerPage,setPostPerPage]=useState(5)
    const LastPost = CurrentPage * PostPerPage;
    const FirstPost = LastPost-PostPerPage;
    const Output = ShipmentHistory.slice(FirstPost,LastPost)

    const [Show,setShow]=useState(false)
    const handle=()=>{
        navigate("/all-shipments")
    }

    const render=(res)=>{
        switch (res) {
        case "Pending Pickup":
        return "Pending"
        break;
        case "Cancelled":
        return "Cancel"
        break;
        case "Rejected":
        return "Rejected"
        break;
        case "Delivered":
        return "Delivered"
        break;
        case "Pending Approval":
        return "Pending Approval"
        break;
    
        default:
        return "Success"
        break;
        }
        }

    const renderColor=(res)=>{
    switch (res) {
    case "Pending Pickup":
    return "pending"
    break;
    case "Cancelled":
    return "cancel"
    break;
    case "Rejected":
    return "cancel"
    break;
    case "Delivered":
    return "success"
    break;
    case "Pending Approval":
    return "pending"
    break;

    default:
    return "success"
    break;
    }
    }

    return (
        <div className="Dash_Recent_Shipment">
            <h3>Shipments</h3>
            <div className={`Shipment_Table_Wrapper ${Show===true ? 'active':''}`}>
                <div className="Recent_Top">
                    <h4>Recent Shipment</h4>
                    <button onClick={handle}>
                        View {Show===true ? 'Less' : 'More'} 
                        <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.16553 5.1668C0.801498 5.1668 0.506395 5.4619 0.506395 5.82593C0.506395 6.18996 0.801498 6.48506 1.16553 6.48506V5.1668ZM13.9354 6.292C14.1928 6.0346 14.1928 5.61726 13.9354 5.35985L9.74072 1.16516C9.48331 0.907749 9.06597 0.907749 8.80857 1.16516C8.55116 1.42256 8.55116 1.8399 8.80857 2.09731L12.5372 5.82593L8.80857 9.55454C8.55116 9.81195 8.55116 10.2293 8.80857 10.4867C9.06597 10.7441 9.48331 10.7441 9.74072 10.4867L13.9354 6.292ZM1.16553 6.48506H13.4693V5.1668H1.16553V6.48506Z" fill="#6D7D93"/>
                        </svg> 
                    </button>
                </div>
                {
                    Output.length > 0 ?
                    <>
                    <table >
                    <thead>
                        <th>Sender Name</th>
                        <th>Receivers Name</th>
                        <th>Tracking Number</th>
                        <th>Amount</th>
                        <th>Status</th>
                    </thead>
                    <tbody>
                    {
            Output.map(item=>(
                            <tr>

                            <td>
                            <div className="Sender_Wrap">
                                {/* <div className="Avatar"></div> */}
                                {item.sender_name}
                            </div>
                        </td>

                        <td>{item.recipient_name}</td>

                            <td>{item.shipment_no}</td>

                            <td>&#8358;{item.total_amount}</td>

                            <td>
                            <div className={`Btn ${renderColor(item.status)}`}>{render(item.status)}</div>
                            </td>
                        </tr>
                        ))
                    }
                    </tbody>
                </table>
                {/* <NavigationButton
                CurrentPage={CurrentPage}
                setCurrentPage={setCurrentPage}
                output={Output}
                /> */}
                    </> : <div className="No_Data_div">
                    <h5>No shipment</h5>
                    </div>
                }

            </div>
        </div>
    );
}
 
export default DashboardRecentShip;