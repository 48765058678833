import { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./StripeCheckoutForm";
import { loadStripe } from "@stripe/stripe-js";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    outline: "none",
    outerWidth: "0px",
    borderRadius: "5px",
    p: 4,
};

function StripeModal({ clientSecret, handleClose, handleSuccess }) {
    let stripePromise = loadStripe("pk_live_51HGyjMKxYLnmn4keur48teijIdF5lZwIVknBB4hC7iz11XjJaTXyCJ8vyGv5ByAIEAXrj5D2y7RH7skwqFiCiPUq00grL7LQpi");
    // let stripePromise = loadStripe("pk_test_51HGyjMKxYLnmn4kegofPUmJZXvLuwooAnjW6o4FynbqpTfQpTgqChj7wOkbG1Hx9lNWey456thtrVkpjSrZyhk8r00amE2KLsY")

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={clientSecret !== null}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={clientSecret !== null}>
                    <Box sx={style}>
                        <div className="Package_Modal_Wrapper">
                            <Elements stripe={stripePromise} options={{ clientSecret }}>
                                <CheckoutForm handleSuccess={handleSuccess} handleClose={handleClose} />
                            </Elements>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}

export default StripeModal;