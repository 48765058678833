import React, { useState } from 'react'
import "../../Assets/style/PrivacyPolicy.scss"
import { Footer, Header } from '../Home/Components';
import { NavLink } from 'react-router-dom';
import { Drawer } from '@mui/material';
import Button from "../../components/Button"

const PrivacyPolicy = () => {
    const [Open,setOpen]=useState(false)

    const drawerWidth = 260;

    const links=[
        // {
        //     name:'home',
        //     path:'/'
        // },
        // {
        //     name:'projects',
        //     path:'/projects'
        // },
        // {
        //     name:'services',
        //     path:'/services'
        // }
    ]
    return (
        <div >
            <Header links={links} setOpen={setOpen}/>
            <div className='PrivacyPolicy'>
                <h2 className='Policy-Header' >Privacy Policy</h2>

                <ol className='policy-list' >
                    <li>
                        <p className='text-list-header' >Introduction</p>
                        <ol className='px-2 px-md-4' >
                            <li>
                                <p className='text-list-header-sub' >
                                    <span>1.1</span>{" "} Scope
                                </p>
                                <p className='px-4' >
                                    This privacy policy will help you understand how Ella Logistics Services Limited uses
                                    and protects the data you provide to us when you visit and engage us. We reserve the right
                                    to change this policy at any given time, of which you will be promptly updated.
                                    If you want to make sure that you are up to date with the latest changes, we advise you to
                                    frequently visit our website.
                                </p>
                            </li>
                            <li>
                                <p className='text-list-header-sub' >
                                    <span>1.2</span>{" "} What User Data We Collect
                                </p>
                                <p className='px-4' >
                                    When you visit the website and engage us, we may collect the following data:
                                </p>
                                <ul className='px-5 unordered' >
                                    <li>
                                        Your IP address
                                    </li>
                                    <li>Your contact information and email address.</li>
                                    <li>Other information such as interests and preferences.</li>
                                    <li>Data profile regarding your online behaviour on our website.</li>
                                </ul>
                                <p className='px-4' >
                                    Why We Collect Your Data:
                                </p>
                                <p className='px-4' >
                                    We are collecting your data for several reasons:
                                </p>
                                <ul className='px-5 unordered' >
                                    <li>To better understand your needs.</li>
                                    <li>To improve our services and products.</li>
                                    <li>
                                        To send you promotional emails containing the information we 
                                        think you will find interesting.
                                    </li>
                                    <li>To contact you to fill out surveys and participate in other types of market research.</li>
                                    <li>To customize our website according to your online behavior and personal preferences.</li>
                                </ul>
                            </li>
                            <li>
                                <p className='text-list-header-sub' >
                                    <span>1.3</span>{" "} Safeguarding and Securing the Data
                                </p>
                                <p className='px-4' >
                                    <b>ELLA LOGISTICS SERVICES LIMITED</b> is committed to securing your data 
                                    and keeping it confidential. ELLA LOGISTICS SERVICES LIMITED has done all 
                                    in its power to prevent data theft, unauthorised access, and disclosure by implementing 
                                    the latest technologies and software, which help us safeguard all the information we 
                                    collect online.
                                </p>
                            </li>
                            <li>
                                <p className='text-list-header-sub' >
                                    <span>1.4</span>{" "} Terms and Definitions
                                </p>
                                <p className='px-4' >
                                    “us” ,”we” and “our” refer to <b>ELLA LOGISTICS SERVICES LIMITED</b>
                                </p>
                            </li>
                            <li>
                                <p className='text-list-header-sub' >
                                    <span>1.5</span>{" "} Responsibilities
                                </p>
                                <p className='px-4' >
                                    The Data Protection Officer is responsible for all aspects of the implementation and 
                                    management of this policy, unless noted otherwise.
                                    Department heads and supervisors are responsible for the implementation 
                                    of this policy, within the scope of their 
                                    responsibilities, and must 
                                    ensure that all staff under their control understand and undertake their 
                                    responsibilities accordingly.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p className='text-list-header' >Policy</p>
                        <ol className='px-2 px-md-4' >
                            <li>
                                <p className='text-list-header-sub' >
                                    <span>2.1</span>{" "} Your Privacy Rights
                                </p>
                                <p className='px-4' >
                                    This Privacy Policy describes privacy rights regarding the collection, usage, storage, 
                                    sharing and protection of personal information. It is in line with NITDA including database 
                                    applications, services, tools and physical contact with us regardless of how you access or 
                                    use them. If you have created a username, identification code, password or any other 
                                    piece of information as part of the access security measures, you must treat such information 
                                    as confidential, and you must not disclose it to any third party. We reserve the right to 
                                    disable any user identification code or password, whether chosen by you or allocated by us, 
                                    at any time, if in our opinion you have 
                                    
                                    failed to comply with any of the provisions of these conditions.
                                </p>
                            </li>
                            <li>
                                <p className='text-list-header-sub' >
                                    <span>2.2</span>{" "} Consent
                                </p>
                                <p className='px-4' >
                                    The acceptance will give consent upon access to the platforms, or use of services, 
                                    content, features, technologies or functions offered on our website, digital platforms 
                                    or visit any of our offices for official or non-official purposes. This Policy governs 
                                    the use of ELLA LOGISTICS SERVICES LIMITED services and intervention 
                                    projects by our users and stakeholders unless otherwise agreed through a written contract.
                                </p>
                            </li>
                            <li>
                                <p className='text-list-header-sub' >
                                    <span>2.3</span>{" "} Your Personal Information
                                </p>
                                <p className='px-4' >
                                    When you use <b>ELLA LOGISTICS SERVICES LIMITED</b> Services, we collect 
                                    information sent to us by your computer, mobile phone or other electronic access device. 
                                    The automatically collected information includes but not limited to- data about the 
                                    pages you access, computer IP address, device ID or unique identifier, device type, 
                                    geo-location information, computer and connection information, mobile network information, 
                                    statistics on page views, trace to and from the sites, referral URL, ad data, standard web 
                                    log data, still and moving images.
                                </p>
                                <p className='px-4' >
                                    We may also collect information you provide us including but not 
                                    
                                    limited to- information on web form, survey responses, account update information, 
                                    email, phone number, organization you represent, social position, correspondence 
                                    with <b>ELLA LOGISTICS SERVICES LIMITED</b> support services. We may also collect 
                                    information about your transactions, enquiries and your activities on our platform or 
                                    premises. We may also use information provided by these parties like social media sites .
                                </p>
                            </li>
                            <li>
                                <p className='text-list-header-sub' >
                                    <span>2.4</span>{" "} What we will do with your personal information
                                </p>
                                <p className='px-4' >
                                    The purpose of collecting your personal information is to give you efficient, 
                                    enjoyable and secure services. We may use your information to:
                                </p>
                                <ol className='policy-list' >
                                    <li>Provide service and support</li>
                                    <li>Process applications and send notice about your transaction to request parties</li>
                                    <li>Verify your identity</li>
                                    <li>Resolve disputes / collect fees and troubleshoot problems</li>
                                    <li>Manage risk or detect , prevent or remediate fraud or other potentially prohibited or illegal activities.</li>
                                    <li>Detect , prevent or remediate violation of laws , regulations , standards, guidelines and frameworks.</li>
                                    <li>Improve <b>ELLA LOGISTICS SERVICES LIMITED</b> services by implementing aggregate customer or user preference</li>
                                    <li>Measure the performance of <b>ELLA LOGISTICS SERVICES LIMITED</b></li>
                                    <li>Manage and protect</li>
                                </ol>
                                <p className='px-4' >
                                    Track information breach and remediate such identified breaches; Manage and protect our information technology and physical infrastructure.
                                </p>
                            </li>
                            <li>
                                <p className='text-list-header-sub' >
                                    <span>2.5</span>{" "} Cookies
                                </p>
                                <p className='px-4' >
                                    Cookies are small files placed on your computer&apos;s hard drive that enables the 
                                    website to identify your computer as you view different pages. Cookies allow websites and 
                                    applications to store your preferences in order to present contents, options or functions that 
                                    are specific to you. Like most interactive websites, our website uses cookies to enable the 
                                    tracking of your activity for the duration of a session. Our website uses only encrypted session 
                                    cookies which are erased either after a predefined timeout period or once the user logs out of the 
                                    platform and closes the browser. Session cookies do not collect information from the user&apos;s 
                                    computer. They will typically store information in the form of a session identification that does 
                                    not personally identify the user.
                                </p>
                            </li>
                            <li>
                                <p className='text-list-header-sub' >
                                    <span>2.6</span>{" "} How we protect your personal information
                                </p>
                                <p className='px-4' >
                                    We store and process your personal information on our computers. We will 
                                    seek the consent where we need to send your data to a country without an 
                                    adequate data protection law. We protect your information using physical, 
                                    technical, and administrative security measures to reduce the risks of loss, 
                                    misuse, unauthorized access, disclosure and alteration. Some of the safeguards 
                                    we use are firewalls and data encryption, physical access controls to our data 
                                    centers, and information access authorization controls.
                                </p>
                            </li>
                            <li>
                                <p className='text-list-header-sub' >
                                    <span>2.7</span>{" "} Security
                                </p>
                                <p className='px-4' >
                                    We will always hold your information securely. To prevent unauthorized 
                                    access to your information, we have implemented strong controls and security 
                                    safeguards at the technical and operational levels. This site uses Secure 
                                    Sockets Layer/Transport Layer Security (SSL/TLS) to ensure secure transmission 
                                    of your personal data. You should see the padlock symbol in your URL address bar 
                                    once you are successfully logged into the platform.
                                </p>
                                <p className='px-4' >
                                    The URL address will also start with https:// depicting a secure webpage. 
                                    SSL applies encryption between two points such as your PC and the connecting 
                                    server. Any data transmitted during the session will be encrypted before 
                                    transmission and decrypted at the receiving end. This is to ensure that data 
                                    cannot be read during transmission.
                                </p>
                                <p className='px-4' >
                                    <b>ELLA LOGISTICS SERVICES LIMITED</b> has also taken measures to comply with global 
                                    Information Security Management Systems (ISMS) ; we therefore have put in place digital 
                                    and physical security measures to limit or eliminate possibilities of 
                                    data privacy breach incidents.
                                </p>
                            </li>
                            <li>
                                <p className='text-list-header-sub' >
                                    <span>2.8</span>{" "} Data Confidentiality Rights
                                </p>
                                <p className='px-4' >
                                    Your information is regarded as confidential and will not be divulged to any 
                                    third party except under legal and/or regulatory conditions. You have the right 
                                    to request sight of, and copies of any and all information we keep on you, if such 
                                    requests are made in compliance with the Freedom of Information Act and other 
                                    relevant enactments. While ELLA LOGISTICS SERVICES LIMITED is responsible for 
                                    safeguarding the information entrusted to us, your role in fulfilling confidentiality 
                                    duties includes, but is not limited to, adopting and enforcing appropriate security 
                                    measures such as non-sharing of passwords and other platform logins details and 
                                    adherence with physical security protocols on the premises, dealing with only 
                                    authorised officers of the agency.
                                </p>
                            </li>
                            <li>
                                <p className='text-list-header-sub' >
                                    <span>2.9</span>{" "} Links to other website and premises
                                </p>
                                <p className='px-4' >
                                    Certain transaction processing channels may require links to other websites or 
                                    organisations other than ours. Please note that <b>ELLA LOGISTICS SERVICES LIMITED</b> is 
                                    not responsible and has no control over the website domain. We do not monitor or review 
                                    the content of other websites which are linked from ours . Please be aware that we are 
                                    responsible for the privacy practices or content of our websites. We encourage users to 
                                    be aware that when they visit the website they should evaluate the security and 
                                    trustworthiness of the site before providing any personal information. 
                                    <b>ELLA LOGISTICS SERVICES LIMITED</b> will not accept any responsibility for loss or 
                                    damage in whatever way resulting from the disclosure to third 
                                    parties of personnel information.
                                </p>
                            </li>
                            <li>
                                <p className='text-list-header-sub' >
                                    <span>2.10</span>{" "} Governing Law
                                </p>
                                <p className='px-4' >
                                    This Privacy Policy is made pursuant to the Nigeria Data Protection Regulation (2019) 
                                    and other relevant Nigerian laws, regulations or international conventions applicable 
                                    to Nigeria. Where any provision of this Policy is deemed inconsistent with a law, 
                                    regulation or convention, such provision shall be subject to the overriding law, 
                                    regulation or convention.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p className='text-list-header' >Breaches of Policy</p>
                        <p className='px-4' >
                            <b>ELLA LOGISTICS SERVICES LIMITED</b> will take all necessary measures to remedy any breach of 
                            this policy including the use of our disciplinary or contractual processes where appropriate.
                        </p>
                    </li>
                </ol>
            </div>
            <Footer/>
            <Drawer
        variant="temporary"
        onClose={()=>setOpen(false)}
        anchor="right"
        sx={{
        display: { xs: 'block', md: 'none' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open={Open}
        >
            <div className="Sidebar_Content">
            <div className="Inner-Container">
           <div className="Logo"></div>
            <div className="Right">
            <ul>
                {
                    links.map((item,index)=>
                    <li key={index}>
                    <NavLink 
                    className={({ isActive }) => (isActive ? "active-link" : "link")}
                    to={item.path}
                    >{item.name}</NavLink>
                    </li>
                    )
                }
            </ul>
            <Button to="/login" name="Sign in" />
            </div>
           </div>
         </div>
        </Drawer>
        </div>
    )
}

export default PrivacyPolicy