const HeaderTab = ({
  Tab,
  setTab,
  sender,
  receiver,
  price,
  package_detail,
  payment,
  confirm,
}) => {
  // console.log("polas",receiver)

  const Senderhandle = () => {
    setTab(0);
  };

  const Receiverhandle = () => {
    setTab(1);
  };

  const Pricehandle = () => {
    setTab(2);
  };

  const Packagehandle = () => {
    setTab(3);
  };

  const Paymenthandle = () => {
    setTab(4);
  };

  const Confirmhandle = () => {
    setTab(5);
  };

  return (
    <div className="Tab_Wrapper">
      <button
        disabled={sender === true ? false : true}
        onClick={Senderhandle}
        className={`Box ${sender === true || Tab === 0 ? "active" : ""}`}
      >
        <div className="circle">1</div>
        <p>Sender</p>
      </button>
      <button
        disabled={receiver === true ? false : true}
        onClick={Receiverhandle}
        className={`Box ${receiver === true || Tab === 1 ? "active" : ""}`}
      >
        <div className="circle">2</div>
        <p>Receiver</p>
      </button>
      <button
        disabled={package_detail === true ? false : true}
        onClick={Pricehandle}
        className={`Box ${
          package_detail === true || Tab === 2 ? "active" : ""
        }`}
      >
        <div className="circle">3</div>
        <p>Package Details</p>
      </button>
      <button
        disabled={price === true ? false : true}
        onClick={Packagehandle}
        className={`Box ${price === true || Tab === 3 ? "active" : ""}`}
      >
        <div className="circle">4</div>
        <p>Pricing</p>
      </button>
      <button
        disabled={payment === true ? false : true}
        onClick={Paymenthandle}
        className={`Box ${payment === true || Tab === 4 ? "active" : ""}`}
      >
        <div className="circle">5</div>
        <p>Payment Method</p>
      </button>
      <button
        disabled={confirm === true ? false : true}
        onClick={Confirmhandle}
        className={`Box ${confirm !== "" || Tab === 5 ? "active" : ""}`}
      >
        <div className="circle">6</div>
        <p>Confirm Details</p>
      </button>
    </div>
  );
};

export default HeaderTab;
