import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector, useDispatch,} from "react-redux";
import { generateTransactionID } from "../../../Library/Utilities";
import { BookInterStateShipment, ClearRecord } from "../../../store/InterStateAction";
import { usePaystackPayment } from 'react-paystack';
import { toast } from "react-toastify";
import NestedBtn from '../../../components/NestedBtn'
import axios from "axios";
import { InterStateActions } from "../../../store/InterStateSlice";
import { useNavigate } from "react-router-dom";


const ConfrimDetail = ({setTab,PaymentStatus}) => {

    const navigate = useNavigate()

    const [Pickup,setPickup]=useState('')

    const [PickLocation,setLocation]=useState('')

    const dispatch=useDispatch()

    const {
        User,
        WalletDetails,} = useSelector((state)=>state.Auth)
   
    const {
     Overall,
     isLoading,
     isBook,
     Sender,
     Receiver,
     SenderCity,
     ReceiverCity,
     TotalValue,
     PaymentOption,
     PickUp_Location,
     Package,
     RequestPack,
     PriceDetails,
     TotalWeight} = useSelector((state)=>state.Interstate)


     const handleLocation=(e)=>{
        setLocation(e.target.value)
        dispatch(InterStateActions.GetPickUp(e.target.value))
    }

     const config = {
        reference: (new Date()).getTime().toString(),
        email: User.email,
        // amount:PriceDetails.amount, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
        amount:10000,
        publicKey:process.env.REACT_APP_PAYSTACK_KEY,
    };
    
    const onSuccess = (reference) => {
        toast.success('Payment successful!')
        setTab(0)
      console.log("paystack success",reference);
    };
  
   
    const onClose = () => {
        toast.error('Payment fail')
      console.log('paystack closed')
    }
  
    const PaystackHookExample = () => {
        const initializePayment = usePaystackPayment(config);
        return (
            <button onClick={() => {
                initializePayment(onSuccess, onClose)
            }}>BOOK SHIPMENT</button>
        );
    };

    const HomeHandle=()=>{
        dispatch(ClearRecord())
        setTab(0)
    }

    const Previous=()=>{
        setTab(4)
    }

    const BookShipment=()=>{
        const data={
            "packages":RequestPack,
            "user_id":User.userID,
            "user_ses_id":User.sessionID,
            "company_id":User.companyID,
            "transaction_id":generateTransactionID(15),
            "processor":"DHL",
            "payment_method":PaymentOption,
            "sender_first_name":Sender.first_name,
            "sender_last_name":Sender.last_name,
            "sender_phone_no":Sender.phone_number,
            "sender_postcode":"101001",
            "pick_up_location":Sender.street,
            "sender_city":SenderCity,
            "sender_state":Sender.state,
            "sender_country":"Nigeria",
            "sender_email":User.email,
            "sender_company_name":"Ella Logistics", 
            "drop_off_location":Receiver.address,
            "recipient_first_name":Receiver.first_name,
            "recipient_last_name":Receiver.last_name,
            "recipient_phone_no":Receiver.phone_number,
            "recipient_email":User.email,
            "description":Overall,
            "request_type":"Interstate",
            "recipient_postcode":"72521",
            "recipient_city":ReceiverCity,
            "recipient_state":Receiver.state,
            "recipient_country":"Nigeria",
            "weight":TotalWeight,
            "amount":PriceDetails.amount,
            "flutterwave_amount":PriceDetails.flutterwave_amount,
            "processing_unit":PickUp_Location,
            "item_value":null,
            "item_value_currency":"NGN",
            "length":null,
            "breadth":null,
            "height":null,
            "insurance":"no",
            "origin_of_content":null,
            "request_pickup":"No",
            "transaction_reference_no":"",
            "carrier_id":"1",
            "pickup_address_id":"",
            "dropoff_address_id":"",
            "parcel_id":"",
            "request_pickup_carrier_id":"",
            "request_pickup_address_id":"",
            "request_dropoff_address_id":""
        }
        dispatch(BookInterStateShipment(data))
    }


   


    useEffect(() => {
        AOS.init({
          duration : 2000
        });
      }, []);


    useEffect(()=>{
        const Fetch=async()=>{
            const data={
                "user_id":User.userID,
                "user_ses_id":User.sessionID,
                "company_id":User.companyID,
                "transaction_id":generateTransactionID(15),
            }
            await axios.post('https://user.parcelflow.io/engine/v1/actions/get-pickup-locations',data)
            .then(res=>{
                if(res.data.success===true){
                    setPickup(res.data.data)
                    console.log('pick',Pickup)
                }
                if(res.data.success===false){
                    setPickup('')
                    toast.error(res.data.message)
                }
            })
        }
        Fetch()
    },[])


    useEffect(()=>{
        // if(window.location.reload){
        //     HomeHandle()
        //    console.log('am refresh')
        // }
    },[])


    return (
        <>
         {
         isBook ===true ? <div className="Successfull">
         <svg width="90" height="89" viewBox="0 0 90 89" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fill-rule="evenodd" clip-rule="evenodd" d="M45.0961 88.91C69.6104 88.91 89.4832 69.0372 89.4832 44.5229C89.4832 20.0085 69.6104 0.135742 45.0961 0.135742C20.5818 0.135742 0.708984 20.0085 0.708984 44.5229C0.708984 69.0372 20.5818 88.91 45.0961 88.91ZM65.6646 37.3494C67.8314 35.1826 67.8314 31.6696 65.6646 29.5028C63.4978 27.336 59.9847 27.336 57.818 29.5028L39.5477 47.773L32.3742 40.5995C30.2075 38.4328 26.6944 38.4328 24.5276 40.5995C22.3608 42.7663 22.3608 46.2794 24.5276 48.4462L35.6244 59.5429C37.7912 61.7097 41.3042 61.7097 43.471 59.5429L65.6646 37.3494Z" fill="#2DD4BF"/>
         </svg>
             <h4>Shipment as been book Successfully</h4>
             <p>Everything is Fine, you can go ahead to track your delivery</p>
             <div onClick={HomeHandle} className="Btn">Back</div>
          </div> :
          <div data-aos="fade-zoom-in" className="Confirm">
          <h3>Confirm Details</h3>
          <div className="Box">
              <p>SUMMARY</p>
          </div>
          <div className="Box">
              <p>SELECT PROCESSING AREA</p>
              <div className="Right">
                <select value={PickLocation} onChange={(e)=>handleLocation(e)} name="" id="">
                 <option value="">Choose Processing Area</option>
                 {
                    Pickup!=='' && Pickup.map((item,index)=>
                    <option key={index} value={item.id}>{item.city_name}</option>
                    )
                 }
                </select>
              </div>
          </div>
          <div className="Box">
              <p>SENDER DETAILS</p>
              <div className="Right">
                  <h4>{Sender.first_name} {Sender.last_name}</h4>
                  <h6>{Sender.street}</h6>
              </div>
          </div>
          <div className="Box">
              <p>RECEIVER DETAILS</p>
              <div className="Right">
              <h4>{Receiver.first_name} {Receiver.last_name}</h4>
              <h6>{Receiver.street}</h6>
              </div>
          </div>
          <div className="Box">
              <p>DECLARED WEIGHT</p>
              <div className="Right">
              <h4>{TotalWeight}KG</h4>
              <h6>1CM X 1CM X 1CM</h6>
              </div>
          </div>
          <div className="Box">
              <p>PACKAGE</p>
              <div className="Right">
              {
                  Package.map((item,index)=>
                  <h6 key={index}>{item.name} </h6>
                  )
              }            
              </div>
          </div>
          <div className="Box">
              <p>PACKAGE VALUE</p>
              <div className="Right">
              <h4>{TotalValue}</h4>               
              </div>
          </div>
          <div className="Box">
              <p>PAYMENT MENTHOD</p>
              <div className="Right">
              <h4>{PaymentOption}</h4>               
              </div>
          </div>
          <div className="Bottom_Box">
                  <h3>NGN  {WalletDetails.account_balance}</h3>
                  <h5>Inc. VAT</h5>
          </div>
          {
            PaymentOption==="Wallet" ? <button onClick={ BookShipment }>
            {
              isLoading===true ?<span className="spinner-border spinner-grow-sm"/> :'BOOK SHIPMENT'
            }
            </button> : <PaystackHookExample/>
          }
          
          <small>
          By continuing, I represent that the declaration above is a proper and accurate description of the contents of my package.
          </small>
          <NestedBtn 
            handle={Previous}
            status={PaymentStatus}
            name="previous" />
      </div>
        }
        </>
    );
}
 
export default ConfrimDetail;