import { Link } from "react-router-dom";
import "../../Assets/style/LogIn.scss"
import RegisterForm from "./RegisterForm";

const Register = () => {
    return (
        <div className="Login_Wrapper">
        <div className="Left">
          <Link to="/"><div className="Logo"></div></Link>
           <p>We take care about<br/>transportation for your business</p>
           <div className="Transport"></div>
        </div>
        <div className="Right Register">
        <RegisterForm/>
        </div>
       </div> 
    );
}
 
export default Register;