import { createSlice } from '@reduxjs/toolkit';

// ==============================|| REDUX - MAIN STORE ||============================== //

const initialState = {
    Overall:'',
    isLoading:false,
    isBook:false,
    CostStatus:false,
    PriceDetails:'',
    PaymentOption:'',
    Sender:'',
    SenderCountry:'',
    SenderState:'',
    Receiver:'',
    ReceiverCity: "",
    SenderCity: "",
    ReceiverCountry:'',
    ReceiverState:'',
    Package:[
        {
            package_items: [],
            package_weight: "0",
            package_length: "",
            package_width: "",
            description: "",
            package_height: "",
            package_value: "0",
            number_of_items: "0"
        }
    ],
    SenderStatus:false,
    ReceiverStatus:false,
    PaymentStatus:false,
    PackageStatus:false,
    PriceStatus:false,
    RequestPack:{},
    TotalQuantity:0,
    TotalValue:0,
    TotalWeight:0,
    PickUp_Location:'',
    Carrier_Info: [],
    packaging_info: {},
    SelectedCarrier: {},
    insurance: "No"
}

const InternationSlice=createSlice({
    name:'InternationSlice',
    initialState,
    reducers:{
        Reset: () => initialState,
        GetCost:(state,action)=>{
          state.CostStatus=action.payload
        },
        GetPickUp:(state,action)=>{
            state.PickUp_Location=action
        },
        GetSenderStatus:(state,action)=>{
            state.SenderStatus=action.payload
        },
        GetReceiverStatus:(state,action)=>{
            state.ReceiverStatus=action.payload
        },
        GetPackageStatus:(state,action)=>{
            state.PackageStatus=action.payload
        },
        GetPaymentStatus:(state,action)=>{
            state.PaymentStatus=action.payload
        },
        SetPriceStatus:(state,{payload})=>{
            state.PriceStatus=payload
        },
        GetPriceDetails:(state,action)=>{
        state.PriceDetails=action.payload
        },
        GetOverall:(state,action)=>{
        state.Overall=action.payload
        },
        GetSenderRegion:(state,action)=>{
            state.SenderCountry=action.payload
        },
        GetSenderState:(state,action)=>{
            state.SenderState=action.payload
        },
        GetSenderCity:(state,action)=>{
            state.SenderCity=action.payload
        },
        GetReceiverCity:(state,action)=>{
            state.ReceiverCity=action.payload
        },
        GetReceiverState:(state,action)=>{
            state.ReceiverState=action.payload
        },
        GetReceiverRegion:(state,action)=>{
        state.ReceiverCountry=action.payload
        },
        Loading:(state,{payload})=>{
        state.isLoading=payload
        },
        ShipmentStatus:(state,{payload})=>{
        state.isBook=payload
        },
        GetPayment:(state,action)=>{
        state.PaymentOption=action.payload
        },
        GetCarrierInfo: (state, action) => {
            state.Carrier_Info=action.payload
        },
        GetSelectedCarrier: (state, action) => {
            state.SelectedCarrier=action.payload
        },
        GetPackagingInfo: (state, action) => {
            state.packaging_info = action.payload
        },
        GetQtyVal:(state)=>{
         let q = state.Package.map(item=>{return item.quantity})
         let sum_q=0
         for (let a of q){
            sum_q+=+a
         }
         state.TotalQuantity =sum_q

         let v = state.Package.map(item=>{return item.value*item.quantity})
         let sum_v=0
         for (let a of v){
            sum_v+=+a
         }
         state.TotalValue=sum_v

         let w = state.Package.map(item=>{return item.weight*item.quantity})
         let sum_w=0
         for (let a of w){
            sum_w+=+a
         }
         state.TotalWeight=sum_w
        },
        GetSender(state,action){
            state.Sender=action.payload
        },
        GetReceiver(state,action){
            state.Receiver=action.payload
        },
        GetPackage(state,{payload}){
            const Index = state.Package.findIndex((item)=>item.name===payload.name)
            if(Index >=0){
                state.Package[Index].name=payload.name
                state.Package[Index].value=payload.value
                state.Package[Index].description=payload.description
                state.Package[Index].weight=payload.weight
                state.Package[Index].quantity=payload.quantity
                state.Package[Index].currency=payload.currency
            }
            else{
                state.Package.push(payload)
            }
        },
        ResetPackage(state,action){
            state.Package=action.payload
        },
        ResetTotalQuantity(state,action){
            state.TotalQuantity=action.payload
        },
        ResetTotalValue(state,action){
            state.TotalValue=action.payload
        },
        ResetTotalWeight(state,action){
            state.TotalWeight=action.payload
        },
        GetRequestPack(state){
            const data=state.Package
            let object = data.reduce((acc,current,i)=>{
                acc[i] = current;
                return acc;
            }, {});
            state.RequestPack=object
        },
        EditPackage(state,action){
            state.Package=state.Package
            .map((item)=>{
                if(item.id===action.payload.id){
                    item.name=action.payload.name
                    item.value=action.payload.value
                    item.description=action.payload.description
                    item.weight=action.payload.weight
                    item.quantity=action.payload.quantity
                    item.currency=action.payload.currency
                }
                return item
            })
        },
        UpdatePackage(state, action) {
            state.Package = action.payload
        },
        UpdateInsurance:(state,action)=>{
            state.insurance=action.payload
        },
     }
});

export const InternationalActions = InternationSlice.actions

export default InternationSlice.reducer

