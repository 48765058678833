import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";


const ConfrimDetail = ({setTab}) => {


    const HomeHandle=()=>{
        setTab(0)
    }



    useEffect(() => {
        AOS.init({
          duration : 2000
        });
      }, []);


    return (
        <>
        <div data-aos="fade-zoom-in" className="Confirm">
            <h3>Confirm Details</h3>
            <div className="Box">
                <p>SUMMARY</p>
            </div>
            <div className="Box">
                <p>SENDER DETAILS</p>
                <div className="Right">
                    <h4>Sender.first_name</h4>
                    <h6>Sender.address_one</h6>
                </div>
            </div>
            <div className="Box">
                <p>RECEIVER DETAILS</p>
                <div className="Right">
                <h4>Receiver.first_name</h4>
                <h6>Receiver.address_one</h6>
                </div>
            </div>
            <div className="Box">
                <p>DECLARED WEIGHT</p>
                <div className="Right">
                    <h4>TotalWeight KG</h4>
                    <h6>1CM X 1CM X 1CM</h6>
                </div>
            </div>
            <div className="Box">
                <p>PACKAGE</p>
                <div className="Right">
                item.name              
                </div>
            </div>
            <div className="Box">
                <p>PACKAGE VALUE</p>
                <div className="Right">
                <h4>TotalValue</h4>               
                </div>
            </div>
            <div className="Box">
                <p>PAYMENT MENTHOD</p>
                <div className="Right">
                <h4>PaymentOption</h4>               
                </div>
            </div>
            <div className="Bottom_Box">
                    <h3>NGN 200</h3>
                    <h5>Inc. VAT</h5>
            </div>
            <button >
            BOOK SHIPMENT
            </button>
            <small>
            By continuing, I represent that the declaration above is a proper and accurate description of the contents of my package.
            </small>
        </div>
        <div data-aos="fade-fade-in" className="Successfull">
        <svg width="90" height="89" viewBox="0 0 90 89" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M45.0961 88.91C69.6104 88.91 89.4832 69.0372 89.4832 44.5229C89.4832 20.0085 69.6104 0.135742 45.0961 0.135742C20.5818 0.135742 0.708984 20.0085 0.708984 44.5229C0.708984 69.0372 20.5818 88.91 45.0961 88.91ZM65.6646 37.3494C67.8314 35.1826 67.8314 31.6696 65.6646 29.5028C63.4978 27.336 59.9847 27.336 57.818 29.5028L39.5477 47.773L32.3742 40.5995C30.2075 38.4328 26.6944 38.4328 24.5276 40.5995C22.3608 42.7663 22.3608 46.2794 24.5276 48.4462L35.6244 59.5429C37.7912 61.7097 41.3042 61.7097 43.471 59.5429L65.6646 37.3494Z" fill="#2DD4BF"/>
        </svg>
            <h4>Shipment as been book Successfully</h4>
            <p>Everything is Fine, you can go ahead to track your delivery</p>
            <div onClick={HomeHandle} className="Btn">Back</div>
         </div>
        </>
    );
}
 
export default ConfrimDetail;