// import "../../../Assets/style/"

const LoaclShipmentHeader = ({
    Tab,
    sender,
    item,
    payment,
    confirm
}) => {
    return (
        <div className="Tab_Wrapper">
        <div className={`Box ${(sender!=='' || Tab===0) ? 'active':''}`}>
           <div className="circle">1</div>
           <p>Sender Details</p>
       </div>
       <div className={`Box ${(item!=='' || Tab===1) ? 'active':''}`}>
           <div className="circle">2</div>
           <p>Item Detail</p>
       </div>
       <div className={`Box ${(payment!=='' || Tab===2) ? 'active':''}`}>
           <div className="circle">4</div>
           <p>Payment Method</p>
       </div>
       <div className={`Box ${(confirm!=='' || Tab===3) ? 'active':''}`}>
           <div className="circle">5</div>
           <p>Confirm Details</p>
       </div>
       </div>
    );
}
 
export default LoaclShipmentHeader;