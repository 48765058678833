import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { registerURL } from '../../Library/URLs';
import axios from 'axios';
import { toast } from 'react-toastify';
import {generateTransactionID} from "../../Library/Utilities";

const RegisterForm = () => {

      const navigate=useNavigate()

      const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm();

      const [isLoading,setLoading]=useState(false)

      function getDevice() {
          let operatingSystem = 'Not known';
          if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Windows OS'; }
          if (window.navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'MacOS'; }
          if (window.navigator.appVersion.indexOf('X11') !== -1) { operatingSystem = 'UNIX OS'; }
          if (window.navigator.appVersion.indexOf('Linux') !== -1) { operatingSystem = 'Linux OS'; }
  
          let currentBrowser = 'Not known';
          if (window.navigator.userAgent.indexOf('Chrome') !== -1) { currentBrowser = 'Chrome'; }
          else if (window.navigator.userAgent.indexOf('Firefox') !== -1) { currentBrowser = 'Mozilla Firefox'; }
          else if (window.navigator.userAgent.indexOf('MSIE') !== -1) { currentBrowser = 'Internet Exployer'; }
          else if (window.navigator.userAgent.indexOf('Edge') !== -1) { currentBrowser = 'Edge'; }
          else if (window.navigator.userAgent.indexOf('Safari') !== -1) { currentBrowser = 'Safari'; }
          else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'Opera'; }
          else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'YaBrowser'; }
  
          return currentBrowser + " on " + operatingSystem;
      }
  
      const SignInUser = (data) => {
          console.log("hello..")
          setLoading(true)
          const raw={
             "phone":data.Phone,
             "first_name":data.first_name,
             "last_name":data.last_name,
             "email":data.email,
             "password":data.Password,
              "device_id":"value",
              "device_name":getDevice(),
              "transaction_id":generateTransactionID(15),
              "token":"token",
              "company_id":65
          }
          console.log(raw);
  
          axios
              .post(registerURL, raw)
              .then(function (response) {
                  console.log("res",response);
                  setLoading(true)
  
                  if (response["data"]["success"] === false) {
                      setLoading(false)
                      toast.error(response["data"]["message"])
                  } else if (response["data"]["success"] === true) {
                     console.log('success res',response.data)
                      setLoading(false)
                      toast.success("Register successful")
                      navigate('/login', {replace: true});
                  }
              })
              .catch(function (error) {
                  return toast.error("Register error"+error.message);
              });
      };
    
    const Submit=(data)=>{
        console.log(data)
        SignInUser(data)
    }



    return (
        <div className="LogIn_Form">
        <div className="Wrapper">
         <Link to="/"><div className="Logo Register"></div></Link>
         <h4>Hey, Hello</h4>
         <p>To continue, please provide a valid details.</p>
         <form onSubmit={handleSubmit(Submit)}>
            <div className="Top">
            <div className="Box">
             <input 
             type="text" 
             placeholder="First name"
             className={`${errors.first_name ? 'error':''}`}
             {...register('first_name', { required: true })}
             />
             {errors.first_name && <span>First name is required.</span>}
             </div>

             <div className="Box">
             <input 
             type="text" 
             placeholder="Last name"
             className={`${errors.last_name ? 'error':''}`}
             {...register('last_name', { required: true })}
             />
             {errors.last_name && <span>Last name is required.</span>}
             </div> 
            </div>

             <div className="Box">
             <input 
             type="tel" 
             placeholder="Phone number"
             className={`${errors.Phone ? 'error':''}`}
             {...register('Phone', { required: true })}
             />
             {errors.Phone && <span>Phone is required.</span>}
             </div>

             <div className="Box Email_Wrap">
             <input 
             type="email" 
             placeholder="Enter email"
             style={{textTransform:"none"}}
             className={`${errors.email ? 'error':''}`}
             {...register('email', { required: true })}
             />
             {errors.email && <span>Email is required.</span>}
             </div>

             <div className="Box">
             <input 
             type="password" 
             placeholder="Password"
             className={`${errors.Password ? 'error':''}`}
             {...register('Password', { required: true })}
             />
             {errors.Password && <span>Password is required.</span>}
             </div>
             <Link className="Forgot_Text" to="/login">Already have an account</Link>
             <div className="Descrip Register">
             By completing this transaction, you represent that you have read, understand and accept the Zeexpress <a href="https://ellalogistics.africa/privacy-policy" target="_blank" rel="noreferrer">terms of service</a> and <a href="https://ellalogistics.africa/privacy-policy" target="_blank" rel="noreferrer">privacy policy</a>.
             </div>
             <button>{
                 isLoading===true ?  <span className="spinner-border spinner-grow-sm"/> : 'SIGN UP'
                 } </button>
         </form>
        </div>
     </div>
    );
}
 
export default RegisterForm;